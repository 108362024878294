import React from 'react'
import classnames from 'classnames'

function InputFieldGroup({
  type,
  error,
  placeholder,
  name,
  value,
  onChange,
  disabled,
  info,
  className,
  wrapperClassName
}) {
  return (
    <div className={classnames('form-group', wrapperClassName)}>
      <input
        type={type}
        className={classnames('form-control form-control-lg', className, {
          'is-invalid': error
        })}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

InputFieldGroup.defaultProps = {
  type: 'text'
}

export default InputFieldGroup
