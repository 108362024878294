export const reservation_types = activeIdx => {
  const items = [
    { text: 'Upcoming' },
    { text: 'Now' },
    { text: 'Past' }
  ]

  items[activeIdx].active = true
  return items
}
