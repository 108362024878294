import { SET_VERSION_CONFIG, } from "../../config/actionTypes";
import versionConfigEnum from "../../enums/versionConfigEnum";

const initialState = {
  empty_modifier_group: null,
  show_table_number: null,
  show_booking_flow: null,
  is_anonymous_user_identification_enabled: null,
}

const versionConfigReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_VERSION_CONFIG:
      const updatedState = { ...state };
      Object.keys(updatedState).forEach((key) => {
        // Set all keys to false initially
        updatedState[key] = false;
      });
      // If payload exists, iterate over the data
      if (payload) {
        payload.forEach((data) => {
          Object.keys(versionConfigEnum).forEach((key) => {
            if (data?.key === versionConfigEnum[key]) {
              // Update the corresponding key with the is_active value
              updatedState[key] = data.is_active;
            }
          });
        });
      }
      return updatedState;
    default:
      return state;
  }
};

export default versionConfigReducer