import isEmpty from 'lodash.isempty'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import AddMenuItemModal from '../../components/orders/AddMenuItemModal'
import MenuItemDetailsModal from '../../components/restaurant/MenuItemDetailsModal'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import ActiveSectionItems from './ActiveSectionItems'
import MenuListApproved from './MenuListApproved'
import './RestaurantMenuApproved.css'
import { getRestaurantMenuApproved, setActiveMenuAndSectionInfoApproved, setActiveMenuSectionItemsWithRoundForUserApproved, setMenuItemRecommendations, setOnMenuItemRecommendationsModal, setRestaurantMenuApproved } from './services/actions'
import RecommendedMenuItemsModal from '../../components/orders/RecommendedMenuItemsModal'
import { getModifiersForMenuItem, getSelectedModifiersForOrderItem, setModifiersForMenuItem, setRestaurantById, setSelectedModifiersForOrderItem } from '../restaurant/services/actions'
import { setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { setIsPay } from '../../store/actions/usersActions'

const RestaurantMenuApproved = ({
    onReservation = false,
    restaurantIdFromReservation,
    reservationId,
    orderQuantities,
    onAddClickHandler = () => { },
}) => {
    const { restaurantId: restaurantIdParams } = useParams()
    const dispatch = useDispatch()
    const {
        restaurants: { restaurants },
        restaurant: { restaurant, modifiersFromDb, selectedModifiersFromDb },
        orders: { orders, lastRoundOrders },
        menu: {
            restaurantMenuApproved,
            activeMenuAndSectionInfoApproved: {
                activeMenuId,
                activeMenuName,
                activeMenuSectionId,
                activeMenuSectionName,
            },
            activeMenuSectionItemsApproved,
            activeMenuSectionItemsWithRoundForUserApproved,
            menuItemRecommendations,
            onMenuItemRecommendationsModal,
        },
        user: { isPay, },
        versionConfig: { empty_modifier_group }
    } = useSelector(state => state)

    //local state
    const [menuItemDetails, setMenuItemDetails] = useState({})
    const [recommendedItemDetails, setRecommendedItemDetails] = useState({})
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const [restaurantId, setRestaurantId] = useState(null)

    const [isMenuDrawerOpened, setIsMenuDrawerOpened] = useState(false)
    const drawerRef = useRef(null)

    const [hasInitialMenuOpened, setHasInitialMenuOpened] = useState(false)

    const [onMenuItemDetailsModal, setOnMenuItemDetailsModal] = useState(false)
    const [onAddEditDeleteItemModal, setOnAddEditDeleteItemModal] = useState(false)
    const [onAddRecommendedMenuItemsModal, setOnAddRecommendedMenuItemsModal] = useState(false)

    // callbacks
    const updateMenuItemRecommendations = useCallback(() => {
        // fix
        // resenie ova da ne se povikuva sekoj pat pri promena na item sto nema prepotaki
        if (!isEmpty(menuItemDetails) && lastRoundOrders) {
            dispatch(setMenuItemRecommendations({
                menuItemRecommendations: menuItemDetails?.menu_item_recommended_external_ids,
                lastRoundOrders
            }))
        }
    }, [menuItemDetails, lastRoundOrders]);

    const updateMenuItemModifiers = useCallback(() => {
        if (isEmpty(menuItemDetails)) {
            if (!isEmpty(modifiersFromDb)) {
                dispatch(setModifiersForMenuItem([]))
            }
            if (!isEmpty(selectedModifiersFromDb)) {
                dispatch(setSelectedModifiersForOrderItem([]))
            }
        }
    }, [menuItemDetails, modifiersFromDb, selectedModifiersFromDb, recommendedItemDetails])

    //functions

    const handleMenuItemDetails = async (menuItemId, menuItemRecommendations) => {
        // dispatch(setModifiersForMenuItem([]))
        // dispatch(setSelectedModifiersForOrderItem([]))
        if (menuItemId) {
            const itemDetails = activeMenuSectionItemsApproved?.find(item => item.menu_item_id === menuItemId)
            setMenuItemDetails(itemDetails)

            if (onReservation && restaurantId) {
                await handleModifiersReset(menuItemId, itemDetails).then(() => {
                    setOnAddEditDeleteItemModal(true)
                })
            }
            else {
                setOnMenuItemDetailsModal(true)
            }

        }
        else {
            setMenuItemDetails({})
            setOnMenuItemDetailsModal(false)
        }
    }

    const handleModifiersReset = async (menuItemId, itemDetails) => {
        const itemFromOrders = lastRoundOrders.find(val => val.menu_item_id === menuItemId) || {}
        // await dispatch(setRefreshDataForOpenedMenuItem({ menuItemId, refresh: false }))
        if (itemFromOrders && itemFromOrders.order_item_id) {
            await dispatch(getSelectedModifiersForOrderItem(itemFromOrders.order_item_id, restaurantId))
        }
        else {
            await dispatch(getModifiersForMenuItem(itemDetails.menu_item_id, itemDetails.menu_item_section_id, restaurantId))
        }
        if (isPay) {
            dispatch(setIsPay(false))
        }
    }

    const handleRecommendedItemDetails = async (recommendedItemId) => {
        const itemDetails = activeMenuSectionItemsApproved?.find(item => item.menu_item_id === recommendedItemId)
        setRecommendedItemDetails(itemDetails)
        await handleModifiersReset(recommendedItemId, itemDetails).then(() => {
            setOnAddRecommendedMenuItemsModal(false)
            setOnAddEditDeleteItemModal(true)
        })
    }


    const handleOnCloseMenuItemModal = () => {
        setOnAddEditDeleteItemModal(false)
        setMenuItemDetails({})
        if (menuItemRecommendations) {
            dispatch(setMenuItemRecommendations({
                menuItemRecommendations: [],
                lastRoundOrders
            }))
        }
        // dispatch(setSelectedModifiersForOrderItem([]))
    }


    const handleOnAddRecommendedItemModal = () => {
        setOnAddEditDeleteItemModal(false)
        setOnAddRecommendedMenuItemsModal(true)
        dispatch(setModifiersForMenuItem([]))
        dispatch(setSelectedModifiersForOrderItem([]))
        setRecommendedItemDetails({})
    }

    const handleCloseRecommendedMenuItemModal = () => {
        if (menuItemRecommendations) {
            setOnAddRecommendedMenuItemsModal(true)
            setRecommendedItemDetails({})
            dispatch(setModifiersForMenuItem([]))
            dispatch(setSelectedModifiersForOrderItem([]))
        }
        setOnAddEditDeleteItemModal(false)
    }

    const handleCloseMenuItemRecommendationModal = () => {
        setOnAddRecommendedMenuItemsModal(false)
        setMenuItemDetails({})
        if (menuItemRecommendations) {
            dispatch(setMenuItemRecommendations({
                menuItemRecommendations: [],
                lastRoundOrders
            }))
        }
    }

    const handleIsMenuOpened = () => {
        setIsMenuOpened(prevState => !prevState)
    }

    const handleIsMenuDrawerOpened = () => {
        setIsMenuDrawerOpened(prevState => !prevState)
    }

    const handleChangeMenu = (menuId, menuName) => {
        if (menuId !== activeMenuId && menuName !== activeMenuName) {
            if (!isMenuOpened) {
                handleIsMenuOpened()
            }
            dispatch(setActiveMenuAndSectionInfoApproved({ menuId, menuName, lastRoundOrders }))
        }
        else {
            handleIsMenuOpened()
        }
    }

    const handleChangeMenuSection = (menuSectionId, menuSectionName) => {
        if (menuSectionId !== activeMenuSectionId && menuSectionName !== activeMenuSectionName) {
            dispatch(setActiveMenuAndSectionInfoApproved({ menuSectionId, menuSectionName, lastRoundOrders }))
        }
        // Acocate to the upper condition to close the drawer only on new selection of a menu section if needed
        if (isMobile) {
            handleIsMenuDrawerOpened()
        }
    }

    // effects

    useEffect(() => {
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            setIsMobile(true)
        }
        else setIsMobile(false)
    }, [])

    useEffect(() => {
        !onReservation ?
            setRestaurantId(+restaurantIdParams) :
            setRestaurantId(+restaurantIdFromReservation)
    }, [onReservation, restaurantIdParams, restaurantIdFromReservation])

    useEffect(() => {
        if (!isEmpty(restaurants) && isEmpty(restaurant) && restaurantId) {
            dispatch(setRestaurantById({ restaurants, restaurantId }))
        }
    }, [dispatch, restaurants, restaurant, restaurantId])

    useEffect(() => {
        if (isEmpty(restaurantMenuApproved) && (restaurantId)) {
            dispatch(getRestaurantMenuApproved(+restaurantId, +reservationId, false))
        }
    }, [dispatch, onReservation, restaurantId, reservationId, restaurantMenuApproved])


    useEffect(() => {
        // Dispatch to match the lastRound data with the active menu items on load
        if (onReservation && activeMenuId && activeMenuName) {
            dispatch(setActiveMenuAndSectionInfoApproved({ menuId: activeMenuId, menuName: activeMenuName, lastRoundOrders }))
            // This state is set to true so this dispatch happens only once on the order page
        }
    }, [dispatch, onReservation, lastRoundOrders, activeMenuId, activeMenuName])




    useEffect(() => {
        setTimeout(() => {
            if (activeMenuId && activeMenuName && activeMenuSectionId && activeMenuSectionName && !hasInitialMenuOpened) {
                handleIsMenuOpened()
                handleIsMenuDrawerOpened()
                setHasInitialMenuOpened(true)
            }
        }, 10)
    }, [activeMenuId, activeMenuName, activeMenuSectionId, activeMenuSectionName, hasInitialMenuOpened])

    useEffect(() => {
        updateMenuItemRecommendations();
    }, [updateMenuItemRecommendations]);

    useEffect(() => {
        updateMenuItemModifiers()
    }, [updateMenuItemModifiers])

    // useEffect(() => {
    //     console.log({
    //         menuItemDetails,
    //         recommendedItemDetails,
    //         onAddEditDeleteItemModal,
    //         onAddRecommendedMenuItemsModal,
    //         menuItemRecommendations
    //     })
    // }, [menuItemDetails, recommendedItemDetails, onAddEditDeleteItemModal, onAddRecommendedMenuItemsModal, menuItemRecommendations])

    return (
        <Row className="full-menu-row reservations-row">
            <Column md='10' lg='8'>
                <Row>
                    <Column md="4" lg="4">
                        {
                            <MenuListApproved
                                restaurantMenuApproved={restaurantMenuApproved}
                                restaurantLogo={restaurant.logo ?? restaurant.image}
                                isMobile={isMobile}
                                isMenuOpened={isMenuOpened}
                                isMenuDrawerOpened={isMenuDrawerOpened}
                                drawerRef={drawerRef}
                                activeMenuId={activeMenuId}
                                activeMenuName={activeMenuName}
                                activeMenuSectionId={activeMenuSectionId}
                                activeMenuSectionName={activeMenuSectionName}
                                onReservation={onReservation}
                                handleChangeMenu={handleChangeMenu}
                                handleChangeMenuSection={handleChangeMenuSection}
                                handleIsMenuDrawerOpened={handleIsMenuDrawerOpened}
                            />
                        }
                    </Column>

                    <Column
                        className="scrollable-list m-0 p-0"
                        md="8"
                        lg="8">
                        <ActiveSectionItems
                            items={activeMenuSectionItemsApproved}
                            emptyMofifierGroup={empty_modifier_group}
                            onMenuItemRecommendationsModal={onMenuItemRecommendationsModal}
                            orderQuantities={orderQuantities}
                            onReservation={onReservation}
                            showSpecial
                            handleMenuItemDetails={handleMenuItemDetails}
                        />
                    </Column>
                    {
                        menuItemDetails && !onReservation && onMenuItemDetailsModal &&
                        <MenuItemDetailsModal
                            show={!isEmpty(menuItemDetails)}
                            close={handleMenuItemDetails}
                            item={menuItemDetails}
                            activeMenuSectionId={activeMenuSectionId}
                        />
                    }
                    {
                        menuItemDetails && onAddEditDeleteItemModal && !onAddRecommendedMenuItemsModal ?
                            <AddMenuItemModal
                                show={!isEmpty(menuItemDetails)}
                                item={
                                    !isEmpty(recommendedItemDetails) ?
                                        recommendedItemDetails :
                                        menuItemDetails
                                }
                                menuItemRecommendations={menuItemRecommendations}
                                recommendedItemDetails={recommendedItemDetails}
                                handleCloseRecommendedMenuItemModal={handleCloseRecommendedMenuItemModal}
                                activeMenuSectionId={activeMenuSectionId}
                                close={!isEmpty(recommendedItemDetails) ?
                                    handleOnAddRecommendedItemModal :
                                    handleOnCloseMenuItemModal}
                                onAddClickHandler={onAddClickHandler}
                            /> :
                            null
                    }

                    {
                        menuItemDetails && menuItemRecommendations && !onAddEditDeleteItemModal && onAddRecommendedMenuItemsModal ?
                            <RecommendedMenuItemsModal
                                show={!isEmpty(menuItemRecommendations)}
                                items={menuItemRecommendations}
                                menuItemDetails={menuItemDetails}
                                onMenuItemRecommendationsModal={onMenuItemRecommendationsModal}
                                close={handleCloseMenuItemRecommendationModal}
                                handleMenuItemDetails={handleMenuItemDetails}
                                handleRecommendedItemDetails={handleRecommendedItemDetails}

                            /> :
                            null
                    }
                </Row>
            </Column>
        </Row>
    )
}

export default RestaurantMenuApproved