import isEmpty from 'lodash.isempty'
import Validator from 'validator'

function validateLogin({ email, password }) {
  const errors = {}
  if (!Validator.isEmail(email)) errors.email = 'Invalid email address'
  if (isEmpty(password)) errors.password = 'Password cannot be empty'

  return { errors, isValid: isEmpty(errors) }
}

export default validateLogin
