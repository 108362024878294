import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { ApplePay } from "./components/ApplePay";
import ChoosePaymentMethod from "./components/ChoosePaymentMethod";
import { GooglePay } from "./components/GooglePay";
import SpreedlyLogic from "./components/SpreedlyLogic";
import { paymentComponentsEnum } from "./enums";
import './SpreedlyCheckout.css';

const SpreedlyCheckoutVault = ({onCompleteOrderClick, onPayment}) => {
    const {
        orders: { paymentMethods },
    } = useSelector(state => state)

    const [activePaymentMethodButton, setActivePaymentMethodButton] = useState(0)

    const {
        showCreditCardVault,
        setIsAddNewMethod,
        isNewCardFormValid,
        onSaveCard,
        onEdit,
        editMode,
        cancelEditingCardRef,
    } = SpreedlyLogic({ cb: onCompleteOrderClick, onPayment })

    const showGooglePay = () => (
        <GooglePay onLoadPaymentData={({token, cardType, last4Digits, zipCode}) => {
            onCompleteOrderClick({
                spreedlyInformation: {
                    token,
                    cardType,
                    last4Digits,
                    isFirstTime: false,
                    isGooglePay: true,
                    zipCode
                }
            })
        }} />
    )

    const showApplePay = () => (
        <ApplePay onLoadPaymentData={onCompleteOrderClick} />
    )

    const onChoosePaymentMethod = (idx) => {
        setActivePaymentMethodButton(idx)
    }

    const transformPaymentMethodPosition = useCallback((paymentMethod) => {
        return Object.values(paymentComponentsEnum)
            .filter(paymentMethod => paymentMethod.isEnabled)
            .indexOf(paymentMethod) === activePaymentMethodButton
    }, [activePaymentMethodButton])

    useEffect(() => {
        if (!transformPaymentMethodPosition(paymentComponentsEnum.CREDIT_CARD)) {
            if (editMode) onEdit()
            else setIsAddNewMethod(false)
        } else if (transformPaymentMethodPosition(paymentComponentsEnum.CREDIT_CARD) && !editMode) {
            if ((paymentMethods.length === 0)) setIsAddNewMethod(true)
        }
    }, [transformPaymentMethodPosition, editMode, onEdit, paymentMethods])

    return (
        <React.Fragment>
            <ChoosePaymentMethod activePaymentMethodButton={activePaymentMethodButton}
                                 onChoosePaymentMethod={onChoosePaymentMethod}/>

            {transformPaymentMethodPosition(paymentComponentsEnum.CREDIT_CARD) ? showCreditCardVault() : null}
            {transformPaymentMethodPosition(paymentComponentsEnum.APPLE_PAY) ? showApplePay() : null}
            {transformPaymentMethodPosition(paymentComponentsEnum.GOOGLE_PAY) ? showGooglePay() : null}
            {/* {editMode && transformPaymentMethodPosition(paymentComponentsEnum.CREDIT_CARD) ?
                <p ref={cancelEditingCardRef} className="spreedly-checkout-vault-meta-cancel-edit" onClick={onEdit}>Cancel editing</p> : null} */}

            {transformPaymentMethodPosition(paymentComponentsEnum.CREDIT_CARD) ?
                <div className="pay-button">
                    <button onClick={onSaveCard} className="btn btn-primary" disabled={!isNewCardFormValid()}>
                        Pay now
                    </button>
                </div> : null}
        </React.Fragment>
    )
}

export default SpreedlyCheckoutVault
