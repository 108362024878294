import {
  SET_CHECKOUT_INFO,
  SET_NEW_ORDER_DETAILS,
  SET_ORDER_NEXT_ROUTE,
  SET_ORDER_PROCESSING,
  SET_ORDERS,
  SET_ORDERS_ROUNDS,
  SET_ORDERS_NO_ROUNDS,
  SET_LAST_ROUND_ORDERS,
  SET_PAYMENT_METHODS_FOR_USER,
  SET_USER_RATING,
  SET_BOOKED_RESERVATION_RETURN
} from '../../../config/actionTypes'
import { reservations_path } from '../../../config/pages_paths'

const initialState = {
  orders: [],
  ordersRounds: [],
  ordersNoRounds: [],
  onNextRoute: { path: reservations_path, tabIndex: 1 },
  checkoutInfo: { orders: [], totalTip: 10, tip: 0, total: 0, sections: [] },
  newOrderDetails: {},
  orderProcessing: false,
  paymentMethods: [],
  lastRoundOrders: [],
  menuItemsQuantity: [],
  sectionItemsQuantity: [],
  userRating: {},
  bookedReservationReturn: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return { ...state, orders: action.payload }
    case SET_ORDERS_ROUNDS:
      return { ...state, ordersRounds: action.payload }
    case SET_ORDERS_NO_ROUNDS: 
      return { ...state, ordersNoRounds: action.payload }
    case SET_LAST_ROUND_ORDERS:
      const  menuItemsQuantity = action.payload?.reduce((acc, {menu_id: menuId, quantity}) => 
      ({...acc, [menuId]: (acc[menuId] || 0) + quantity}), 
      {})
      const  sectionItemsQuantity = action.payload?.reduce((acc, {menu_section_id: menuSectionId, quantity}) => 
      ({...acc, [menuSectionId]: (acc[menuSectionId] || 0) + quantity}), 
      {})
      // returns duplicate ids once as Object key and the sum of their quantity as values

      const menuItemsQuantityResult = Object.keys(menuItemsQuantity)?.map(item => ({
        menuId: +item, quantity: menuItemsQuantity[item]
      }))
      const sectionItemsQuantityResult = Object.keys(sectionItemsQuantity)?.map(item => ({
        menuSectionId: +item, quantity: sectionItemsQuantity[item]
      }))
      // creates keys for the menu and section ids and their quantity so its more accessable

      return { ...state, 
        lastRoundOrders: action.payload, 
        menuItemsQuantity: menuItemsQuantityResult,
        sectionItemsQuantity: sectionItemsQuantityResult
      }

    case SET_ORDER_NEXT_ROUTE:
      return { ...state, onNextRoute: action.payload }
    case SET_CHECKOUT_INFO:
      return { ...state, checkoutInfo: action.payload }
    case SET_NEW_ORDER_DETAILS:
      return { ...state, newOrderDetails: action.payload }
    case SET_ORDER_PROCESSING:
      return { ...state, orderProcessing: action.payload }
    case SET_PAYMENT_METHODS_FOR_USER:
      return { ...state, paymentMethods: action.payload.sort((a,b) => { 
        //sorts payment methods by id, always returning the active first
        if (a.isSelected === b.isSelected) {
        return a.id - b.id;
      } else {
        return a.isSelected ? -1 : 1;
      }}) }
    case SET_USER_RATING: 
      return { ...state, userRating: action.payload }
    case SET_BOOKED_RESERVATION_RETURN:
       return { ...state, bookedReservationReturn: action.payload}
    default:
      return state
  }
}
