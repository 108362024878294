import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import BackIcon from '../../assets/ico-back.svg'
import LandingLogo from '../../assets/logo.svg'
import BlueLogo from '../../assets/logo_2.svg'
import { aboutus_page_path, credit_card_information_path, help_page_path, home_path, landing_path, privacy_page_path, profile_page_path, qr_order_landing_path, reservations_path, terms_page_path } from '../../config/pages_paths'
import useScroll from '../../hooks/useScroll'
import { setActiveModal, setPageRoutePath } from '../../store/actions/feedbackActions'
import { navigateToParentUrl } from '../../utils/navigateToParentUrl'
import Button from '../common/Button'
import './Navigation.css'
import { navItems } from './config'

export function GetTheAppButton({ className, renderConditionally, pathname }) {
  // TODO move links on backend and improve platform detection
  const onClick = () => {
    let url = 'https://play.google.com/store/apps/details?id=nyc.voila'
    if (
      new RegExp(
        /(iPhone|iPod|iPad|Macintosh|MacIntel|MacPPC|Mac68K|Pike|ios)/gim
      ).test(window.navigator.platform)
    ) {
      url = 'https://apps.apple.com/ug/app/voila-dining/id1553458462'
    }
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.click()
  }

  const renderButton = () => {
    return (
      <Button
        text={<span>Get the app</span>}
        onClick={onClick}
        className={className || 'nav-item m-2 round-btn get-the-app-btn'}
      />
    )
  }

  return renderConditionally
    ? pathname === home_path
      ? renderButton()
      : null
    : renderButton()
}

function Navigation() {
  const location = useLocation()
  const history = useHistory()
  const headerRef = useRef()
  const dispatch = useDispatch()
  const {
    auth: { isAuthenticated },
    // booking: { reservations },
  } = useSelector(state => state)
  const [isDown] = useScroll(headerRef)
  const [showNavigationArrow, setShowNavigationArrow] = useState(true)

  const pathsToHideArrow = [
    home_path,
    profile_page_path,
    aboutus_page_path,
    credit_card_information_path,
    terms_page_path,
    help_page_path,
    privacy_page_path,
    qr_order_landing_path,
    reservations_path,
  ];

  useEffect(() => {
    setShowNavigationArrow(!pathsToHideArrow.includes(location.pathname));
  }, [location]);

  // const [numOfReservations, setNumOfReservations] = useState(0)

  // const isSmallViewPort = useMediaQuery((theme) => theme.breakpoints.down('md'))
  // const anchor = !isSmallViewPort ? 'top' : 'bottom'
  // const [anchorEl, setAnchorEl] = useState(null)
  // const [numOfReservations, setNumOfReservations] = useState(0)

  const onNavItemClick = path => {
    history.push(path)
  }

  const onAuthProtectedClick = path => {
    dispatch(setPageRoutePath(path))
  }

  const openModalHandler = modal => {
    dispatch(setActiveModal(modal))
  }

  // const handleNumOfReservations = useCallback(() => {
  //   let numReservations = 0;
  //   if (!isEmpty(reservations?.ongoing)) {
  //     numReservations = 1;
  //   }
  //   numReservations += reservations.upcoming.length;
  //   setNumOfReservations(numReservations);

  //   return () => {
  //     setNumOfReservations(0);
  //   }
  // }, [reservations]);

  // useEffect(() => {
  //   handleNumOfReservations()
  // }, [handleNumOfReservations])

  return (
    <header
      ref={headerRef}
      // style={{ backgroundColor: 'transparent' }}
      className={classnames('container sticky-top ', {
        'landing-nav': location.pathname === landing_path,
        'nav-bg-scrolled': isDown,
        'home-nav': location.pathname === home_path
      })}
    >
      <nav
        className={classnames('navbar navbar-expand-lg navbar-light', {
          'landing-brand':
            location.pathname === landing_path && !isAuthenticated
        })}
      >
        {
          location.pathname === landing_path || location.pathname === home_path ? (
            <Link className="navbar-brand logo-wrapper" to="/">
              <img alt="logo"
                src={
                  location.pathname === landing_path && !isAuthenticated
                    ? isDown
                      ? BlueLogo
                      : LandingLogo
                    : BlueLogo
                }
                className={location.pathname === landing_path && !isAuthenticated ? 'logo' : 'blue-logo'}
              />
            </Link>
          ) : (
            showNavigationArrow ?
              <div className="nav-item-page">
                {/* <button onClick={() => navigateToParentUrl(location.pathname)}>
                  <img alt="Back" src={BackIcon} />
                </button> */}
                <Link to={() => navigateToParentUrl(location.pathname)}>
                  <img alt="Back" src={BackIcon} />
                </Link>
              </div> :
              null
          )
        }

        <ul className="navbar-nav ml-auto">
          <div
            className={
              location.pathname === landing_path
                ? 'landing-nav-items'
                : location.pathname === home_path
                  ? 'home-nav-items'
                  : ''
            }
          >
            {navItems[location.pathname === landing_path ? 'landing' : 'user']({
              onNavItemClick,
              onAuthProtectedClick,
              openModalHandler,
              // numOfReservations: numOfReservations
            }).map(({ component: Component, props: navItemProps }, idx) => {
              return <Component key={idx} {...navItemProps} />
            })}
          </div>
          {/*Change temporarily for production, when apps are done change back*/}
          {/*<GetTheAppButton*/}
          {/*  pathname={location.pathname}*/}
          {/*  renderConditionally={true} */}
          {/*/>*/}
        </ul>
      </nav>
    </header>
  )
}

export default Navigation
