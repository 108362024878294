import axios from "axios";
import { SET_VERSION_CONFIG, } from "../../config/actionTypes";
import { version_config } from "../../config/api_endpoints";


export const setVersionConfig = payload => dispatch => {
    dispatch({ type: SET_VERSION_CONFIG, payload })
}

export const getVersionConfig = () => async dispatch => {
    try {
        const { data } = await axios.get(version_config)

        if (data) {
            dispatch(setVersionConfig(data.result))
        }
    } catch (err) {
        console.log(err)
    }
}