import React from 'react'
import classnames from 'classnames'

function Button({
  text,
  onClick = () => {},
  color = 'primary',
  className,
  type = 'button',
  disabled = false
}) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classnames('btn', 'btn-' + color, className)}
    >
      {text}
    </button>
  )
}

export default Button
