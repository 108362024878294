import React from 'react'
import CustomButton from '../../common/CustomButton'

function EditItemButton({ onClick, disabled }) {
  return (
    <div className='add-to-order-btn-wrapper'>
    <CustomButton
      disabled={disabled}
      onClick={onClick}
      text="Done"
      wrapperClassName="booking-btn active add-menu-item-submit-btn add-to-order-btn mx-auto"
      textClassName="booking-btn-text add-menu-item-submit-btn-text add-to-order-btn-text active"
    />
    </div>
  )
}

export default EditItemButton
