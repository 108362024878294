import OpenTableLogo from '../../../assets/logo-opentable@2x.png'
import React from 'react'

function PoweredByOrderMark() {
  return (
    <div className="booking-powered-by mx-auto text-center my-4">
      <span className="text-center">Table booking by:</span>
      <div>
        <img alt="opentable" src={OpenTableLogo} />
      </div>
    </div>
  )
}

export default PoweredByOrderMark
