import React from 'react'
import Modal from '../../modal/Modal'
import './styles/OrdersPaidModal.css'
import LoginButton from '../../auth/login/LoginButton'
// import { useDispatch, useSelector } from 'react-redux'
// import RatingStars from '../../common/rating/RatingStars'
// import { editRating } from '../../../pages/order/services/actions'
// import { useParams } from 'react-router'
// import isEmpty from 'lodash.isempty'

function OrdersPaidModal({ show, close, metadata = {} }) {
  // const { 
  //   orders: { userRating },
  //   feedback: { reservationId }
  // } = useSelector(state => state)

  // const dispatch = useDispatch()

  // const { ratingContent, ratingStars, ratingDescription } = 
  // RatingStars({
  //     existingRatingStars:userRating.ratingStars, 
  //     existingRatingDescription:userRating.ratingDescription
  // })

  // const ratingFeedback = (ratingStars || ratingDescription)

  const handleCloseModal = () => {
    // ratingFeedback && dispatch(editRating(reservationId, ratingStars, ratingDescription))
    close()
}

  return (
    <Modal
      className="orders-paid-modal-container"
      show={show}
      close={handleCloseModal}
    >
      <div className="orders-paid-modal">
        <h3>{metadata.heading}</h3>
        <p>{metadata.paragraph}</p>
          {/* {ratingContent} */}
        <LoginButton style={{ margin: '7px' }} text="OK" onClick={handleCloseModal} />
      </div>
    </Modal>
  )
}

export default OrdersPaidModal
