import hexColors from "../constants/hexColors"

export const genHexIndex = (colors) => {
    if((colors.length) < (hexColors.length))
        return colors.length

    return genRandomHexIndex();
}

const genRandomHexIndex = () => {
    return Math.floor((Math.random() * (hexColors.length - 1)) + 1);
}
