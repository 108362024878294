import React, { Fragment, useState } from 'react'
import Row from '../../containers/layout/Row'
import Column from '../../containers/layout/Column'
import { useDispatch } from 'react-redux'
import { sendClientInfoEmail } from '../../pages/landing/services/actions'

function Footer() {
  return (
    <footer className="footer">
      <Row className="text-center">
        <Column md={12} lg={12}>
          <h3>Interested?</h3>
          <p>We are coming soon to an app store close to you...</p>
          <FooterContactForm />
        </Column>
        <Column className="text-left" md={6} lg={5}>
          <h5>About us</h5>
          <p>
            voilà is an app by The Group, a collection of restaurants based in
            New York City, embodying passion, integrity and excellence. The
            Group provides an authentic dining experience by celebrating
            tradition and invoking the modern.
          </p>
        </Column>
        <Column className="text-left" md={4} lg={2}>
          <h5>Contact</h5>
          <p>108 7th Avenue South, New York NY 10014 | contact@voila.nyc</p>
        </Column>
      </Row>
    </footer>
  )
}

export default Footer

function FooterContactForm() {
  const [opts, setOpts] = useState({
    release_news: true,
    onboarding_news: true
  })
  const [clientEmail, setClientEmail] = useState('')
  const dispatch = useDispatch()

  const onChange = e => {
    e.persist()
    setOpts({ ...opts, [e.target.name]: e.target.checked })
  }

  const onSubmit = e => {
    e.preventDefault()
    if (!clientEmail) return null
    dispatch(sendClientInfoEmail({ email: clientEmail, ...opts }))
  }

  return (
    <Fragment>
      <div className="footer-contact-form">
        <input
          value={clientEmail}
          onChange={e => setClientEmail(e.target.value)}
          placeholder="Leave your e-mail here to be up to date"
        />
        <button onClick={onSubmit}>
          <span id="keep-me-informed">Keep me informed</span>
        </button>
      </div>
      <div className="footer-radios">
        <div>
          <input
            onClick={onChange}
            onChange={onChange}
            id="release_news"
            name="release_news"
            type="checkbox"
            checked={opts.release_news}
          />
          <label htmlFor="release_news">News about the app release</label>
        </div>
        <div>
          <input
            onChange={onChange}
            id="onboarding_news"
            name="onboarding_news"
            type="checkbox"
            checked={opts.onboarding_news}
          />
          <label htmlFor="onboarding_news">
            News about onboarding as a restaurant
          </label>
        </div>
      </div>
    </Fragment>
  )
}
