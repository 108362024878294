import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MenuItemPlaceholder from '../../assets/menu_item_placeholder.png'
import RestaurantPlaceholder from '../../assets/restaurant_placeholder.png'


const Image = ({
    src,
    height,
    width,
    menu,
    restaurant,
    alt,
    effect,
    className,
    style = {},
}) => {

    const [image, setImage] = useState(null)
    const [imagePlaceHolder, setImagePlaceHolder] = useState(null)

    const handleImageError = () => {
        menu && setImage(MenuItemPlaceholder)
        restaurant && setImage(RestaurantPlaceholder)
    }

    useEffect(() => {
        setImage(src)

        menu && setImagePlaceHolder(MenuItemPlaceholder)
        restaurant && setImagePlaceHolder(RestaurantPlaceholder)

        !src && handleImageError()

        return () => setImage(null)
    }, [src])


    return (
        <LazyLoadImage
            height={height ? height : "100%"}
            width={width ? width : "100%"}
            alt={alt ? alt : "image"}
            effect={effect ? effect : "blur"}
            src={image || imagePlaceHolder}
            // placeholderSrc={imagePlaceHolder}
            onError={handleImageError}
            className={className ? className : ""}
            style={style ? style : ""}
        />
    );
};

export default Image;