import isEmpty from "lodash.isempty";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VoilaLogo from '../../assets/logo-voila.svg';
import EmptyReservationImage from '../../assets/empty-plate.png';
import { updateUserName } from "../../pages/auth/services/actions";
import { setUserUpdated } from "../../store/actions/usersActions";
import CustomButton from "../common/CustomButton";
import InputField from "../common/InputField";
import Modal from "../modal/Modal";

function UpdateUserNameModal({ show, close }) {
    const [firstName, setFirstName] = useState("")
    const [errorMsg, setErrorMsg] = useState("")

    const dispatch = useDispatch()

    const { restaurants: { restaurant },
        booking: { reservations: { ongoing } }
    } = useSelector(state => state)


    const handleCloseModal = () => {
        if (firstName.length < 3) {
            setErrorMsg("Your name should be at least 3 characters")
        }
        else if (firstName.length > 12) {
            setErrorMsg("Your name should be less than 12 characters")
        }
        else {
            if(ongoing) {
                dispatch(updateUserName({ first_name: firstName, reservation_id: ongoing.reservation_id }))
                dispatch(setUserUpdated(true))
            }
            close()
        }
    }

    const handleNameChange = e => {
        setFirstName(e.target.value)
        if ((firstName.length >= 2 || firstName.length < 12) && !isEmpty(errorMsg)) {
            setErrorMsg("")
        }
    }

    return (
        <Modal show={show} className="update-name-modal">
            {/* <ModalHeading title="Please enter your name" /> */}
            <div>
                {!isEmpty(ongoing?.logo) && <img src={ongoing.logo} style={{ width: "100%", margin: "0 0 1rem", borderRadius:"0.5rem" }} />}

                {/* <p style={{ margin: "1rem 0" }}>By using our platform you will be charged a total of 20% of your check amount, that consists of a 3% platform fee and a 17% default tip, which is customizable and you can adjust it as you wish</p> */}

                <h3>Please enter your name</h3>
                <InputField
                    name="firstName"
                    onChange={handleNameChange}
                    label=""
                    value={firstName}
                    error={errorMsg}
                    containerClassName='update-name-input'
                />
                <div style={{ marginBottom: "0.5rem" }}>
                    <p>Powered by:</p>
                    <img alt='logo_voila' className='voila-logo' src={VoilaLogo} />
                </div>

                <CustomButton
                    onClick={handleCloseModal}
                    text="Next"
                />
            </div>

        </Modal>
    )
}

export default UpdateUserNameModal