import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSendToKitchenMode } from '../../../pages/reservations/services/actions'
import EditTipModal from '../../orders/EditTipModal'
import { canSendToKitchen } from '../helpers/ordersStatus'
import CheckoutInfo from './CheckoutInfo'
import CloseOrCompleteRound from './CloseOrCompleteRound'
import OrderDeliveryOptions from './OrderDeliveryOptions'
import SendToKitchen from "./SendToKitchen"

function RoundCompletion({
  onCompleteOrderClick,
  onSendToKitchen,
  setPreparationTime,
  setDeliveryOption,
  checkoutInfo = { orders: [], sections: [] },
  otherTryingToPay,
  payForUsers,
  hasNewOrder,
  onPaymentCheckboxChange,
  orderUserIds,
  onRefreshVoidedOrders,
  showOrderMoreCondition,
}) {
  const {
    orders: {
      ordersRounds,
      checkoutInfo: { subTotalInt }
    },
    booking: { isSendToKitchenMode },
    auth: {
      user: { id }
    },
    restaurant: { isPaymentRequired }
  } = useSelector(state => state)
  const dispatch = useDispatch()

  const [showTipModal, setTipModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const showInfoModalHandler = () => {
    setShowInfoModal(true)
  }

  const onCloseInfoModal = () => {
    setShowInfoModal(false)
  }

  const onEditTipHandler = () => {
    setTipModal(true)
  }

  const onCloseTipModal = () => {
    setTipModal(false)
  }

  const onDeliveryOption = e => {
    setDeliveryOption(e.target.id)
  }

  useEffect(() => {
    if (ordersRounds)
      dispatch(
        setSendToKitchenMode(canSendToKitchen(ordersRounds, payForUsers, id))
      )
  }, [ordersRounds, payForUsers, id, dispatch])

  return (
    <div className="order-checkout-wrapper">
      {showTipModal ? (
        <EditTipModal
          total={checkoutInfo.subTotalInt}
          showTipModal={showTipModal}
          onCloseTipModal={onCloseTipModal}
          payForUsers={payForUsers}
        />
      ) : null}
      {showInfoModal ? (
        <EditTipModal
          type="info"
          showTipModal={showInfoModal}
          onCloseTipModal={onCloseInfoModal}
        />
      ) : null}
      {!isSendToKitchenMode ? (
        !otherTryingToPay ? (
          <CheckoutInfo onEditTipHandler={onEditTipHandler} showInfoModalHandler={showInfoModalHandler} />
        ) : null
      ) : (
        <OrderDeliveryOptions
          setOption={onDeliveryOption}
          checkoutInfo={checkoutInfo}
          setPreparationTime={setPreparationTime}
        />
      )}
      {isPaymentRequired ? (
        <CloseOrCompleteRound
          onCompleteOrderClick={onCompleteOrderClick}
          onSendToKitchen={onSendToKitchen}
          otherTryingToPay={otherTryingToPay}
          isSendToKitchenMode={isSendToKitchenMode}
        />
      ) : (
        <SendToKitchen
          onCompleteOrderClick={onCompleteOrderClick}
          onSendToKitchen={onSendToKitchen}
          otherTryingToPay={otherTryingToPay}
          isSendToKitchenMode={isSendToKitchenMode}
          hasNewOrder={hasNewOrder}
          onPaymentCheckboxChange={onPaymentCheckboxChange}
          payForUsers={payForUsers}
          orderUserIds={orderUserIds}
          onRefreshVoidedOrders={onRefreshVoidedOrders}
          showOrderMoreCondition={showOrderMoreCondition}
        />
      )}
    </div>
  )
}

export default RoundCompletion
