import { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import { setActiveModal } from '../store/actions/feedbackActions'
import { login_modal, update_user_modal } from '../config/modal_paths'
import { useDispatch, useSelector } from 'react-redux'

function  useUserDetails({ requireAllFields = true }) {
  const { user } = useSelector(state => state.user)
  const [hasAllFields, setHasAllFields] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // if(user.is_anonymous) 
      // dispatch(setActiveModal(login_modal))
    if ((!isEmpty(user) || user.is_anonymous) && requireAllFields) {
      if (!user.first_name || !user.phone || !user.last_name || !user.email) {
        // dispatch(setActiveModal(update_user_modal))
        setHasAllFields(false)
      } else setHasAllFields(true)
    }
  }, [dispatch, user, requireAllFields])

  const onMissingFields = () => {
    dispatch(setActiveModal(update_user_modal))
  }

  return { onMissingFields, hasAllFields }
}

export default useUserDetails
