import React from 'react'
import CustomButton from '../../common/CustomButton'

function RemoveItemButton({ onClick, disabled }) {
  return (
    <CustomButton
      disabled={disabled}
      onClick={onClick}
      text="Remove"
      wrapperClassName="booking-btn active add-menu-item-submit-btn remove-order-btn mx-auto"
      textClassName="booking-btn-text add-menu-item-submit-btn-text remove-order-btn-text active"
    />
  )
}

export default RemoveItemButton
