import React from 'react'
import classnames from 'classnames'

function ReservationInfoLabel({ label, info, table, style, className }) {
  return (
    <div
      style={style}
      className={classnames('reservation-info-container', className)}
    >
      {/* <div className="reservation-label">
        <span className="text-right">{label ? label + ':' : null}</span>
      </div> */}
      {/* <div className={classnames('reservation-info')}>{info}</div> */}
      {info}
      <div className={classnames('reservation-table')}>{table}</div>
    </div>
  )
}

export default ReservationInfoLabel
