import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../common/InputField';
import {
  deactivateAccount,
  logoutUser,
  updateUser,
  updateUserPassword,
} from '../../pages/auth/services/actions';
import { home_path } from '../../config/pages_paths';
import { useHistory } from 'react-router';
import { useGoogleLogout } from 'react-google-login';
import './ProfileDetails.css';
import { getUser } from '../../store/actions/usersActions';
import useForm from '../../hooks/useForm';
import isEmpty from 'lodash.isempty';
import validateUserProfile from '../../validation/validateUserProfile';
import DeactivateAccountModal from './DeactivateAccountModal';
import validateUserPassword from '../../validation/validateUserPassword';

function ProfileDetails() {
  const {
    user: { user: userDetails },
    errors,
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [formErrors, setErrors] = useState({});
  const [deactivateAccountModal, setDeactivateAccountModal] = useState(false);

  const onLogout = () => {
    try {
      dispatch(logoutUser());
      signOut();
      if (window.FB) window.FB.logout();
      history.go(0)
    } catch (err) {}
    history.push(home_path);
  };

  const onLogoutSuccess = () => {
    console.log('Successfully logout from google');
  };

  const onFailure = err => {
    console.log('google logout failure', err);
  };

  const { signOut } = useGoogleLogout({
    onFailure,
    clientId: '55700200557-8un2ipb96sqmhpuuf8ilh9riv062st5j.apps.googleusercontent.com',
    onLogoutSuccess,
  });

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const onUpdateUserDetails = e => {
    e && e.preventDefault();

    const newValues = {
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      phone: values.phone.trim(),
    };
    setValues(newValues);

    const { errors, isValid } = validateUserProfile(newValues);

    if (!isValid) {
      setErrors(errors);
      return null;
    } else setErrors({});

    dispatch(updateUser(newValues));
  };

  const onUpdateUserPassword = e => {
    e && e.preventDefault();

    const newValues = userDetails.has_pw ? {
      password: values.password.trim(),
      new_password: values.new_password.trim(),
      confirm_password: values.confirm_password.trim(),
      has_pw: userDetails.has_pw,
    } : {
      new_password: values.new_password.trim(),
      confirm_password: values.confirm_password.trim(),
      has_pw: userDetails.has_pw,
    }

    const { errors, isValid } = validateUserPassword(newValues);

    setValues(newValues);
    if (!isValid) {
      setErrors(errors);
      return null;
    } else setErrors({});

    dispatch(updateUserPassword(newValues, !userDetails.has_pw));
  };

  const handleSubmit = (e, formName) => {
    // Prevent form submission
    e.preventDefault();

    if (formName === 'update-user-form') {
      onUpdateUserDetails(e);
    } else if (formName === 'update-user-password') {
      onUpdateUserPassword(e);
    }
  };

  const { values, handleChange, setValues } = useForm(
    {
      email: userDetails.email || '',
      first_name: userDetails.first_name || '',
      last_name: userDetails.last_name || '',
      phone: userDetails.phone || '',
      password: '',
      new_password: '',
      confirm_password: '',
    },
    handleSubmit
  );

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      setValues(values => ({ ...values, ...userDetails }));
    }
  }, [userDetails, setValues]);

  const onDeactivateAccount = () => {
    setDeactivateAccountModal(curState => !curState);
  };

  const onDeactivateHandler = () => {
    dispatch(deactivateAccount(onLogout));
  };

  return (
    <React.Fragment>
      {deactivateAccountModal ? (
        <DeactivateAccountModal
          show={deactivateAccountModal}
          close={onDeactivateAccount}
          onDeactivate={onDeactivateHandler}
        />
      ) : null}
      <h3 className="account-information">Account information</h3>
      <form id="update-user-form" onSubmit={e => handleSubmit(e, 'update-user-form')}>
      <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          disabled={true}
          onChange={handleChange}
          name="email"
          value={values.email}
          error={formErrors.email || errors.email}
          label="E-mail address"
        />
        <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          onChange={handleChange}
          name="first_name"
          value={values.first_name}
          error={formErrors.first_name || errors.first_name}
          label="First name"
        />
        <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          onChange={handleChange}
          name="last_name"
          value={values.last_name}
          error={formErrors.last_name || errors.last_name}
          label="Last name"
        />
        <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          onChange={handleChange}
          name="phone"
          value={values.phone}
          error={formErrors.phone || errors.phone}
          label="Phone"
          placeholder="Enter the phone number with + and the country code"
        />

        <div className="submit-button">
          <div className={"submit-btn-primary"}>
            <button type="submit" className="btn btn-primary"><span>Save changes</span></button>
          </div>
          <div className={"submit-btn-secondary"}>
            <button className="btn btn-danger" onClick={onLogout}><span>Log out</span></button>
          </div>
        </div>
        <div className="update-btn-container">
        <button className="deactivate-acc-btn" onClick={onDeactivateAccount}><span>Deactivate account</span></button>
        </div>
      </form>

      {/* ... (rest of the component code) */}

      <div style={{margin:"1rem 0"}}>
        <h3 className="account-information">
          {
            userDetails.has_pw
            ? "Change Password"
            : "Enter Password"
          }
        </h3>
        <form id="update-user-password" onSubmit={e => handleSubmit(e, 'update-user-password')}>
        { userDetails.has_pw &&
          <InputField
            inputClassName="profile-input"
            labelClassName="profile-input-label"
            errorClassName="profile-input-invalid-feedback"
            onChange={handleChange}
            name="password"
            value={values.password}
            placeholder={'******'}
            error={formErrors.password || errors.password}
            label="Current Password"
            type="password"
          />
        }
          <InputField
            inputClassName="profile-input"
            labelClassName="profile-input-label"
            errorClassName="profile-input-invalid-feedback"
            onChange={handleChange}
            name="new_password"
            value={values.new_password}
            placeholder={'******'}
            error={formErrors.new_password || errors.new_password}
            label="New Password"
            type="password"
          />
          <InputField
            inputClassName="profile-input"
            labelClassName="profile-input-label"
            errorClassName="profile-input-invalid-feedback"
            onChange={handleChange}
            name="confirm_password"
            value={values.confirm_password}
            placeholder={'******'}
            error={formErrors.confirm_password || errors.confirm_password}
            label="Confirm Password"
            type="password"
          />
         <div className="submit-button">
          <div className={"submit-btn-primary"}>
            <button type="submit" className="btn btn-primary"><span>Save changes</span></button>
          </div>
        </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default ProfileDetails;

