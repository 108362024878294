import Switch from 'react-switch'
import React from 'react'

function EmailMarketingOpt({ onChange, checked = true }) {
  return (
    <div className="offers-switch-container">
      <div>
        <p>Receive restaurant emails</p>
        <span>Sign me up to receive dining offers and news</span>
      </div>
      <Switch
        onChange={onChange}
        checked={checked}
        onColor="#020083"
        checkedIcon={false}
        uncheckedIcon={false}
      />
    </div>
  )
}

export default EmailMarketingOpt
