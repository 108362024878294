import React from 'react'

const newCardInformationErrorsEnum = {
    isCcNumberValid: 'Card number is invalid',
    isCvvValid: 'CVV is invalid',
    isExpDateValid: 'Expiration date is invalid',
    isZipCodeValid: 'ZIP Code is invalid'
}

const NewCardForm = ({
                         cardType,
                         onExpDateChange,
                         expDate,
                         card_types_enum,
                         isCcNumberValid,
                         isCvvValid,
                         isExpDateValid,
                         zipCode,
                         isZipCodeValid,
                         onZipCodeChange,
                         isCcNumberTouched,
                         isCvvTouched,
                         isExpDateTouched,
                         isZipCodeTouched,
                     }) => {
    return (
        <form id="payment-form">
            <div className='spreedly-card-number-type-wrapper'>
                <div className='spreedly-card-number-wrapper'>
                    <label className="spreedly-card-number-label">Card Number</label>
                    <div
                        id="spreedly-number"
                        className='spreedly-new-card-input'
                    >
                    </div>
                </div>
                <div className='spreedly-card-type-wrapper'>
                    <label>Type</label>
                    <img
                        className="spreedly-cc-icon"
                        alt="cc-card-type"
                        src={card_types_enum[cardType]}
                    />
                </div>
            </div>
            {isCcNumberTouched && !isCcNumberValid ? (
                <p
                    style={{
                        color: 'red',
                        textAlign: 'start',
                        marginBottom: 0
                    }}
                >
                    {newCardInformationErrorsEnum['isCcNumberValid']}
                </p>
            ) : null}
            <br/>

            <div className="spreedly-details-container">
                <div className="spreedly-flex-column">
                    <label className="spreedly-exp-date-label" htmlFor="month">
                        Expiration Date
                        {/*<span className="spreedly-label-hint">(MM/YYYY)</span>*/}
                    </label>
                    <input
                        onChange={onExpDateChange}
                        type="tel"
                        placeholder="MM/YY"
                        id="exp-date"
                        name="exp-date"
                        maxLength="5"
                        value={expDate}
                        className='spreedly-new-card-input spreedly-exp-date-input'
                    />
                    {isExpDateTouched && !isExpDateValid ? (
                        <p
                            style={{
                                color: 'red',
                                textAlign: 'start',
                                marginBottom: 0
                            }}
                        >
                            {newCardInformationErrorsEnum['isExpDateValid']}
                        </p>
                    ) : null}
                </div>

                <div className="spreedly-flex-column spreedly-cvc-wrapper">
                    <label className="spreedly-cvv-label">
                        CVC code
                        {/*CVV <span className="spreedly-label-hint">(3 digits)</span>*/}
                    </label>
                    <div
                        id="spreedly-cvv"
                        className='spreedly-new-card-input spreedly-cvv-input'
                    />
                    {isCvvTouched && !isCvvValid ? (
                        <p
                            style={{
                                color: 'red',
                                textAlign: 'start',
                                marginBottom: 0
                            }}
                        >
                            {newCardInformationErrorsEnum['isCvvValid']}
                        </p>
                    ) : null}
                </div>

                <div className="spreedly-zip-code-wrapper">
                    <label className="spreedly-zip-code-label" htmlFor="month">
                        ZIP Code
                        {/*<span className="spreedly-label-hint">(MM/YYYY)</span>*/}
                    </label>
                    <input
                        onChange={onZipCodeChange}
                        type="tel"
                        placeholder="e.g 12345"
                        id="zip-code"
                        name="zip-code"
                        maxLength="7"
                        value={zipCode}
                        className='spreedly-new-card-input spreedly-zip-code-input'
                    />
                    {isZipCodeTouched && !isZipCodeValid ? (
                        <p
                            style={{
                                color: 'red',
                                textAlign: 'start',
                                marginBottom: 0
                            }}
                        >
                            {newCardInformationErrorsEnum['isZipCodeValid']}
                        </p>
                    ) : null}
                </div>
            </div>
        </form>
    )
}

export default NewCardForm
