import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import './ManageReservation.css'
import ReservationDateIcon from '../../assets/clock_white.png'
import CustomButton from '../common/CustomButton'
import {
  booking_page_path,
  booking_reservation_path,
  order_page_path,
  reservations_path,
  table_number_page_path
} from '../../config/pages_paths'
import { useHistory } from 'react-router'
import { setInitReservationType } from '../../pages/reservations/services/actions'
import { useDispatch } from 'react-redux'
import reservationTypeEnum from '../../enums/reservationTypeEnum'

const bookingTabs = () => [
  { label: 'Reservation', checked: true, id: 0 },
  { label: 'New dining', checked: false, id: 1 }
]

function ManageReservation({
  date_string: date,
  size_of_party,
  show_booking_flow,
  restaurant_id,
  reservation_id,
  dummy,
  isNow,
  reservationTypeOnCurrentRestaurant,
  restaurantIdOnCurrentRestaurant,
}) {
  const [tabs, setTabs] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (reservation_id && !dummy && !isNow) {
      setTabs(bookingTabs())
    }
  }, [dummy, reservation_id, isNow])

  const onTabClick = id => {
    setTabs(tabs => {
      return tabs.map(tab => {
        tab.checked = tab.id === id
        return tab
      })
    })
  }

  const activeTabIndex = tabs.findIndex(val => val.checked)

  const { label, buttons } =
    activeTabIndex === 0 || dummy || isNow
      ? BookingTab({ date, size_of_party, reservation_id, dummy, isNow, restaurantIdOnCurrentRestaurant, reservationTypeOnCurrentRestaurant })
      : NewDiningTab({ restaurant_id: restaurantIdOnCurrentRestaurant })

  // console.log(activeTabIndex, dummy, isNow)

  const onCreateNewReservation = () => {
    history.push(booking_page_path.replace(':restaurantId', restaurantIdOnCurrentRestaurant))
  }

  return (
    <div className="manage-reservation-container">
      <div className="manage-reservation-tabs">
        {tabs.map(tab => (
          <div key={tab.id}>
            <span
              onClick={() => onTabClick(tab.id)}
              className={classNames('restaurant-dining-tab', {
                active: tab.checked
              })}
            >
              {tab.label}
            </span>
            <div
              className={classNames({ active: tab.checked })}
              id={'checked-tab'}
            />
          </div>
        ))}
      </div>
      <p style={{display: "flex", alignItems: "center", height:"27px"}}>{label}</p>
      <div className="manage-reservation-buttons">{buttons}</div>
      {/* {dummy || isNow ? (
        <div
          onClick={onCreateNewReservation}
          className={'create-new-reservation-inline-btn'}
        >
          Make a new reservation
        </div>
      ) : null} */}
    </div>
  )
}

function BookingTab({ date, size_of_party, reservation_id, dummy, isNow, restaurantIdOnCurrentRestaurant, reservationTypeOnCurrentRestaurant }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const onOrderClickHandler = () => {
    dispatch(setInitReservationType(1))
    history.push(order_page_path.replace(':reservationId', reservation_id))
  }

  const onManageClick = () => {
    if (dummy) {
      history.push(reservations_path)
      dispatch(setInitReservationType(1))
    } 
      else if(reservationTypeOnCurrentRestaurant === reservationTypeEnum.upcoming) {
        history.push(booking_reservation_path
          .replace(':restaurantId', restaurantIdOnCurrentRestaurant)
          .replace(':reservationId', reservation_id))
      }
    else
      history.push(order_page_path.replace(':reservationId', reservation_id))
  }

  return {
    label: (
      <>
        <img
          className="restaurant-reservation-icon"
          src={ReservationDateIcon}
          alt="reservation-date"
        />{' '}
        {dummy || isNow ? (
          <>Order placed. Something else?</>
        ) : (
          <>
            <strong id="reservation-pill-label">Reservation: {' '}</strong>  {date} |{' '}
            {size_of_party} people
          </>
        )}
      </>
    ),
    buttons: (
      <>
        <CustomButton
          onClick={onOrderClickHandler}
          text="Select items"
          textClassName="reservation-order-btn-text2"
          wrapperClassName="reservation-order-btn"
        />
        {!dummy && !isNow ? (
          <div onClick={onManageClick} className="reservation-modify-btn menage-reservation-btn">
            Manage
          </div>
        ) : null}
      </>
    )
  }
}

function NewDiningTab({ restaurant_id }) {
  const history = useHistory()

  const onBookClick = () => {
    history.push(booking_page_path.replace(':restaurantId', restaurant_id))
  }

  const onOrderClick = () => {
    history.push(table_number_page_path.replace(':restaurantId', restaurant_id))
  }

  return {
    label: 'Make another reservation or dine now',
    buttons: (
      <>
        <CustomButton
          onClick={onBookClick}
          text="Book"
          textClassName="reservation-order-btn-text2"
          wrapperClassName="reservation-order-btn"
        />
        <CustomButton
          onClick={onOrderClick}
          text="Order"
          textClassName="reservation-order-btn-text2"
          wrapperClassName="reservation-order-btn"
        />
      </>
    )
  }
}

export default ManageReservation
