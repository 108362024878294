import classNames from "classnames";
import isEmpty from "lodash.isempty";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { order_page_path } from "../../../config/pages_paths";
import { orderStatusEnum } from "../../../enums/orderStatusEnum";
import { setPayingForOthers } from "../../../pages/book/services/actions";
import { getCheckoutInfo } from "../../../pages/order/services/actions";
import { getReceipt } from "../../../pages/reservations/services/actions";
import { setIsForWhomToPayConfirmed } from "../../../store/actions/feedbackActions";
import { setIsPay, setIsProceededToCheckoutSelection, setUpdateUserReceipt } from "../../../store/actions/usersActions";
import SeparationLine from "../../common/SeparationLine";
import SelectableTilesButton from "../../orders/buttons/SelectableTilesButton";
import SpreedlyCheckoutVault from "../../payment/spreedly/SpreedlyCheckoutVault";
import { autoScrollTo } from "../helpers/autoSrollTo";

function SendToKitchen({
  isSendToKitchenMode,
  onSendToKitchen,
  onCompleteOrderClick,
  hasNewOrder,
  onPaymentCheckboxChange,
  payForUsers,
  orderUserIds,
  otherTryingToPay,
  onRefreshVoidedOrders,
  showOrderMoreCondition,
}) {
  const {
    auth: {
      user: { id }
    },
    orders: {
      checkoutInfo: { subTotalInt, total, tip, checkoutInfoPerUsers, checkoutInfoOnTable },
      ordersRounds,
      noTipReason,
    },
    booking: {
      idsSomeonePaysFor,
      payingForOthersState,
      currentReservation
    },
    user: { isPay, isProceededToCheckoutSelection, updateUserReceipt, },
    feedback: { isForWhomToPayConfirmed },
    restaurant: { restaurant }
  } = useSelector(state => state)

  const history = useHistory()

  const [userPayingForMe, setUserPayingForMe] = useState(null)
  const [orderLessThanZeroErrorMsg, setOrderLessThanZeroErrorMsg] = useState(false)
  const [isMoreThanOneGuestOnTable, setIsMoreThanOneGuestOnTable] = useState(false)
  const [isPayForYourselfSelected, setIsPayForYourselfSelected] = useState(false)
  const [isOnlySelectedToPayForYourself, setIsOnlySelectedToPayForYourself] = useState(false)
  const [hasThisUserPaidForHimself, setHasThisUserPaidForHimself] = useState(false)
  const [onSelectingUsersToPayFor, setOnSelectingUsersToPayFor] = useState(false)
  const [payByGuest, setPayByGuest] = useState(false)
  const [selectedUsersForPayment, setSelectedUsersForPayment] = useState([])
  const [showFeedbackMessages, setShowFeedbackMessages] = useState({
    someoneStartedPaymentProcess: false,
  })
  const [userCheckoutInfo, setUserCheckoutInfo] = useState({})
  const [showReceiptButton, setShowReceiptButton] = useState(false)
  const [receiptURL, setReceiptURL] = useState('')

  const spreedlyCheckoutVaultRef = useRef(null)
  const selectWhoToPayForRef = useRef(null)
  const paymentGuestSelection = useRef(null)
  const dispatch = useDispatch()
  const remainingBalance = (parseFloat(checkoutInfoOnTable.total) - parseFloat(total.split("$")[1])).toFixed(2)
  const reservationDate = new Date(+currentReservation.date).toLocaleString({ hour12: true })
  // console.log({showOrderMoreCondition, isPaymentRequired, showReceiptButton, receiptURL, restaurant, reservationDate})


  useEffect(() => {
    // check if any user's order is paid by himself
    // if there is none, hide the receipt button
    // all orders have been paid by other users
    if (currentReservation?.has_receipt) {
      setShowReceiptButton(true)
    }
  }, [ordersRounds, id, currentReservation, showReceiptButton])

  useEffect(() => {
    if (currentReservation?.reservation_id && currentReservation.order_status === orderStatusEnum.orderPaid && (!receiptURL || updateUserReceipt)) {
      dispatch(getReceipt(currentReservation?.reservation_id, setReceiptURL))
      dispatch(setUpdateUserReceipt(false))
    }

  }, [dispatch, currentReservation, updateUserReceipt, receiptURL])

  useEffect(() => {
    if (ordersRounds.length > 0) {
      let currentRound = ordersRounds.find(round => !round.round)
      let showErrorMsgArr = [];
      if (currentRound) {
        currentRound.users.forEach(user => {
          const orderWithPrice = user.orders.some(order => order.price > 0)
          if (!orderWithPrice) {
            showErrorMsgArr.push(true);
          } else {
            showErrorMsgArr.push(false);
          }
        })
      } else {
        showErrorMsgArr.push(false);
      }

      if (!showErrorMsgArr.includes(false)) {
        setOrderLessThanZeroErrorMsg(true);
      } else {
        setOrderLessThanZeroErrorMsg(false);
      }
    }
  }, [ordersRounds])

  useEffect(() => {
    if (otherTryingToPay && payingForOthersState.length > 0) {
      const payerId = (payingForOthersState.find(val => val.userIds.find(i => i === id)) || {}).payerId
      const findUserPayingForMe = ordersRounds?.map(round => round.users.map(user => {
        if (user.user_id === payerId) {
          return user.user
        }
      }))
      setUserPayingForMe(findUserPayingForMe[0])
    }
  }, [otherTryingToPay, payingForOthersState, id, orderUserIds])

  useEffect(() => {
    if (!otherTryingToPay) {
      setUserPayingForMe(null)
    }
  }, [otherTryingToPay])

  const isSpreedlyCheckoutVaultVisible = useMemo(() => {
    return !isMoreThanOneGuestOnTable || (isMoreThanOneGuestOnTable && isForWhomToPayConfirmed) && isPay
  }, [isMoreThanOneGuestOnTable, isForWhomToPayConfirmed, isPay])

  const userIdsToSelect = orderUserIds.map(user => {
    const userCheckoutInfo = checkoutInfoPerUsers?.find(info => info.userId === user.userId && info.total !== "0.00")
    const othersPaying = payingForOthersState?.find(other => other?.payerId === id)

    return !idsSomeonePaysFor.some(id => user.userId === id) && (
      <SelectableTilesButton setIsPayForYourselfSelected={setIsPayForYourselfSelected}
        key={user.userId} userId={user?.userId}
        disabled={isSpreedlyCheckoutVaultVisible}
        onClick={() => handleOnPaymentCheckboxChange(user)}
        active={othersPaying?.userIds?.includes(user.userId) !== undefined && othersPaying?.userIds?.includes(user.userId) && payingForOthersState?.payerId !== user?.userId}>
        <span>
          {user.userName.length > 20 ? user.userName.substring(0, 17).concat('...') : user.userName} {'\u00A0'} <b> {`$${userCheckoutInfo?.total ?? 0}`} </b>
        </span>
      </SelectableTilesButton>
    )
  })

  useEffect(() => {
    checkoutInfoPerUsers && setUserCheckoutInfo(checkoutInfoPerUsers?.find(user => user.userId === id))
  }, [checkoutInfoPerUsers])

  useEffect(() => {
    if (userIdsToSelect &&
      userIdsToSelect.length > 0 &&
      (parseFloat(userCheckoutInfo.subTotal) > 0 ||
      checkoutInfoPerUsers?.filter(user => parseFloat(user.subTotal) > 0).length >= 2)) {
      setIsMoreThanOneGuestOnTable(true)
    } else {
      setIsMoreThanOneGuestOnTable(false)
    }
  }, [ordersRounds, userIdsToSelect])

  useEffect(() => {
    //  look in future to unselect self in paying for other guest without items
    for (let i = 0; i < idsSomeonePaysFor.length; i++) {
      if (payForUsers.includes(idsSomeonePaysFor[i]) && parseFloat(userCheckoutInfo.subTotal) === 0) {
        const index = payForUsers.findIndex((id) => id === idsSomeonePaysFor[i]);
        if (index > -1) {
          payForUsers.splice(index, 1);
        }
        dispatch(getCheckoutInfo(currentReservation?.reservation_id, payForUsers.length ? payForUsers : [id]))
        break;
      }
    }

  }, [idsSomeonePaysFor, idsSomeonePaysFor])


  useEffect(() => {
    if (isPay) {
      for (let i = 0; i < payForUsers.length; i++) {
        if (payingForOthersState.length > 0) {
          for (let j = 0; j < payingForOthersState.length; j++) {
            if (payingForOthersState[j].userIds.includes(payForUsers[i]) && payingForOthersState[j].payerId !== id) {
              for (let k = 0; k < payingForOthersState[j].userIds.length; k++) {
                const index = payForUsers.findIndex((id) => id === payingForOthersState[j].userIds[k]);
                if (index > -1) {
                  payForUsers.splice(index, 1);
                }
              }
              dispatch(getCheckoutInfo(currentReservation?.reservation_id, payForUsers.length ? payForUsers : [id]))
              break;
            }
          }
        }
      }
    }

    if (otherTryingToPay && !isEmpty(userPayingForMe) && !hasThisUserPaidForHimself && isPay) {
      dispatch(setIsPay(false))
      dispatch(setIsProceededToCheckoutSelection(false))
    }
  }, [payingForOthersState, isPay])

  useEffect(() => {
    // dispatch(getCheckoutInfo(currentReservation?.reservation_id, payForUsers.length ? payForUsers : [id]))
    dispatch(getCheckoutInfo(currentReservation.reservation_id, payForUsers))
  }, [dispatch, payForUsers])

  useEffect(() => {
    const checkIfUserHavePaidForHimself = checkoutInfoPerUsers?.find(user => user.userId === id && user.total === "0.00")
    checkIfUserHavePaidForHimself?.total === "0.00" ? setHasThisUserPaidForHimself(true) : setHasThisUserPaidForHimself(false)
  }, [checkoutInfoPerUsers])

  useEffect(() => {
    let arr = []
    const finUsersWhoHavePaid = checkoutInfoPerUsers?.map(user => {
      if (user.userId !== id && user.total === "0.00") {
        arr.push(user.userId)
      }
    })
    const removeUsersWhoWerePaidFor = arr?.map(id => {
      const index = payForUsers.indexOf(id);
      if (index > -1) {
        payForUsers.splice(index, 1);
      }
    })
  }, [checkoutInfoPerUsers])

  useEffect(() => {
    const userWithoutSelection = payingForOthersState?.find(user => user.payerId === id && user.userIds.length === 0)
    const userCheckoutInfo = checkoutInfoPerUsers?.find(user => user.userId === id)

    if (userWithoutSelection && (subTotalInt !== parseFloat(userCheckoutInfo.subTotal) && otherTryingToPay && payForUsers.length > 0)) {
      handleSelectingCancelationConfirmation()
    }
  }, [payingForOthersState, otherTryingToPay, payForUsers])

  useEffect(() => {
    if (payingForOthersState.length === 0 && !otherTryingToPay && payForUsers.length > 0 && !isPay && isPayForYourselfSelected) {
      handleSelectingCancelationConfirmation()
      setOnSelectingUsersToPayFor(false)

    }
  }, [payingForOthersState, otherTryingToPay, isPay, payForUsers, isPayForYourselfSelected])

  useEffect(() => {
    const checkIfAllUsersAreSelected = userIdsToSelect.find(id => id !== false)

    if (isPay) {
      let someonePaysForUser = false;
      orderUserIds.map(user => {
        if (payingForOthersState.find(userPaying => userPaying.userIds.includes(user.userId))) {
          someonePaysForUser = true;
          return;
        }
      })

      if (!someonePaysForUser && showFeedbackMessages.someoneStartedPaymentProcess) {
        setShowFeedbackMessages({
          ...showFeedbackMessages,
          someoneStartedPaymentProcess: false
        })

        dispatch(setIsPay(false))
      }

      if (checkIfAllUsersAreSelected === undefined && !otherTryingToPay && hasThisUserPaidForHimself) {
        setShowFeedbackMessages({
          ...showFeedbackMessages,
          someoneStartedPaymentProcess: true
        })
      }
    }

    if (checkIfAllUsersAreSelected !== undefined || !isPay) {
      setShowFeedbackMessages({
        ...showFeedbackMessages,
        someoneStartedPaymentProcess: false
      })

      if (showFeedbackMessages.someoneStartedPaymentProcess) {
        handleSelectingCancelationConfirmation()
        dispatch(setIsPay(false))
      }
    }
  }, [payingForOthersState, isPay, showFeedbackMessages.someoneStartedPaymentProcess])

  useEffect(() => {
    if (isForWhomToPayConfirmed && !isPay) {
      handleSelectingCancelationConfirmation()
      dispatch(setIsForWhomToPayConfirmed(false))
    }
  }, [isForWhomToPayConfirmed, isPay])

  const handleProceededToCheckout = () => {
    dispatch(setIsPay(true))
    if (isMoreThanOneGuestOnTable) {
      dispatch(setIsProceededToCheckoutSelection(true))
      autoScrollTo(paymentGuestSelection)
    }
    else {
      payForUsersLogic()
      setTimeout(() => {
        autoScrollTo(spreedlyCheckoutVaultRef)
      }, 500)
      dispatch(setIsForWhomToPayConfirmed(true))
    }
  }

  const handleOnPaymentCheckboxChange = (user) => {
    if (payForUsers.length === 0) {
      payForUsers.push(+id)
    }
    let idx = selectedUsersForPayment.findIndex(selected => selected.userId === user.userId)

    if (idx !== -1) {
      setSelectedUsersForPayment(prevState => [...prevState.slice(0, idx), ...prevState.slice(idx + 1)])
    }
    else {
      setSelectedUsersForPayment(prevState => [...prevState, user])
    }

    onPaymentCheckboxChange({ userId: user.userId, isProceed: false })
    setIsOnlySelectedToPayForYourself(false)
    autoScrollTo(selectWhoToPayForRef)
  }

  function payForUsersLogic() {
    let someonePaysForUser = false;
    orderUserIds.map(user => {
      if (payingForOthersState.find(userPaying => userPaying.userIds.includes(user.userId))) {
        someonePaysForUser = true;
        return;
      }
      payForUsers.push(user.userId)
    })

    if (!payForUsers.includes(+id)) {
      payForUsers.push(+id)
    }

    if (hasThisUserPaidForHimself && payForUsers.length === 1 && payForUsers.includes(+id) && someonePaysForUser) {
      setShowFeedbackMessages({
        ...showFeedbackMessages,
        someoneStartedPaymentProcess: true
      })
    }

    if (payForUsers.length === 1 && payForUsers.includes(+id)) {
      setIsOnlySelectedToPayForYourself(true)
    }
    //ako drug plaka stavi uslov
    dispatch(getCheckoutInfo(currentReservation.reservation_id, payForUsers))
    setSelectedUsersForPayment(orderUserIds)
    onPaymentCheckboxChange({ userId: -1, isProceed: true })
  }

  const handleSelectAllOnTable = () => {
    payForUsersLogic()
    dispatch(setIsProceededToCheckoutSelection(false))
    autoScrollTo(spreedlyCheckoutVaultRef)
    onRefreshVoidedOrders(+tip, noTipReason)
    setIsPayForYourselfSelected(true)
    setSelectedUsersForPayment(orderUserIds)
    onPaymentCheckboxChange({ userId: id, isProceed: true })
    dispatch(setIsForWhomToPayConfirmed(true))
  }

  const handleForWhomToPay = () => {
    onPaymentCheckboxChange({ userId: -1, isProceed: true })
    dispatch(setIsForWhomToPayConfirmed(true))
    setOnSelectingUsersToPayFor(false)
    isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false))
    autoScrollTo(spreedlyCheckoutVaultRef)
  }
  const handleSelectingCancelationConfirmation = () => {
    //setters
    setIsPayForYourselfSelected(false)
    setIsOnlySelectedToPayForYourself(false)
    setOnSelectingUsersToPayFor(true)
    setPayByGuest(true)
    setSelectedUsersForPayment([])
    //dispatchers
    !isPay && dispatch(setIsPay(true))
    isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false))
    dispatch(setIsForWhomToPayConfirmed(false))
    dispatch(setPayingForOthers([]))
    //props functions
    onPaymentCheckboxChange({ userId: id, isProceed: true, isEdit: true })

    autoScrollTo(selectWhoToPayForRef)
  }
  const handleSelectingYourself = () => {
    setIsPayForYourselfSelected(true)
    setIsOnlySelectedToPayForYourself(true)

    if (!payForUsers.includes(+id)) {
      payForUsers.push(+id)
    }

    autoScrollTo(selectWhoToPayForRef)
  }

  return (
    <div className={`spreedly-credit-card-form-wrapper mb-3`} >
      {!isEmpty(ordersRounds) ? (
        <>
          {
            currentReservation.order_status === orderStatusEnum.orderStarted && orderLessThanZeroErrorMsg &&
            <span className="order-amount-error-message">
              For the initial round the order amount must be more than $0!
            </span>
          }

          {otherTryingToPay && !isEmpty(userPayingForMe) && !hasThisUserPaidForHimself &&
            <>
              <h3>Someone is in the payment process,</h3>
              <h3>Please stand by!</h3>
            </>
          }

          {showFeedbackMessages.someoneStartedPaymentProcess && <h3>Someone has already started the payment process!</h3>}
          {/* <div style={{display:'flex', width:'100%'}}></div> */}
          <div className="pay-or-kitchen-buttons">

            {!isPay && !isProceededToCheckoutSelection && !otherTryingToPay && !showFeedbackMessages.someoneStartedPaymentProcess &&
              <div className='checkout-button'>
                <button onClick={() => history.push(order_page_path.replace(':reservationId', currentReservation?.reservation_id))} style={{ marginBottom: '15px' }} className="btn btn-primary">
                  {/* {isPaymentRequired ? 'Menu' : "Order More"} */}
                  Add items from Menu
                </button>
              </div>
            }
            {!showOrderMoreCondition && !isEmpty(ordersRounds) && !hasNewOrder && !isPay ? (
              (
                <div className={'checkout-button'}>
                  {showReceiptButton && (
                    <button className="btn btn-primary" disabled={!receiptURL} style={{ marginBottom: '15px' }}>
                      {receiptURL
                        ? <a href={receiptURL} download={`Voila-${restaurant.name}-${reservationDate}.pdf`}>Receipt</a>
                        : <span>Loading</span>
                      }
                    </button>
                  )}
                </div>
              )
            ) : null}
          </div>



          {(!isPay && !otherTryingToPay && !isProceededToCheckoutSelection) && (
            <div className={!isSendToKitchenMode ? 'pay-or-kitchen-buttons' : ''} >
              {
                !(!hasNewOrder || currentReservation.order_status === orderStatusEnum.orderStarted && orderLessThanZeroErrorMsg) && (
                  <div className="pay-button">
                    <button
                      // disabled={!hasNewOrder || currentReservation.order_status === orderStatusEnum.orderStarted && orderLessThanZeroErrorMsg}
                      onClick={onSendToKitchen} className="btn btn-primary" >
                      Send your Order
                    </button>
                  </div>
                )
              }
              {!isSendToKitchenMode && !(checkoutInfoOnTable.total === '0.00' || hasNewOrder || otherTryingToPay) && (
                <div className="pay-button">
                  <button onClick={handleProceededToCheckout} className="btn btn-primary"
                  // disabled={checkoutInfoOnTable.total === '0.00' || hasNewOrder || otherTryingToPay}
                  >
                    Checkout
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className='checkout-button'>
          <button onClick={() => history.push(order_page_path.replace(':reservationId', currentReservation?.reservation_id))} style={{ marginBottom: '15px' }} className="btn btn-primary">
            {/* {isPaymentRequired ? 'Menu' : "Order More"} */}
            Add items from Menu
          </button>
        </div>
      )}

      {isPay && !otherTryingToPay && !showFeedbackMessages.someoneStartedPaymentProcess && (
        <>
          {isMoreThanOneGuestOnTable && (
            <div>

              {isProceededToCheckoutSelection &&
                <div ref={paymentGuestSelection} className="payment-selection-wrapper">
                  <div className="checkout-button">
                    <button className="btn btn-primary" onClick={handleSelectAllOnTable}>
                      Pay for all
                    </button>
                  </div>

                  <div className="checkout-button">
                    <button className="btn btn-primary" onClick={handleSelectingCancelationConfirmation}>
                      Pay by guest
                    </button>
                  </div>
                </div>}

              {onSelectingUsersToPayFor && !isSpreedlyCheckoutVaultVisible && !isProceededToCheckoutSelection &&
                <>
                  <h5>Select who do you want to pay for?</h5>
                  <div className="selectable-tiles selectable-tiles-block">
                    {!hasThisUserPaidForHimself && (
                      <button disabled={isSpreedlyCheckoutVaultVisible}
                        className={`btn selectable-tiles-button ${isPayForYourselfSelected ? 'active' : ''}`}
                        onClick={handleSelectingYourself} >
                        Myself
                      </button>
                    )}
                    {userIdsToSelect}
                  </div>
                  <div ref={selectWhoToPayForRef} style={{ marginTop: '15px' }} className="pay-button">
                    <button disabled={(isOnlySelectedToPayForYourself && subTotalInt === 0 && payForUsers.length >= 1) || !isPayForYourselfSelected || subTotalInt === 0}
                      onClick={handleForWhomToPay} className="btn btn-primary" >
                      Proceed
                    </button>
                  </div>
                </>
              }
              {isSpreedlyCheckoutVaultVisible && (
                <>
                  {!isPayForYourselfSelected ?
                    <>
                      {/* <div className={classNames("pay-by-guest", !payByGuest && "outline-wrapper")}>
                      <button
                        disabled={isPayForYourselfSelected}
                        onClick={handleSelectingCancelationConfirmation} className={"btn btn-primary"}>
                        Cancel Payment
                      </button>
                    </div> */}
                    </>
                    :
                    <>
                      <div className="pay-all-message">
                        {remainingBalance > 0 && (
                          <>
                            <h3>Remaining balance: ${remainingBalance}</h3>
                            <SeparationLine style={{ marginBottom: "15px", width: "100%" }} />
                          </>
                        )}

                        {!isOnlySelectedToPayForYourself && (
                          selectedUsersForPayment.length === orderUserIds.length ?
                            <>
                              <span>You are paying for all items on the table</span>
                              <span>OR</span>
                            </> :
                            selectedUsersForPayment.length !== 0 &&
                            <>
                              <span>You are also paying for</span>
                              <div className="selectable-tiles selectable-tiles-block">
                                {selectedUsersForPayment.map((user, idx) => (
                                  <SelectableTilesButton
                                    active={true}
                                    showAsParagraph={true}
                                  >
                                    <span key={user.userId || idx}>{user.userName}</span>
                                  </SelectableTilesButton>
                                ))}
                              </div>
                            </>
                        )
                        }
                      </div>
                      <div className={classNames("pay-by-guest", !payByGuest && "outline-wrapper")}>
                        <button
                          disabled={!isPayForYourselfSelected}
                          onClick={handleSelectingCancelationConfirmation} className={"btn btn-primary"}>
                          Pay by guest
                        </button>
                      </div>
                    </>

                  }
                </>
              )}
            </div>
          )}

          {(isSpreedlyCheckoutVaultVisible) && (
            <div ref={spreedlyCheckoutVaultRef}>
              <SpreedlyCheckoutVault onCompleteOrderClick={onCompleteOrderClick} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SendToKitchen
