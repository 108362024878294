import React, { useEffect } from 'react'
import './ToastMessage.css'
import { setToastMessage } from '../../../store/actions/feedbackActions'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import UncreatedExternalItems from './UncreatedExternalItems'
import CloseIcon from '../../../assets/close.svg'

function ToastMessage({ severity, message, autoHide = true, timer, unrecreatedExternalItems, showCloseIcon }) {
  const dispatch = useDispatch()

  const handleResetToastMessage = () => {
    dispatch(setToastMessage({show: false, severity: "", timer: 0, autoHide: true, showCloseIcon: false}))
  }

  useEffect(() => {
    autoHide &&
      setTimeout(() => {
       handleResetToastMessage()
      }, timer ?? 5000)
  }, [dispatch, autoHide, timer])

  return (
    <div
      className={classnames('toast-message-wrapper', {
        'toast-error': severity === 'error',
        'toast-info': severity === 'info',
        'toast-auto-width': unrecreatedExternalItems
        // 'toast-warning': severity === 'warning',
        // 'toast-success': severity === 'success'
      })}
    >

      {showCloseIcon && (
        <div 
          className='toast-close'
          onClick={handleResetToastMessage}
        >
          <img 
          src={CloseIcon}
          alt='close'
          />
        </div>
      )}

      {unrecreatedExternalItems &&
      <div 
      className='toast-close'
      onClick={handleResetToastMessage}
      >
        <img 
        src={CloseIcon}
        alt='close'
        />
      </div>
      }
      {unrecreatedExternalItems ?
        <UncreatedExternalItems unrecreatedExternalItems={unrecreatedExternalItems} /> :
        message}
    </div>
  )
}

export default ToastMessage
