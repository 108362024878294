import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveModal } from '../../store/actions/feedbackActions'
import { accept_invitation_modal, login_modal } from '../../config/modal_paths'
import {
  getInvitationDetails,
  setInvitationToken
} from '../../pages/book/services/actions'
import isEmpty from 'lodash.isempty'
import { getUser } from '../../store/actions/usersActions'
import { reservations_path } from '../../config/pages_paths'
import './AcceptInvitation.css'

function AcceptInvitation() {
  const { token } = useParams()
  const {
    auth: { isAuthenticated },
    booking: { invitationToken, invitationDetails },
    user: { user }
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (token) {
      dispatch(setInvitationToken(token))
    }
  }, [token, dispatch])

  useEffect(() => {
    if (!isEmpty(invitationToken)) {
      if (isAuthenticated) {
        dispatch(getInvitationDetails(invitationToken))
        dispatch(getUser())
      } else {
        dispatch(setActiveModal(login_modal))
      }
    }
  }, [invitationToken, dispatch, isAuthenticated])

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(invitationDetails)) {
      if (user.user_id === invitationDetails.reservation.user_id) {
        history.push(reservations_path)
      } else dispatch(setActiveModal(accept_invitation_modal))
    }
  }, [user, invitationDetails, dispatch, history])

  return null
}

export default AcceptInvitation
