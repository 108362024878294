import React from 'react'
import Modal from '../../modal/Modal'
import ModalHeading from '../../common/ModalHeading'
import CustomButton from '../../common/CustomButton'
import OutlineButton from '../../common/OutlineButton'

function SendToKitchenModal({ show, close, onSend }) {
  return (
    <Modal
      className="sent-to-kitchen-modal send-to-kitchen-modal"
      show={show}
      close={close}
    >
      <ModalHeading title={'Are you sure?'} onClick={close} />
      <p className='message-margin-bottom'>
        Not everyone from your table has finished their order yet. The timing of
        your food can be different if you proceed.
      </p>
      <div className={'send-to-kitchen-modal-buttons'}>
        <CustomButton text={'Back to order'} onClick={close} />
        <OutlineButton text={'Send'} onClick={onSend} />
      </div>
    </Modal>
  )
}

export default SendToKitchenModal
