import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoginButton from '../../../components/auth/login/LoginButton'
import InputField from '../../../components/common/InputField'
import ModalHeading2 from '../../../components/common/ModalHeading2'
import Modal from '../../../components/modal/Modal'
import {
  booked_reservation_modal,
  login_modal,
  register_success_modal
} from '../../../config/modal_paths'
import { isEqual } from 'lodash'
import Column from '../../../containers/layout/Column'
import Row from '../../../containers/layout/Row'
import useForm from '../../../hooks/useForm'
import { setActiveModal } from '../../../store/actions/feedbackActions'
import { setNewUser } from '../../../store/actions/usersActions'
import validateRegister from '../../../validation/register'
import {
  loginWithFacebook,
  loginWithGoogle,
  register
} from '../services/actions'
import './RegisterPage.css'

function RegisterPage({ show, close }) {
  const dispatch = useDispatch()
  const {
    user: { newUser },
    feedback: { responseMessage, activeModalMetadata, },
    auth: { isAuthenticated, user },
    booking: { invitationToken, hasBookedReservation },
    errors
  } = useSelector(state => state)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if (activeModalMetadata?.email || activeModalMetadata?.first_name) {
      setValues(activeModalMetadata)
    }
  }, [])

  const onSubmit = async () => {
    const { errors, isValid } = validateRegister(values)
    if (!isValid) {
      setFormErrors(errors)
      return null
    } else setFormErrors({})

    if (isEqual(values, { ...newUser, onSignUp: true })) {
      close()
      dispatch(setActiveModal(register_success_modal, { ...values, onSignUp: true }))
    }
    else {
      handleRegister()
    }
  }

  const handleRegister = async () => {
    const successRegister = await dispatch(register({ ...values, reservation: invitationToken }))
    if (successRegister) {
      dispatch(setNewUser(values))
      dispatch(setActiveModal(register_success_modal, { ...values, onSignUp: true }))
    }

  }

  const { values, setValues, handleChange, handleSubmit } = useForm(
    {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      new_password: '',
      confirm_password: ''
    },
    onSubmit
  )

  // useEffect(() => {
  //   if (responseMessage) {
  //     dispatch(setActiveModal(register_success_modal))
  //   }
  // }, [responseMessage, dispatch])

  const onSignInClickHandler = () => {
    dispatch(setActiveModal(login_modal, { ...activeModalMetadata }))
  }
  const handleClose = () => {
    close()
    if(!isAuthenticated && hasBookedReservation) {
      dispatch(setActiveModal(booked_reservation_modal))
    }
  }

  return (
    <Modal className="login-modal" show={show} close={handleClose}>
      {/* <ModalHeading onClick={handleClose} title="Sign-up" /> */}
      <ModalHeading2 className="login-header" title1="Log-in" title2="Sign-up" activeTitle={'Sign-up'} onLogin={onSignInClickHandler} onClick={handleClose} />


      <Row>
        <Column className="text-center align-items-center m-4" md={12} lg={12}>
          <div className="register-heading">
            <h3>Sign-up to experience the new dining</h3>
          </div>
        </Column>
        <Column md={12} lg={12}>
          <form onSubmit={handleSubmit}>
            <InputField
              type="email"
              required
              name="email"
              onChange={handleChange}
              label="E-mail address"
              value={values.email}
              error={formErrors.email || errors.email}
            />
            <InputField
              name="new_password"
              type="password"
              onChange={handleChange}
              label="Password"
              value={values.new_password}
              error={formErrors.new_password || errors.new_password}
            />
            <InputField
              style={{ marginBottom: "1.46rem" }}
              name="confirm_password"
              type="password"
              onChange={handleChange}
              label="Confirm Password"
              value={values.confirm_password}
              error={formErrors.confirm_password || errors.confirm_password}
            />
            {/* <div className="external-login-btns mx-auto">
              <span className="login-with" style={{ marginRight: '16px' }}>
                Log-in with
              </span>{' '}
              <FacebookLogin
                appId="847432139083677"
                autoLoad={false}
                callback={responseFacebook}
                disableMobileRedirect={true}
                fields="name,email,picture"
                scope="public_profile,email"
                isMobile={false}
                render={renderProps => (
                  <ExternalLoginButton
                    className="facebook"
                    onClick={renderProps.onClick}
                    img={FacebookImg}
                  />
                )}
              />
              <GoogleLogin
                clientId="489942013394-6ne459119dmtgl9f6rauporr7m6ch9b3.apps.googleusercontent.com"
                render={renderProps => (
                  <ExternalLoginButton
                    onClick={renderProps.onClick}
                    img={GoogleImg}
                  />
                )}
                onSuccess={responseGoogle}
                onFailure={onGoogleLoginFailure}
              />
              <AppleLogin />
            </div> */}

            <LoginButton onClick={onSubmit} />
          </form>
        </Column>
        <div id="footer" className="login-footer">
          <div className="already-have-account">
            <span>
              Already have an account?{' '}
              <span className=" create-account" onClick={onSignInClickHandler}>
                Log-in
              </span>
            </span>
          </div>
        </div>
      </Row>
    </Modal>
  )
}

export default RegisterPage
