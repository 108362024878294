import isEmpty from 'lodash.isempty';
import {
    SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED,
    SET_MENU_ITEM_RECOMMENDATIONS,
    SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL,
    SET_RESTAURANT_MENU_APPROVED
} from '../../../config/actionTypes';

// NOTE:
// REMOVE APPROVED ONCE THE REFACTORING IS COMPLETED !
// :P

const initialState = {
    // array containing the full menu
    restaurantMenuApproved: [],
    // object for the name and id of the menu and section
    activeMenuAndSectionInfoApproved: {
        activeMenuId: null,
        activeMenuName: null,
        activeMenuSectionId: null,
        activeMenuSectionName: null,
    },
    // array containing all the items for the active menu and section
    activeMenuSectionItemsApproved: [],
    // array containing all the items for the active menu and section with the information from the last order round
    activeMenuSectionItemsWithRoundForUserApproved: [],
    // array containing recommendations for an individual item that is set once a certain item is shown
    menuItemRecommendations: [],
    // boolean state for handing logic between modals with recommendations
    onMenuItemRecommendationsModal: null,

}

const menu = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_RESTAURANT_MENU_APPROVED: {
                // Check if restaurantMenuApproved is an empty array
                if (payload && Array.isArray(payload)) {
                    const isRestaurantMenuEmpty = state.restaurantMenuApproved.length === 0;

                    // Filter the menus and don't return the sections with no items
                    // and the menus with no sections
                    const filteredMenu = payload.filter(menu => {
                        return !isEmpty(menu.menu_sections) &&
                        menu.menu_sections.some(section => !isEmpty(section.menu_items));
                    });

                    const [firstMenu] = filteredMenu;
                    const [firstMenuSection] = firstMenu.menu_sections;
                    const { menu_items: itemsFromMenuSection } = firstMenuSection;

                    return {
                        ...state,
                        // Always update restaurantMenuApproved with the new payload
                        restaurantMenuApproved: filteredMenu,
                        // Update activeMenuAndSectionInfoApproved and activeMenuSectionItemsApproved on the first incoming payload
                        ...(isRestaurantMenuEmpty
                            ? {
                                activeMenuAndSectionInfoApproved: {
                                    activeMenuId: firstMenu?.menu_id,
                                    activeMenuName: firstMenu?.menu_name,
                                    activeMenuSectionId: firstMenuSection?.menu_section_id,
                                    activeMenuSectionName: firstMenuSection?.menu_section_name,
                                },
                                activeMenuSectionItemsApproved: itemsFromMenuSection,
                            }
                            : {}),
                    };
                } else {
                    return state;
                }
            }

        case SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED: {
            // Note: This Object will always have values as long the menu payload is correctly recieved with proper db props
            // If there is a menu payload we update all values where the first section is selected from the new menu,
            // while when a section payload arrives we only update the section values
            // and update the menu items in both scenarios accordingly to the condition

            let newMenuSectionId = state.activeMenuAndSectionInfoApproved.activeMenuSectionId;
            let newMenuSectionName = state.activeMenuAndSectionInfoApproved.activeMenuSectionName;
            let newMenuSectionItems = state.activeMenuSectionItemsApproved

            if (payload.menuId) {
                const activeMenu = state.restaurantMenuApproved.find(menu => menu.menu_id === payload.menuId);

                if (activeMenu && activeMenu.menu_sections && activeMenu.menu_sections.length > 0) {
                    // Get the first section from the menu_sections array
                    const [firstSection] = activeMenu.menu_sections;
                    newMenuSectionId = firstSection.menu_section_id;
                    newMenuSectionName = firstSection.menu_section_name;
                    newMenuSectionItems = firstSection.menu_items;
                }
            }

            if (payload.menuSectionId) {
                const activeMenu = state.restaurantMenuApproved.find(menu => menu.menu_id === state.activeMenuAndSectionInfoApproved.activeMenuId);
                if (activeMenu && activeMenu.menu_sections && activeMenu.menu_sections.length > 0) {
                    const activeSection = activeMenu.menu_sections.find(section => section.menu_section_id === payload.menuSectionId);
                    if (activeSection) {
                        newMenuSectionId = activeSection.menu_section_id;
                        newMenuSectionName = activeSection.menu_section_name;
                        newMenuSectionItems = activeSection.menu_items;
                    }
                }
            }

            const updatedActiveMenuAndSectionInfo = {
                ...state.activeMenuAndSectionInfoApproved,
                activeMenuId:
                    payload.menuId ??
                    state.activeMenuAndSectionInfoApproved.activeMenuId,
                activeMenuName:
                    payload.menuName ??
                    state.activeMenuAndSectionInfoApproved.activeMenuName,
                activeMenuSectionId:
                    payload.menuSectionId ??
                    newMenuSectionId,
                activeMenuSectionName:
                    payload.menuSectionName ??
                    newMenuSectionName,
            };

            const updatedActiveMenuSectionItems = newMenuSectionItems ?? []

            // If an ongoing reservation is present and the last round orders are  recieved in the payload,
            // we implement this logic to merge the menu items with their order information
            if (payload.lastRoundOrders) {
                const menuSectionItemsWithRoundForUser = updatedActiveMenuSectionItems?.map(item => {
                    const matchingItem = payload?.lastRoundOrders.find(orderItem => orderItem.menu_item_id === item.menu_item_id);

                    if (matchingItem) {
                        // Create a new object for the item with it's properties and add the following ones:
                        return {
                            ...item,
                            order_id: matchingItem.order_id,
                            order_item_id: matchingItem.order_item_id,
                            quantity: matchingItem.quantity,
                            remaining_quantity: matchingItem.remaining_quantity,
                            selected_quantity: matchingItem.selected_quantity,
                        };
                    }
                    // If there's no match, return the original item
                    else {
                        return item;
                    }
                });

                return {
                    ...state,
                    activeMenuAndSectionInfoApproved: updatedActiveMenuAndSectionInfo,
                    activeMenuSectionItemsApproved: menuSectionItemsWithRoundForUser,
                };
            }

            // If lastRoundOrders is empty, and you want to clear the quantities of items in the active menu
            const menuSectionItemsWithClearedQuantities = updatedActiveMenuSectionItems?.map(item => ({
                ...item,
                quantity: 0,
                remaining_quantity: item.quantity, // Reset remaining quantity to original quantity
                selected_quantity: 0,
            }));

            return {
                ...state,
                activeMenuAndSectionInfoApproved: updatedActiveMenuAndSectionInfo,
                activeMenuSectionItemsApproved: menuSectionItemsWithClearedQuantities,
            };
        }

        case SET_MENU_ITEM_RECOMMENDATIONS: {
            if (state.restaurantMenuApproved.length === 0 || !payload || payload.length === 0 || !payload?.menuItemRecommendations) {
                // No menu or empty payload, return the current state
                return state;
            }

            // Find menu items based on external_ids in the payload
            const recommendedItems = payload?.menuItemRecommendations.map(externalId => {
                // Iterate through menus and sections to find the item
                for (const menu of state.restaurantMenuApproved) {
                    if (menu.menu_sections && menu.menu_sections.length > 0) {
                        for (const section of menu.menu_sections) {
                            if (section.menu_items && section.menu_items.length > 0) {
                                const matchingItem = section.menu_items.find(item => item.external_id === externalId);
                                if (matchingItem) {
                                    // Find if recommended items have been added in the card and update them with the order values
                                    if (payload?.lastRoundOrders) {
                                        const matchingItemWithRound = payload.lastRoundOrders.find(item => item.menu_item_id === matchingItem.menu_item_id)
                                        return {
                                            ...matchingItem,
                                            is_recommended: true,
                                            order_id: matchingItemWithRound?.order_id,
                                            order_item_id: matchingItemWithRound?.order_item_id,
                                            quantity: matchingItemWithRound?.quantity,
                                            remaining_quantity: matchingItemWithRound?.remaining_quantity,
                                            selected_quantity: matchingItemWithRound?.selected_quantity,
                                        };
                                    }
                                    return {
                                        ...matchingItem,
                                        is_recommended: true
                                    };
                                }
                            }
                        }
                    }
                }
                return null; // Return null for external_ids not found in the menu
            });

            return {
                ...state,
                menuItemRecommendations: recommendedItems
            };
        }
        case SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL:
            return {
                ...state,
                onMenuItemRecommendationsModal: payload
            }

        default:
            return state
    }
}

export default menu
