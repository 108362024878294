import React from 'react'
import { useDispatch } from 'react-redux'
import { setMenuItemQuantityChangedModal } from '../../store/actions/feedbackActions'
import CustomButton from '../common/CustomButton'
import Modal from '../modal/Modal'
import './MenuItemQuantityChangedModal.css'

function MenuItemQuantityChangedModal({ show, message, extraButton }) {
    const dispatch = useDispatch()

    const close = () => {
      dispatch(setMenuItemQuantityChangedModal(false, "", { name: "", functionality: null }))
    }

    return (
    <Modal className="menu-item-quantity-changed-modal" show={show} close={close}>
      <div>
        <h3>{message.heading}</h3>
        <p style={{display: "grid"}}>{message.message}</p>
        <div style={{ display: "flex" }}>
          <CustomButton
            onClick={close}
            text={'Okay'}
            wrapperClassName="menu-item-quantity-changed-button"
            textClassName="cancel-reservation-btn-text"
            style={{marginLeft: "1rem", marginRight: "1rem"}}
          />

          {extraButton && (
            <CustomButton
              onClick={() => {
                extraButton.functionality();
                close();
              }}
              text={extraButton.name}
              wrapperClassName="menu-item-quantity-changed-button"
              textClassName="cancel-reservation-btn-text"
              style={{marginLeft: "1rem", marginRight: "1rem"}}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default MenuItemQuantityChangedModal