import React from 'react';

const UncreatedExternalItems = ({ unrecreatedExternalItems }) => {
    return (
        <div className="uncreated-external-items">
            <span>
                There are unpaid additional orders:
            </span>
            {unrecreatedExternalItems?.map((item,idx) =>
                <span key={item.id ?? idx}>
                    {item.name}: ${item.price}
                </span>
            )}
            <span>
                Total: ${unrecreatedExternalItems?.reduce((acc, current) => acc + current.price, 0).toFixed(2)}
            </span>
            <span>
                Please contact the staff!
            </span>
        </div>
    );
};

export default UncreatedExternalItems;