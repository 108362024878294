import React, { Fragment, useEffect, useRef } from 'react'
import Backdrop from './Backdrop'
import './Modal.css'
import classnames from 'classnames'

function Modal({ close, show, className, ...props }) {
  const modalRef = useRef()

  useEffect(() => {
    const onKeyPressed = e => {
      e.code === 'Escape' && close()
    }
    document.addEventListener('keydown', onKeyPressed, false)
    return () => {
      document.removeEventListener('keydown', onKeyPressed, false)
    }
  }, [close])

  useEffect(() => {
    // Disable scroll when a modal is active
    document.body.classList.add('body-scroll')

    return () => {
        document.body.classList.remove('body-scroll')
      }
  }, [])

  // useEffect(() => {
  //   if (modalRef.current) {
  //     modalRef.current.scrollIntoView()
  //   }
  // }, [modalRef])

  return (
    <Fragment>
      <Backdrop show={show} onClick={close} />
      <div
        ref={modalRef}
        style={{
          opacity: show ? '1' : '0'
        }}
        className={classnames('custom-modal', {
          [className]: className
        })}
      >
        {props.children}
      </div>
    </Fragment>
  )
}

export default Modal
