import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import LoadingSpinner from '../../components/common/loader/LoadingSpinner'
import { home_path } from '../../config/pages_paths'
import { getRestaurant } from '../../store/actions/restaurantsActions'
import { getMenu } from './services/actions'

function RestaurantWrapper({ component: Component }) {
  const {
    restaurant: { restaurant, },
    booking: { reservationDetails: { reservation } }
  } = useSelector(state => state)
  const { restaurantId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  const fetchData = () => {
    if(restaurantId) {
      dispatch(getRestaurant(restaurantId, history))  
      dispatch(getMenu(restaurantId))
    }
    setLoading(false)
  }
  

  const redirectHome = () => {
    restaurant.availability === "coming soon" && 
    history.push(home_path)
  }

  useEffect(() => {
    fetchData()
  }, [])

  // useEffect(() => {
  //   redirectHome()
  // },[restaurant])

  return loading ? (
    <LoadingSpinner />
  ) : Component ? (
    <Component />
  ) : null
}

export default RestaurantWrapper
