import React, { useCallback, useEffect, useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import {applePayLogs} from "../../../../pages/order/services/actions";
import './ApplePay.css'
import axios from "axios";
import {validate_apple_pay_session_api} from "../../../../config/api_endpoints";


//TODO: Replace hardcoded data
const config = {
    merchantIdentifier: 'merchant.nyc.voila',
    merchantName: 'Voila Dining'
}

export function ApplePay({onLoadPaymentData}) {

    const dispatch = useDispatch()

    const [canMakePayments, setCanMakePayments] = useState(false)
    const [session, setSession] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const {
        orders: {
            checkoutInfo: {total}
        }
    } = useSelector(state => state)

    const onPaymentCompletePromise = useCallback(({token, cardType, last4Digits, zipCode}) => new Promise((resolve, reject) => {
        onLoadPaymentData({
            spreedlyInformation: {
                token,
                cardType,
                last4Digits,
                isFirstTime: false,
                isApplePay: true,
                zipCode
            }
        })
        resolve();
    }), [onLoadPaymentData])

    useEffect(() => {
        setTotalPrice(total.replace(/\D/, ''))
    }, [total])

    useEffect(() => {
        if (window.ApplePaySession) {
            dispatch(applePayLogs('IN APPLE PAY SESSION'))
            const merchantIdentifier = config.merchantIdentifier;
            // const promise = window.ApplePaySession.canMakePayments(merchantIdentifier);
            // dispatch(applePayLogs(`MERCHANTID ${merchantIdentifier} ${promise}`))

            const canMakePayments = window.ApplePaySession.canMakePayments(merchantIdentifier)
            if(canMakePayments) {
                setCanMakePayments(true)
                dispatch(applePayLogs('CAN MAKE PAYMENTS'))
            }
            // promise.then(function (canMakePayments) {
            //     dispatch(applePayLogs('CAN MAKE PAYMENTS RESPONSE '))
            //     // if (canMakePayments) {
            //     //     setCanMakePayments(true)
            //     // }
            //     // else
            //     //     dispatch(applePayLogs('CAN MAKE PAYMENTS FALSE'))
            // }).catch(err => dispatch(applePayLogs('APPLE PAY SESSION ERROR', err)));
        }
    }, [dispatch])



    const initSession = useCallback(() => {
        const request = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'jcb'],
            merchantCapabilities: ['supports3DS'],
            requiredBillingContactFields: ['postalAddress'],
            total: {label: config.merchantName, type: 'final', amount: totalPrice},
        }
        const session = new window.ApplePaySession(3, request);
        setSession(session)
    }, [totalPrice])

    useEffect(() => {
        if (session) {
            session.begin()
            // Validate the merchant once the payment sheet is displayed
            session.onvalidatemerchant = (event) => {
                const validationUrl = event.validationURL
                validateSession(validationUrl, (merchantSession) => {
                    session.completeMerchantValidation(merchantSession)
                })
            }

            // Send the payment information to backend and post that to Spreedly
            session.onpaymentauthorized = (event) => {
                onPaymentCompletePromise({
                    token: event.payment?.token?.paymentData,
                    cardType: event.payment?.token?.paymentMethod?.network,
                    last4Digits: event.payment?.token?.paymentMethod?.displayName ? event.token?.paymentMethod?.displayName?.split(' ')[1] : event.token?.paymentMethod?.displayName,
                    zipCode: event.payment?.billingContact?.postalCode
                }).then((res) => {
                    dispatch(applePayLogs('PAYMENT AUTHORIZED'))
                    session.completePayment(session.STATUS_SUCCESS)
                }).catch((err) => {
                    dispatch(applePayLogs('PAYMENT NOT AUTHORIZED' + err))
                    session.completePayment(session.STATUS_FAILURE)
                })
            }
        }
    }, [session, onPaymentCompletePromise, dispatch])

    const validateSession = (validationUrl, callbackFn) => {
        axios.post(validate_apple_pay_session_api, {appleUrl: validationUrl}).then((response) => {
            callbackFn(response.data)
        })
    }

    return canMakePayments ?
        <div className="apple-pay-button apple-pay-button-black" onClick={initSession} />
        : null
}