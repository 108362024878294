import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Image from '../../components/common/Image';
import MenuItemBadge from '../../components/orders/MenuItemBadge';
import Column from '../../containers/layout/Column';
import inventoryStatusEnum from '../../enums/inventoryStatusEnum';

const ActiveSectionItems = ({
  items = [],
  emptyMofifierGroup,
  onReservation = false,
  showSpecial = false,
  onRecommendations = false,
  handleMenuItemDetails,
  handleRecommendedItemDetails,
}) => {
  // Create a dictionary of menu_item_id to quantity from lastRoundOrders


  const handleOnClick = async (item) => {
    if (onReservation &&
      item.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK
    ) {
      !onRecommendations ?
        await handleMenuItemDetails(item.menu_item_id, item?.menu_item_recommended_external_ids) :
        await handleRecommendedItemDetails(item.menu_item_id)
    }
    else if (!onReservation) {
      await handleMenuItemDetails(item.menu_item_id)
    }
    return;

  };

  return (
    <>
      {items?.map((item, idx) => {
        return (
          <Fragment key={item.menu_item_id ?? idx}>
            <Column justifyContent="center" className="p-0 m-0 mx-auto" col={12}>
              <div
                onClick={() => handleOnClick(item)}
                className="grid-container-1-4"
                style={{
                  ...(item?.is_special && showSpecial && {
                    backgroundColor: 'hsla(0,0%,83%,.3)',
                    borderRadius: '1rem',
                  }),
                  maxWidth: '27rem',
                  justifyContent: 'center!important',
                  padding:'0.5rem 0.5rem 0.5rem 0'
                }}
              >
                <div className="user-order-item-image-wrapper_">
                  <div className="image-wrapper_">
                    {/* Check if the menu_item_id exists in orderQuantities */}
                    {onReservation && item?.quantity ? (
                      <MenuItemBadge number={item.quantity} />
                    ) : null}
                    <Image
                      src={item.image}
                      menu
                      alt="menu-item"
                      className={`user-order-item-image_`}
                      pill={"Unavailable"}
                    />
                    {(!emptyMofifierGroup
                      ? onReservation && item.inventory_status === inventoryStatusEnum.OUT_OF_STOCK
                      : onReservation &&
                      (item.inventory_status === inventoryStatusEnum.OUT_OF_STOCK ||
                        item.is_invalid)) ? (
                      <div className="reservation-box-label">Unavailable</div>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "grid", height: "100%" }}>
                  <div className="grid-container-3-1">
                    <div className="user-order-item-details_">
                      <h6>{item.menu_item_name}</h6>
                    </div>
                    <div className="user-order-item-actions-wrapper_">
                      <div>
                        <h6>${Number.parseFloat(item.price).toFixed(2)}</h6>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <small
                      className="user-order-item-description"
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: "13px",
                        color: "#877a74",
                        textAlign: "left",
                      }}
                    >
                      {item.about}
                    </small>
                  </div>
                </div>
              </div>
            </Column>
            <div className="text-center menu-item-separator" />
          </Fragment>
        );
      })}
    </>
  );
};

export default ActiveSectionItems;
