import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { registerRedirect } from '../../pages/auth/services/actions'
import { useDispatch, useSelector } from 'react-redux'
import { booking_page_path, home_path } from '../../config/pages_paths'
import { setRegisterRedirectResponse } from '../../store/actions/feedbackActions'
import localStorageHelper from '../../utils/localStorageHelper'
import { searchFreeTables } from '../../pages/book/services/actions'
import { BOOKING_DATA } from '../../constants/index'

function RegisterConfirmation() {
  const { confirmationToken } = useParams()
  const {
    feedback: { isAlreadyRegistered, userRegistered }
  } = useSelector(state => state)
  const history = useHistory()
  const dispatch = useDispatch()
  const bookingData = localStorageHelper.getItem(BOOKING_DATA);

  // useEffect(() => {

  //   if (confirmationToken && location.pathname === '/') {
  //     dispatch(registerRedirect('Bearer ' + confirmationToken));
  //   }

  //   if (bookingData && location.pathname === '/') {
  //     const bookId = bookingData.restaurantId; // Assuming restaurantId is used as bookId
  //     history.push(`/book/${bookId}`);
  //   }
  // }, [dispatch, confirmationToken, history]);

  // useEffect(() => {
  //   return () => {
  //     if (bookingData && location.pathname.startsWith('/book/')) {
  //       dispatch(searchFreeTables(bookingData));
  //       localStorageHelper.removeItem(BOOKING_DATA);
  //     }
  //   };
  // }, [dispatch]);

  // useEffect(() => {
  //   if (isAlreadyRegistered || userRegistered) history.push(home_path)
  // }, [isAlreadyRegistered, userRegistered, history])

  useEffect(() => {
    if (confirmationToken) {
      dispatch(registerRedirect('Bearer ' + confirmationToken));
    }
    const bookingData = localStorageHelper.getItem(BOOKING_DATA);


    if (!isAlreadyRegistered || !userRegistered) {

      if (bookingData) {
        history.push(`/book/${bookingData.restaurantId}`)
        dispatch(searchFreeTables(bookingData));
        localStorageHelper.removeItem(BOOKING_DATA);

      }
      else {
        history.push(home_path)
      }
    }
    else {
      history.push(home_path);
    }

  }, [dispatch, isAlreadyRegistered, userRegistered, history]);


  useEffect(() => {
    return () => {
      dispatch(setRegisterRedirectResponse({}))
    }
  }, [dispatch])

  return null
}

export default RegisterConfirmation
