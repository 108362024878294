import React, { Fragment, useCallback, useState } from 'react'
import RestaurantPriceRange from './RestaurantPriceRange'
import './RestaurantDescription.css'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

function Map({ latitude, longitude, restaurantName }) {
  return (
    <div className="my-4 mx-auto map-wrapper">
      <iframe
        width="450"
        height="300"
        frameBorder="0"
        className="iframe"
        title="map"
        style={{ border: '0', margin: 'auto' }}
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCa9-w0DctxnyWpau4umGpc0oyPEdrQvBI&q=${latitude},${longitude}&zoom=15`}
        allowFullScreen={true}
      />
    </div>
  )
}

function RestaurantDescriptionTabs({ onClick, activeTab }) {
  return (
    <div className="description-tabs-container">
      <div
        onClick={() => onClick(1)}
        className={classnames('description-tab', { active: activeTab === 1 })}
      >
        <span>About</span>
        <div />
      </div>
      <div
        onClick={() => onClick(2)}
        className={classnames('description-tab', { active: activeTab === 2 })}
      >
        <span>Location</span>
        <div />
      </div>
    </div>
  )
}

function RestaurantDescription({
  name,
  about,
  style,
  price_range,
  latitude,
  longitude,
  address,
  currentPosition,
  about_us_link,
  reviews_link
}) {
  const [activeTab, setActiveTab] = useState(1)

  const calcDistance = useCallback(() => {
    let R = 6371 // km
    let dLat = toRad(currentPosition.lat - latitude)
    let dLon = toRad(currentPosition.lon - longitude)
    let lat1 = toRad(latitude)
    let lat2 = toRad(currentPosition.lat)

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    let d = R * c
    //km to miles
    const distance = d * 0.621371

    return isNaN(distance) ? null : distance.toFixed(2)
  }, [latitude, longitude, currentPosition])

  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180
  }

  const displayDistance = useCallback(() => {
    const distance = calcDistance()
    return distance ? distance + 'mi' : address
  }, [address, calcDistance])

  return (
    <React.Fragment>
      <h2 className="text-center">{name}</h2>
      <RestaurantDescriptionTabs
        onClick={tab => setActiveTab(tab)}
        activeTab={activeTab}
      />
      <span className="text-center restaurant-style">
        {style} | <RestaurantPriceRange price_range={price_range} /> |
        {displayDistance()}
      </span>
      {activeTab === 1 ? (
        <div>
          <p className="text-center" style={{marginTop: '0.875rem'}}>{about}</p>
          <div className="restaurant-about-links">
            <Link target={'_blank'} to={{ pathname: reviews_link }}>
              Read Reviews
            </Link>
            <Link target={'_blank'} to={{ pathname: about_us_link }}>
              Website
            </Link>
          </div>
        </div>
      ) : (
        <Fragment>
          {<p className={'my-2'}>{address}</p>}
          <Map
            latitude={latitude}
            longitude={longitude}
            restaurantName={name}
          />
        </Fragment>
      )}
    </React.Fragment>
  )
}

export default RestaurantDescription
