import React, { useEffect, useState } from 'react'
import Modal from '../modal/Modal'
import { useDispatch, useSelector } from "react-redux";
import './OrdersComponents.css'
import Column from '../../containers/layout/Column'
import RoundButton from '../common/RoundButton'
import InputFieldGroup from '../common/InputFieldGroup'
import AddItemButton from './buttons/AddItemButton'
import EditItemButton from './buttons/EditItemButton'
import RemoveItemButton from './buttons/RemoveItemButton'
import './buttons/OrderButtons.css'
import FreeModifiers from './modifiers/FreeModifier'
import MenuItemDetails, { CloseButton } from '../restaurant/MenuItemDetails'
import { editOrderItem, getLastRoundOrders, removeOrderItem } from "../../pages/order/services/actions"
import SeparationLine from '../../components/common/SeparationLine'
import inventoryStatusEnum from '../../enums/inventoryStatusEnum.js'
import { AddIcon, RemoveIcon } from '../../assets/svgs-google/GoogleIcons';
import { setMenuItemRecommendations, setOnMenuItemRecommendationsModal } from '../../pages/menu/services/actions';
import isEmpty from 'lodash.isempty';

function AddMenuItemModal({
  show,
  close,
  item,
  activeMenuSectionId,
  onAddClickHandler,
  handleCloseRecommendedMenuItemModal,
  recommendedItemDetails,
  menuItemRecommendations,
}) {
  const [quantity, setQuantity] = useState(item?.quantity || 1)
  const [selectableQuantity, setSelectableQuantity] = useState(0)
  const [oldestQuantity, setOldestQuantity] = useState(null)
  const [description, setDescription] = useState(item?.description || '')
  const [modifier, setModifier] = useState('')
  const [modifierMeta, setModifierMeta] = useState({})
  const [areAllRequiredGroupsSelected, setAreAllRequiredGroupsSelected] = useState(true)

  const {
    booking: { currentReservation },
  } = useSelector(state => state)
  const dispatch = useDispatch();

  useEffect(() => {
    if (oldestQuantity === null) {
      setOldestQuantity(quantity)
    }
  }, [quantity])

  useEffect(() => {
    if (item?.selected_quantity) {
      //Why are we doing this check here
      const quantityAvailable = item.remaining_quantity - item?.selected_quantity

      const selectableQuantityCalculation = (item?.selected_quantity + quantityAvailable) >= item.remaining_quantity ?
        item.quantity === undefined ? (item.remaining_quantity - item?.selected_quantity) : oldestQuantity + (item.remaining_quantity - item?.selected_quantity) : item?.selected_quantity + quantityAvailable

      setSelectableQuantity(selectableQuantityCalculation)

      if (!item?.quantity && quantity > selectableQuantityCalculation && !item?.order_item_id) {
        setQuantity(selectableQuantityCalculation <= 0 ? 1 : selectableQuantityCalculation)
      }
    } else {
      setSelectableQuantity(item?.remaining_quantity)
    }
  }, [oldestQuantity, item?.remaining_quantity, item?.selected_quantity, item?.order_item_id])

  useEffect(() => {
    if (item?.quantity) {
      setQuantity(item?.quantity)
    } else if (!item?.quantity && !item?.order_item_id) {
      setQuantity(1)
    }
  }, [item?.quantity, item?.order_item_id])


  const handleClose = (recommendationsOrItemDetails) => {
    !isEmpty(recommendationsOrItemDetails) ?
    handleCloseRecommendedMenuItemModal() :
    close()
  }

  const onIncrease = () => {
    switch (item?.inventory_status) {
      case inventoryStatusEnum.IN_STOCK:
        setQuantity(val => val + 1)
        break
      case inventoryStatusEnum.QUANTITY:
        if (quantity < selectableQuantity) {
          setQuantity(val => val + 1)
        }
        break
    }
  }

  const onDecrease = () => {
    setQuantity(val => val - 1 > 0 ? val - 1 : 1)
  }

  const onDescriptionChange = e => {
    e.persist()
    setDescription(e.target.value)
  }


  const onAddOrderItemEventHandler = async () => {
    onAddClickHandler({
      menu_item_id: item.menu_item_id,
      menu_section_id: activeMenuSectionId,
      quantity,
      description,
      modifier,
      modifierMeta
    })
    //dodaj async
   await handleClose(menuItemRecommendations)
  }

  const onEditOrderItemEventHandler = async () => {
    const reservationId = (currentReservation && currentReservation.reservation_id) || item.reservation_id
    // if (quantity !== item?.quantity) {
      const editedItem = await dispatch(editOrderItem(item.order_item_id, reservationId, {
        quantity, description, modifierGroups: modifierMeta.modifier,
        menuItemId: item.menu_item_id
      }))
      if (editedItem) {
        await dispatch(getLastRoundOrders(reservationId))
      }
    // }
    await handleClose(recommendedItemDetails)
  }
  const onDeleteOrderItemEventHandler = async () => {
    const reservationId = (currentReservation && currentReservation.reservation_id) || item.reservation_id
    const removedItem = await dispatch(removeOrderItem(item.order_item_id, reservationId))
    if (removedItem) {
      await dispatch(getLastRoundOrders(reservationId))
    }
    await handleClose(recommendedItemDetails)
  }

  return (
    <div className={'add-menu-item-wrapper'}>
      <Modal className="add-menu-item-modal" show={show} close={close}>
        <CloseButton onClick={close} />
        <MenuItemDetails reduceDetailSize={false} {...item} close={close} />
        {item?.has_modifier ? null : (
          <FreeModifiers
            setModifier={item => setModifier(`${item.name}: ${item.modifier}`)}
            setModifierMeta={setModifierMeta}
            setAreAllRequiredGroupsSelected={setAreAllRequiredGroupsSelected}
          />
        )}
        <SeparationLine />
        <Column className="text-center" col={12}>
          <div className="add-menu-item-options-title mx-auto">
            <h3>Quantity</h3>
            {item?.inventory_status === inventoryStatusEnum.QUANTITY && selectableQuantity === 0 && !item?.order_item_id && item?.remaining_quantity > 0 && (
              <p style={{ color: "red" }}>
                {item?.users?.map((name, i, { length }) => <span>{length - 1 === i ? " " + name : " " + name + ","}</span>)} {item?.users?.length > 1 ? "have" : "has"} already selected maximum quantity for ordering.
              </p>
            )}

            {item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || (item?.inventory_status === inventoryStatusEnum.QUANTITY && item?.remaining_quantity === 0) ? (
              <p style={{ color: "red", fontSize: "12px" }}>The item is out of stock</p>
            ) : null}
          </div>
          <div className="add-item-btns text-center">
            <div onClick={onDecrease}>{RemoveIcon()}</div>
            {/* <i className="fas fa-solid fa-minus fa-icon" onClick={onDecrease} /> */}
            <div className="item-quantity">
              <span>{quantity}</span>
            </div>
            <div onClick={onIncrease}>{AddIcon()}</div>
            {/* <i className="fas fa-solid fa-plus fa-icon" onClick={onIncrease} /> */}
          </div>
        </Column>
        <SeparationLine />
        <Column col={12} className="p-0">
          <div className="special-requirements-title mx-auto">
            <h4>Special requirements</h4>
          </div>
          <InputFieldGroup
            onChange={onDescriptionChange}
            value={description}
            wrapperClassName="special-requirements-input-wrapper"
            className="special-requirements-input"
            placeholder="Any remarks?"
            name="description"
          />
        </Column>
        {!item?.order_item_id && (<AddItemButton onClick={onAddOrderItemEventHandler} disabled={item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || !areAllRequiredGroupsSelected || (item?.inventory_status === inventoryStatusEnum.QUANTITY && (item?.remaining_quantity === 0 || selectableQuantity === 0))} />)}
        <div className='edit-remove-wrapper'>
          {item?.order_item_id && (<EditItemButton onClick={onEditOrderItemEventHandler} disabled={!areAllRequiredGroupsSelected} />)}
          {item?.order_item_id && (<RemoveItemButton onClick={onDeleteOrderItemEventHandler} disabled={false} />)}
        </div>
      </Modal>
    </div>
  )
}

export default AddMenuItemModal
