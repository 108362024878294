import React from 'react'
import './DescriptiveRadioButton.css'

function DescriptiveRadioButton({
  label,
  name,
  id,
  onChange = () => {},
  show,
  defaultChecked,
  ...props
}) {
  return (
    <div className={'descriptive-radio-btn'}>
      <input
        defaultChecked={defaultChecked}
        onChange={onChange}
        type={'radio'}
        id={id}
        name={name}
      />
      <label htmlFor={id}>{label}</label>
      {show ? props.children : null}
    </div>
  )
}

export default DescriptiveRadioButton
