import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { expirationTimerDurationTime, expirationTimerVisibilityStartTime } from '../config/keys'
import { home_path } from '../config/pages_paths'
import { getTimerExpirationTime } from '../pages/reservations/services/actions'
import { setActiveModal, setExpirationTimerModal, setTimerExpiredMessageModal } from '../store/actions/feedbackActions'
import { orderStatusEnum } from '../enums/orderStatusEnum'

const useExpirationTimer = () => {
  const dispatch = useDispatch()
  const {
    booking: { currentReservation },
    orders: { ordersRounds },
  } = useSelector(state => state)
  const history = useHistory()

  const [timeLeft, setTimeLeft] = useState({ time: null, seconds: null })
  const [showTimer, setShowTimer] = useState({ show: false, count: 0 })
  const [countRequest, setCountRequest] = useState({
    getTimerExpirationTime: 0,
    showTimerExpiredMessageModal: 0
  })
  const expirationTime = currentReservation?.expiration_time

  // const showExpirationTimerModalWithSelectedItemsData = {
  //   show: true, text: "Are you still here?", paragraph: "This session will expire. You have not ordered anything yet. Please select your items and complete your order."
  // }
  const showExpirationTimerModalWithoutSelectedItemsData = {
    show: true, text: "Are you still here?", paragraph: " Your order is not completed, please complete your order for the selected items. Otherwise, this session will expire and you will have to select your items again."
  }
  const hideExpirationTimerModalData = { show: false, text: "", paragraph: "" }

  const refreshExpirationToken = useCallback((reservationId) => {

    // setCountRequest({ ...countRequest, getTimerExpirationTime: 0 })
    // if (countRequest.getTimerExpirationTime === 0) {
      //fix this
      // setCountRequest({ ...countRequest, getTimerExpirationTime: 1 })
      // }
      if (ordersRounds.length >= 1 && ordersRounds[0].round !== null) return;
      dispatch(getTimerExpirationTime(reservationId))
  }, [expirationTime])

  const orderExpired = useCallback(() => {
    history.push(home_path)
    const data = { show: true, text: "Your order session expired", paragraph: "Please order again and complete your order." }
    dispatch(setExpirationTimerModal(hideExpirationTimerModalData))
    dispatch(setTimerExpiredMessageModal(data))
  }, [expirationTime])

  // add x more minutes to the current time
  let expirationDate = new Date(expirationTime);
  expirationDate.setMinutes(expirationDate.getMinutes() + expirationTimerDurationTime);
  expirationDate = new Date(expirationDate);

  useEffect(() => {
    if (!expirationTime) return;
    if (ordersRounds.length >= 1 && ordersRounds[0].round !== null) return;

    const recalculateAndFormatTimeLeft = () => {
      // this function makes e.g. time: 6:1 to be time: 06:01
      function leftPad(aNumber, aLength) {
        if (aNumber.toString().length >= aLength) return aNumber;
        return (Math.pow(10, aLength) + Math.floor(aNumber)).toString().substring(1);
      }

      const msLeft = new Date(expirationDate) - new Date()

      const totalSeconds = Math.floor(msLeft / 1000)
      const minutes = leftPad(Math.floor(totalSeconds / 60), 2);
      const seconds = leftPad(totalSeconds - minutes * 60, 2);

      const totalTime = minutes + ":" + seconds

      setTimeLeft({ time: totalTime, seconds: totalSeconds })
    }

    recalculateAndFormatTimeLeft()
    const timerId = setInterval(recalculateAndFormatTimeLeft, 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [expirationTime])

  useEffect(() => {
    if (timeLeft.seconds !== null && Math.sign(timeLeft.seconds) === -1) return; // checks if number have negative value

    if (timeLeft.seconds !== null && timeLeft.seconds <= 0) {
      dispatch(setExpirationTimerModal(hideExpirationTimerModalData))
      setTimeLeft({ time: null, seconds: null })
      setShowTimer({ show: false, count: 0 })
    } else {
      if (timeLeft.seconds !== null && timeLeft.seconds <= expirationTimerVisibilityStartTime && currentReservation?.order_status === orderStatusEnum.orderStarted) {
        if (!showTimer.show && showTimer.count === 0) setShowTimer({ ...showTimer, show: true })

        if (showTimer.show && showTimer.count === 0) {
          setShowTimer({ ...showTimer, count: 1 })
          dispatch(setExpirationTimerModal(showExpirationTimerModalWithoutSelectedItemsData))
          dispatch(setActiveModal(""))
        }
      } else {
        if (showTimer.show) {
          setShowTimer({ show: false, count: 0 })
          dispatch(setExpirationTimerModal(hideExpirationTimerModalData))
        }
      }
    }
  }, [timeLeft, ordersRounds, expirationTimerVisibilityStartTime])

  useEffect(() => {
    if (countRequest.showTimerExpiredMessageModal === 0 && currentReservation?.order_status === orderStatusEnum.orderStarted) {
      if (timeLeft.seconds <= 0 && timeLeft.seconds !== null) {
        if (ordersRounds.length === 0 || (ordersRounds.length === 1 && ordersRounds[0].round === null)) {
          history.push(home_path)
          const data = { show: true, text: "Your order session expired", paragraph: "Please order again and complete your order." }
          dispatch(setExpirationTimerModal(hideExpirationTimerModalData))
          dispatch(setTimerExpiredMessageModal(data))
          setCountRequest({ ...countRequest, showTimerExpiredMessageModal: 1 })
        }
      }
    }
  }, [timeLeft.seconds])

  return {
    // activeModal,
    timeLeft,
    // refreshReservations,
    refreshExpirationToken,
    expirationTime,
    orderExpired
  }
}


export default useExpirationTimer