import React from 'react'

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

function RestaurantActions({ onBook, onOrder, showBookingFlow }) {
  return (
    <>
      <div className="actions-btns-mask" />
      <div className="restaurant-actions-container" style={containerStyle}>
        {/* TODO temporary hidden until booking reservations are fixed */}
        {
          showBookingFlow ?
            <button
              style={{ marginRight: '14px' }}
              className="btn btn-primary big-round-btn"
              onClick={onBook}
            >
              <span>Book</span>
            </button> :
            null
        }
        <button className="btn btn-primary big-round-btn" onClick={onOrder}>
          <span>Order</span>
        </button>
      </div >
    </>
  )
  //(
  // <Row className="m-0 p-0 mx-auto restaurant-actions-btns">
  //   <Column
  //     className="text-center m-0 p-0"
  //     xs={4}
  //     sm={3}
  //     md={12}
  //     lg={5}
  //     xl={4}
  //   >
  //     <button
  //       style={{ marginRight: '14px' }}
  //       className="btn btn-primary big-round-btn"
  //       onClick={onBook}
  //     >
  //       Book
  //     </button>
  //   </Column>
  //   <Column
  //     className="text-center m-0 p-0"
  //     xs={4}
  //     sm={3}
  //     md={12}
  //     lg={5}
  //     xl={4}
  //   >
  //     <button className="btn btn-primary big-round-btn" onClick={onOrder}>
  //       Order
  //     </button>
  //   </Column>
  // </Row>
  // )
}

export default RestaurantActions
