import Validator from 'validator'
import isEmpty from 'lodash.isempty'

function validateRegister({ email, phone, new_password, confirm_password }) {
  const errors = {}
  if (!Validator.isEmail(email)) errors.email = 'Invalid email address'

  if (isEmpty(new_password)) errors.new_password = 'Password cannot be empty'

  if (isEmpty(confirm_password) || (new_password !== confirm_password)) errors.confirm_password = "Passwords don't match"

  return { errors, isValid: isEmpty(errors) }
}

export default validateRegister
