import React from 'react'
import { useDispatch } from "react-redux";
import './SpreedlyCreditCardVault.css'
import SpreedlyLogic from '../../payment/spreedly/components/SpreedlyLogic'
import { createPaymentMethodForUser, updatePaymentMethodForUser } from "../../../pages/order/services/actions";

const SpreedlyCreditCardVault = () => {
  const dispatch = useDispatch()

  const onCompleteAction = (data) => {
    dispatch(createPaymentMethodForUser(data))
  }

  const {
    showCreditCardVault,
    isAddNewMethod,
    isNewCardFormValid,
    isPaymentVaultEmpty,
    onSaveCard,
    onEdit,
  } = SpreedlyLogic({ cb: onCompleteAction })

  return (
    <>
      {showCreditCardVault()}
      {isAddNewMethod && (
        <div className="submit-button">
          <div className={"submit-btn-primary"}>
            <button onClick={onSaveCard} className="btn btn-primary" disabled={!isNewCardFormValid()}>
              <span>Save card</span>
            </button>
          </div>
          {!isPaymentVaultEmpty() && (
            <div className={"submit-btn-secondary"}>
              <button className="btn btn-danger" onClick={onEdit}><span>Cancel</span></button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default SpreedlyCreditCardVault
