import React, { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import classnames from 'classnames'
import PeopleIcon from '../../assets/people.svg'

function SizePartyButtons({ activeBtn, onClick, sizeOfParty }) {
  const [restButtons, setRestButtons] = useState([])

  useEffect(() => {
    let arr = []
    for (let i = 1; i <= 15; i++) {
      arr.push(i)
    }
    setRestButtons(arr)
  }, [])

  return (
    <div className="booking-btns">
      <CustomButton
        onClick={() => onClick(3, 0)}
        wrapperClassName={classnames('booking-btn', {
          active: activeBtn === 0
        })}
        textClassName={classnames('booking-btn-text', {
          active: activeBtn === 0
        })}
        text="3"
      />
      <CustomButton
        onClick={() => onClick(4, 1)}
        wrapperClassName={classnames('booking-btn', {
          active: activeBtn === 1
        })}
        textClassName={classnames('booking-btn-text', {
          active: activeBtn === 1
        })}
        text="4"
      />
      <CustomButton
        onClick={() => onClick(5, 2)}
        wrapperClassName={classnames('booking-btn', {
          active: activeBtn === 2
        })}
        textClassName={classnames('booking-btn-text', {
          active: activeBtn === 2
        })}
        text="5"
      />
      <div className="dropdown">
        <CustomButton
          style={{ width: '90px' }}
          dataToggle="dropdown"
          wrapperClassName={classnames('booking-btn', {
            active: activeBtn === 3
          })}
          textClassName={classnames('booking-btn-text', {
            active: activeBtn === 3
          })}
          icon={PeopleIcon}
          iconClassName={classnames('booking-calendar', {
            active: activeBtn === 3
          })}
          text={otherBtnText(sizeOfParty)}
        />
        <ul
          style={{ zIndex: '1999', padding: '0',}}
          className="dropdown-menu user-order-item-button-menu"
        >
          {restButtons.map(btn => {
            return (
              <li
                style={{
                  background: +sizeOfParty === +btn ? '#01003b' : '',
                  color: +sizeOfParty === +btn ? '#ffffff' : '',
                  padding: '5px',
                  cursor: 'pointer',
                }}
                key={btn}
                onClick={() => onClick(btn, onOtherClick(btn))}
              >
                {' '}
                {btn}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default SizePartyButtons

function onOtherClick(value) {
  switch (value) {
    case 3:
      return 0
    case 4:
      return 1
    case 5:
      return 2
    default:
      return 3
  }
}

function otherBtnText(value) {
  switch (value) {
    case 3:
    case 4:
    case 5:
    case null:
      return 'Other'
    default:
      return value
  }
}
