import React, { useEffect, useState } from 'react'
import Row from '../../containers/layout/Row'
import Column from '../../containers/layout/Column'
import TablePlaceholder from '../../assets/table@3x.png'
import InputField from '../../components/common/InputField'
import {
  checkIsTableValid,
  setActiveModal, setIsTableValid,
  setPageRoutePath
} from '../../store/actions/feedbackActions'
import { login_modal } from '../../config/modal_paths'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import './ChooseTable.css'
import {
  order_first_time_path,
  order_page_path,
} from '../../config/pages_paths'
import { setNewOrderDetails } from './services/actions'

function ChooseTable() {
  const [tableNumber, setTableNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState('')
  const { restaurantId } = useParams()
  const {
    auth: { isAuthenticated },
    feedback: { responseMessage, isTableValid },
    booking: { reservationId }
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()

  const onTableNumberChange = e => {
    +e.target.value < 0
      ? setErrorMessage('Cannot be less than 1')
      : (() => {
          setTableNumber(e.target.value)
          setErrorMessage('')
        })()
  }

  const onNextButtonClick = () => {
    dispatch(checkIsTableValid({ restaurant_id: restaurantId, table_number: tableNumber }))
  }

  useEffect(() => {
    if(isTableValid) {
      dispatch(setIsTableValid(false))
      dispatch(setNewOrderDetails({ tableNumber, restaurantId }))
      isAuthenticated
          ? history.replace(order_first_time_path.replace(':restaurantId', restaurantId).replace(':tableNumber', tableNumber))
          : dispatch(setActiveModal(login_modal))
    }
  }, [isTableValid, dispatch, history, isAuthenticated, tableNumber, restaurantId])

  useEffect(() => {
    if (reservationId && responseMessage) {
      dispatch(
        setPageRoutePath(
          order_page_path.replace(':reservationId', reservationId)
        )
      )
    }
  }, [reservationId, responseMessage, dispatch])

  return (
    <Row className="m-4 reservations-row text-center">
      <Column className="choose-table-column" md={'10'} lg={8}>
        <img src={TablePlaceholder} alt="table" />
        <h2 className="mt-4">Please scan the QR code or input the table number manually</h2>
        <InputField
          type="text"
          onChange={onTableNumberChange}
          value={tableNumber}
          min={1}
          error={errorMessage}
          errorClassName="text-left"
          inputClassName="table-number"
          placeholder={'Input the table number'}
        />
        <div className="order-next-btn">
          <button onClick={onNextButtonClick} className="btn btn-primary">
            Next
          </button>
        </div>
      </Column>
    </Row>
  )
}

export default ChooseTable
