import React from 'react'
import classnames from 'classnames'

const defaultWrapperStyle = {
  width: '42px',
  height: '42px',
  borderRadius: '33px',
  border: 'solid 2px #01003b'
}

const defaultTextStyle = {
  width: '6px',
  height: '2px',
  backgroundColor: '#01003b'
}

function RoundButton({
  style = defaultWrapperStyle,
  onClick,
  wrapperClassName,
  textStyle = defaultTextStyle,
  textClassName,
  text
}) {
  return (
    <div
      style={style}
      onClick={onClick}
      className={classnames('circle-btn-base', wrapperClassName)}
    >
      <span style={textStyle} className={textClassName}>
        {text}
      </span>
    </div>
  )
}

export default RoundButton
