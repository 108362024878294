// It would defenetely be best for future reference to create a switch case which handles all urls and
// their supposed navigation links to avoid errors like this freestyle solution

export function navigateToParentUrl(pathname) {
  // Split the pathname into segments
  const segments = pathname.split('/');

  // Check if the last segment is a number
  if (!isNaN(Number(segments[segments.length - 1]))) {
    // If it's a number, remove the last two segments
    segments.splice(-2);
  } else {
    // If it's not a number, remove only the last segment
    segments.pop();
  }

  // Join the remaining segments to construct the parent path
  const parentPath = segments.join('/');

  // If the parent path is empty, set it to '/'
  return parentPath || '/';
}
