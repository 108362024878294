import React from 'react'
import Modal from '../modal/Modal'
import ModalHeading from '../common/ModalHeading'
import './DeactivateAccountModal.css'

function DeactivateAccountModal({ show, close, onDeactivate }) {
  return (
    <Modal className="deactivate-account-modal" show={show} close={close}>
      <ModalHeading
        className="password-request-sent-modal-header"
        title="Deactivate account"
        onClick={close}
      />
      <div>
        <h3>Are you sure you want to deactivate your account?</h3>
        <p>
          You will lose access to all booking and ordering information. For
          access, you can re-activate your account by creating a new account
          with the same e-mail address.
          <br />
          <br />
          If you want to delete your account, please send an e-mail to
          contact@voila.nyc
        </p>
      </div>
      <div id="deactivate-btn-container">
        <button
          onClick={onDeactivate}
          className="update-info-btn deactivate-account-btn"
        >
          <span>Yes, I am sure</span>
        </button>
        <button
          className="update-info-btn deactivate-account-btn"
          onClick={close}
        >
          <span>Cancel</span>
        </button>
      </div>
    </Modal>
  )
}

export default DeactivateAccountModal
