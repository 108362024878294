import React, { useState } from 'react'
import RoundButton from '../../common/RoundButton'
import CustomButton from '../../common/CustomButton'
import './styles/PreparationTime.css'

function PreparationTime({ initialDelay, maxOffset, cb }) {
  const [preparationTimes, setPreparationTimes] = useState({
    startMinutes: +initialDelay,
    endMinutes: +initialDelay + 5
  })

  const onDecreaseMinutes = () => {
    if (preparationTimes.startMinutes - 5 < initialDelay) return null
    let startMinutes = preparationTimes.startMinutes - 5
    let endMinutes = preparationTimes.endMinutes - 5
    setPreparationTimes({ startMinutes, endMinutes })
    cb(startMinutes)
  }

  const onIncreaseMinutes = () => {
    if (preparationTimes.endMinutes + 5 > +initialDelay + +maxOffset)
      return null
    let startMinutes = preparationTimes.startMinutes + 5
    let endMinutes = preparationTimes.endMinutes + 5
    setPreparationTimes({ startMinutes, endMinutes })
    cb(startMinutes)
  }

  return (
    <div className="preparation-time-wrapper">
      <RoundButton onClick={onDecreaseMinutes} />
      <CustomButton
        wrapperClassName="reservation-btn-wrapper"
        textClassName="preparation-time-btn-text"
        text={
          preparationTimes.startMinutes +
          ' - ' +
          preparationTimes.endMinutes +
          'mins.'
        }
        // onClick={onCancelInvitationClick}
      />
      <RoundButton
        onClick={onIncreaseMinutes}
        text="+"
        textStyle={{}}
        textClassName="add-menu-item-plus-btn"
      />
    </div>
  )
}

export default PreparationTime
