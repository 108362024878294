import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import OrdersRounds from '../../components/ordersRounds/OrdersRounds'
import EmptyReservation from '../../components/reservations/EmptyReservation'
import ReservationListItem from '../../components/reservations/ReservationListItem'
import SideMenuX from '../../components/sideMenu/SideMenuX'
import {
  booking_reservation_path,
  order_page_path,
} from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import useSocket from '../../hooks/useSocket'
import { setIsForWhomToPayConfirmed, setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { getUser, setIsPay, setIsProceededToCheckoutSelection } from '../../store/actions/usersActions'
import { setPayingForOthers } from "../book/services/actions"
import { getCheckoutInfo, getLastRoundOrders, getOrders, getOrdersNoRounds, getOrdersRounds, setNewNameForAnonGuest } from '../order/services/actions'
import { getIsPaymentRequired } from '../restaurant/services/actions'
import { reservation_types } from './config/reservation_types'
import './ReservationsPage.css'
import {
  getCurrentReservation,
  getReservations,
  setOtherTryingToPay,
  setRefreshCheckout,
  setUserIdsSomeonePaysFor
} from './services/actions'

function ReservationsPage() {
  const {
    booking: { reservations, initReservationType, currentReservation, payingForOthersState, reservationDetails },
    auth: { isAuthenticated, user },
    user: { user: { user_id }, isProceededToCheckoutSelection},
    feedback: { connectSocketAfterIdleTimeout, refreshDataForOpenedMenuItem },
    orders: { lastRoundOrders }
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const { restaurantId } = useParams()
  const history = useHistory()
  const [upcomingReservations, setUpcomingReservations] = useState([])
  const [pastReservations, setPastReservations] = useState([])
  const [currentReservations, setCurrentReservations] = useState([])
  const [reservationType, setReservationType] = useState(null)
  const [countRequest, setCountRequest] = useState({
    getIsPaymentRequired: 0,
    showOrdersRoundsComponent: false,
  })

  const onReservationClickHandler = useCallback(
    (restaurantId, reservationId,  reservationType) => {
      if(reservationType === 0) {
        history.push(booking_reservation_path
          .replace(':restaurantId', restaurantId)
          .replace(':reservationId', reservationId))
      }
      else {
        history.push(order_page_path.replace(':reservationId', reservationId))
      }
    },
    [reservationType, history]
  )

  const onRefresh = useCallback(
    reservation_id => {
      if(reservation_id) {
        dispatch(getCurrentReservation())
        dispatch(getOrders(reservation_id))
        dispatch(getOrdersRounds(reservation_id))
        dispatch(getOrdersNoRounds(reservation_id))
        dispatch(setRefreshCheckout(true))
        dispatch(setIsPay(false))
        dispatch(getLastRoundOrders(reservation_id))
        isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false))
        
        if (refreshDataForOpenedMenuItem.menuItemId) {
          dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: true}))
        }
      }
    },
    [dispatch, refreshDataForOpenedMenuItem.menuItemId]
  )

  const onPaymentExpired = useCallback(({data}) => {
    // workaround for when the payment resets properly the socket is called 2 times
    // if the payment hasn't been reset properly the socket is called once
    for (let i = 0; i < 2; i++) {
      dispatch(setPayingForOthers(payingForOthersState.filter(state => state !== data)))
      if(data.payerId === user.id) {
        dispatch(getCheckoutInfo(currentReservation?.reservation_id, [user.id]))
        dispatch(setIsPay(false))
        dispatch(setIsProceededToCheckoutSelection(false))
      }
    }
  },[dispatch, user.id])

  const onRefreshWithoutResetingIsPay = useCallback(
    reservation_id => {
      if(reservation_id) {
        dispatch(getOrders(reservation_id, true))
        dispatch(getOrdersRounds(reservation_id))
        dispatch(getOrdersNoRounds(reservation_id))
        dispatch(setRefreshCheckout(true))
        dispatch(getLastRoundOrders(reservation_id))
      }
    },
    [dispatch]
  )

  const onOtherTryingToPay = useCallback(
    ({ usersTryingToPay }) => {
      dispatch(
        setOtherTryingToPay(
          isSomeonePayingForCurrentUser(usersTryingToPay, user.id)
        )
      )

      dispatch(
        setUserIdsSomeonePaysFor(
          getIdsSomeonePaysFor(usersTryingToPay, user.id)
        )
      )

      dispatch(setPayingForOthers(usersTryingToPay))
    },
    [dispatch, user.id]
  )

  const onUserInfoChanged = useCallback(
    (data) => {
      dispatch(setNewNameForAnonGuest(data.userId, data.userName))
      },  
    [dispatch]
  )
  const onOrderClosed = useCallback((reservationId) => {
    history.push(order_page_path.replace(':reservationId', reservationId))
  }, [history])

  const { emitFriendSelected } = useSocket({
    reservationId: (currentReservation || {}).reservation_id,
    shouldConnect: reservationType === 1 && !connectSocketAfterIdleTimeout,
    onRefresh,
    onOtherTryingToPay,
    onOrderClosed,
    onRefreshWithoutResetingIsPay,
    onPaymentExpired,
    onUserInfoChanged
  })

  useEffect(() => {
    setCountRequest({ ...countRequest, getIsPaymentRequired: 0 })
    if (countRequest.getIsPaymentRequired === 0) {
      if ((currentReservation || {}).restaurant_id) {
        dispatch(getIsPaymentRequired(currentReservation.restaurant_id))
        setCountRequest({ ...countRequest, getIsPaymentRequired: 1 })
      }
    }
  }, [dispatch, currentReservation,])

  useEffect(() => {
    if (!user_id) { 
      dispatch(getUser())
    }
  }, [dispatch])

  // useEffect(() => {
  //   setReservationType(initReservationType)
  // }, [initReservationType])

  useEffect(() => {
    isAuthenticated && dispatch(getReservations())
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (currentReservation?.reservation_id && lastRoundOrders.length === 0) {
      dispatch(getLastRoundOrders(currentReservation?.reservation_id))
    } 
  }, [dispatch, currentReservation?.reservation_id])

  useEffect(() => {
    setPastReservations(
      isEmpty(reservations.past) ? (
        <EmptyReservation 
          text="Nothing ordered yet."
          paragraph="Ready when you are."
        />
      ) : (
        <ul className="reservations-list">
          {reservations.past.map((props, idx) => (
            <ReservationListItem key={idx} onClick = {() => onReservationClickHandler(props.restaurant_id, props.reservation_id, reservationType)} {...props} />
          ))}
        </ul>
      )
    )

    setUpcomingReservations(
      isEmpty(reservations.upcoming) ? (
        <EmptyReservation 
          text="Nothing ordered yet."
          paragraph="Ready when you are."
        />
      ) : (
        <ul className="reservations-list">
          {reservations.upcoming.map((props, idx) => (
            <ReservationListItem key={idx} onClick = {() => onReservationClickHandler(props.restaurant_id, props.reservation_id, reservationType)} {...props} />
          )).reverse()}
        </ul>
      )
    )
    
  }, [reservations, onReservationClickHandler])

  useEffect(() => {
    async function fetchData() {
      await dispatch(getCurrentReservation()).then(() => {setCountRequest({...countRequest, showOrdersRoundsComponent: true})})
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (countRequest.showOrdersRoundsComponent) {
      if (!isEmpty(currentReservation)) {
        setCurrentReservations(
          <OrdersRounds
            emitFriendSelected={emitFriendSelected}
            reservation_id={currentReservation.reservation_id}
          />
        )}
      else setCurrentReservations(
        <EmptyReservation 
          // text="Nothing ordered yet."
          // paragraph="Ready when you are."
          // These values can be changed from here as they're initialy set if no props are provided
        />
      )
    }
  }, [currentReservation, emitFriendSelected, countRequest.showOrdersRoundsComponent])

  useEffect(() => {
    if (reservationType !== 1) {
      dispatch(setIsPay(false))
      dispatch(setIsProceededToCheckoutSelection(false))
      dispatch(setIsForWhomToPayConfirmed(false))
    }
  }, [reservationType])

  useEffect(() => {
    if(!isEmpty(reservations?.ongoing)) setReservationType(1)
    else if(!isEmpty(reservations?.upcoming)) setReservationType(0)
    else if(!isEmpty(reservations?.past)) setReservationType(2)
    else setReservationType(1)

    return () => {
      setReservationType(null)
    }
  }, [reservations])

  const onReservationTypeClickHandler = idx => {
    setReservationType(idx)
  }

  // console.log(reservationType)
  // console.log(reservation_types(1)[0].text)

  // Adding class on side menu wrapper so it can have display none on mobile
  // let sideMenuWrapper = (currentReservations.props && currentReservations.props.reservation_id) ? 'side-menu-wrapper' : ''

  return (
    
    <Row className="reservations-row justify-content-center">
      <Column md={10} lg={8}>
        <Row>
          {!user.is_anonymous
            ? <Column md="4" lg="4">
                {/*Renamed from Reservations to Dining*/}
                <h2 className="page-heading">Dining</h2>
                {reservationType !== null &&
                  <SideMenuX
                  listItems={reservation_types(reservationType)}
                  handleActive={onReservationTypeClickHandler}
                  upcomingBadge={reservations?.upcoming.length ?? 0}
                  ongoingBadge={reservations?.ongoing ? 1 : 0}
                  pastBadge={reservations?.past.length ?? 0}
                  reservationType={reservationType}
                  />
                }
              </Column>
            : <Column md="4" lg="4" />
          }
          <Column className="scrollable-list" md="8" lg="8">
            {reservationType
              ? reservationType === 1
                ? currentReservations
                : pastReservations
              : upcomingReservations}
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

export default ReservationsPage

function isSomeonePayingForCurrentUser(usersTryingToPay = [], userId) {
  return usersTryingToPay.some(
    value =>
      +value.payerId !== +userId && value.userIds.some(id => +id === +userId)
  )
}

function getIdsSomeonePaysFor(usersTryingToPay = [], userId) {
  return usersTryingToPay
    .filter(value => +value.payerId !== +userId)
    .reduce((acc, curr) => {
      acc = acc.concat(curr.userIds)
      return acc
    }, [])
}
