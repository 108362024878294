import React from 'react'
import classnames from 'classnames'
import { useLocation } from 'react-router'

const badgeStyle = {
  width: '20px',
  height: '20px',
  borderRadius: '33px',
  border: 'solid 1px #ffffff',
  backgroundColor: '#01003b',
  color: '#ffffff',
  textAlign: 'center',
  transform: 'translateY(-30px) translateX(12px)',
  position: 'absolute',
  lineHeight: '20px'
}

function NavItemWithIcon({
  activeIcon,
  inactiveIcon,
  text,
  onClick,
  className,
  path,
  badgeNumber = 0
}) {
  const location = useLocation()
  const isActive = location.pathname === path
  return (
    <li
      onClick={onClick}
      className={classnames('nav-item my-auto', className, {
        active: isActive
      })}
    >
      <img
        className="nav-item-img"
        src={isActive ? activeIcon : inactiveIcon}
        alt="nav-item"
      />
      {badgeNumber ? (
        <div className="nav-item-badge" style={badgeStyle}>
          <span>{badgeNumber}</span>
        </div>
      ) : null}

      <span style={{ marginLeft: '10px' }} className="nav-item-text">
        {text}
      </span>
    </li>
  )
}

export default NavItemWithIcon
