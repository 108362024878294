import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrdersNoRounds,
  getOrdersRounds,
  setOrderNextRoute
} from '../../pages/order/services/actions'
import isEmpty from 'lodash.isempty'
import Round, { ARCHIVED_USER_COLOR } from './components/Round'
import { order_page_path, } from '../../config/pages_paths'
import { useHistory } from 'react-router'
import { genHexIndex } from '../../utils/genHexIndex'
import hexColors from "../../constants/hexColors";
import UserOrders from './components/UserOrders'

function ReservationOrders({ reservation_id, canOrder, }) {
  const [ordersData, setOrdersData] = useState([])
  const [showUserOrdersComponent, setShowUserOrdersComponent] = useState(false)
  const {
    auth: {
      user: { id }
    },
    booking: {
      reservationDetails
    }
  } = useSelector(state => state)
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    orders: { ordersRounds, ordersNoRounds }
  } = useSelector(state => state)
  const [avatarColors, setAvatarColors] = useState([])
  const [isActiveReservation, setIsActiveReservation] = useState(false)
  const [hasTableId, setHasTableId] = useState(false)

  useEffect(() => {
    if (!isEmpty(ordersNoRounds) && isEmpty(avatarColors)) {
      let colors = []
      ordersNoRounds.forEach(() => {
        let hexIndex = genHexIndex(colors);
        colors.push(hexColors[hexIndex])
      })
      setAvatarColors(colors)
    }
  }, [ordersNoRounds, avatarColors])

  useEffect(() => {
    async function fetchData() {
      dispatch(getOrdersRounds(reservation_id))
      await dispatch(getOrdersNoRounds(reservation_id)).then(() => { setShowUserOrdersComponent(true) })
    }
    fetchData()
  }, [dispatch, reservation_id])

  useEffect(() => {
    if (!isEmpty(ordersNoRounds)) {
      setOrdersData(ordersNoRounds)
    }

    return () => setOrdersData([])
  }, [ordersNoRounds, ordersData])

  const onOrderFromMenuClick = () => {
    dispatch(
      setOrderNextRoute({
        path: order_page_path.replace(':reservationId', reservation_id)
      })
    )
    history.push(order_page_path.replace(':reservationId', reservation_id))
  }

  useEffect(() => {
    !reservationDetails.reservation?.is_closed && setIsActiveReservation(true)
    !isEmpty(reservationDetails.reservation.table_external_id) && setHasTableId(true)
  }, [reservationDetails, isActiveReservation, hasTableId])

  return (
    <div className="orders-rounds-wrapper">
      {/* {orders.map((round, idx) => {
        return (
          <Round
            onOrderFromMenuClick={onOrderFromMenuClick}
            canPay={canPay}
            canOrder={canOrder}
            key={idx}
            {...round}
            avatarColors={avatarColors}
            reservation_id={reservation_id}
          />
        )
      })} */}
      {showUserOrdersComponent && ordersData.map((user, idx) => {
        return (
          <UserOrders
            key={user.user_id ?? idx}
            orderPosition={idx}
            is_archived={user.is_archived}
            // canPay={hasNewOrder}
            canOrder={canOrder}
            // onPaymentCheckboxChange={() =>
            //   onPaymentCheckboxChange({ userId: user.user_id })
            // }
            // isPaymentChecked={!!payForUsers.find(userId => +userId === +user.user_id)}
            areOwnOrders={id === +user.user_id}
            avatarBackground={user.is_archived ? ARCHIVED_USER_COLOR : avatarColors[idx]}
            avatarColors={avatarColors}
            userDisplayName={id === +user.user_id ? user.user : user.user}
            userStatus={user.user_order_status && user.user_order_status}
            firstLetter={
              user.is_archived
                ? '?'
                : user.user.charAt(0).toUpperCase()
              // user.is_anonymous
              //   ? `${user.user.charAt(0).toUpperCase()}${user.user.charAt(user.user.length - 1).toUpperCase()}`
              //   : user.user.charAt(0).toUpperCase()
            }
            reservation_id={reservation_id}
            {...user}
            user={user}
            isActiveReservation={isActiveReservation}
            hasTableId={hasTableId}
            currentUserId={user.user_id}
            isAccordionOpened={user.user_id === id}
          />
        )
      })
      }
    </div>
  )
}

export default ReservationOrders
