import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import VoilaLogo from '../../assets/logo-voila.svg';
import Modal from '../../components/modal/Modal'
import { login_modal } from '../../config/modal_paths'
import { setActiveModal } from '../../store/actions/feedbackActions'
import { registerAnonymously } from '../auth/services/actions'
import CheckMarkPoint from '../../components/common/CheckMarkPoint'

function BookedReservationModal({ show, close }) {
    const {
        feedback: { activeModalMetadata, activeModal },
        restaurant: { restaurant },

    } = useSelector(state => state)

    const dispatch = useDispatch()

    const handleToLoginModal = () => {
        dispatch(setActiveModal(login_modal))
    }

    const handleContinueAsAnonymous = () => {
        // dispatch(setBookedReservationReturn(false))
        // dispatch(registerAnonymously())
        if (activeModalMetadata) {
            dispatch(registerAnonymously(
                activeModalMetadata?.visitorData || null, 
                activeModalMetadata?.visitorDataError || null, 
                activeModalMetadata?.restaurantId, 
                activeModalMetadata?.tableNumber
            ))
        }
    }

    return (
        <Modal show={show} className="login-modal">
            {/* <ModalHeading title="Login or create an account" /> */}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {restaurant && <img src={restaurant?.logo} style={{ width: "100%", margin: "0 0 1rem", borderRadius:"0.5rem" }} />}

                <h3 style={{textAlign:"center"}}>Login or create an account for full dining experience</h3>

                <CheckMarkPoint />

                <div style={{ marginBottom: "0.5rem" }}>
                    <p>Powered by:</p>
                    <img alt='logo_voila' className='voila-logo' src={VoilaLogo} />
                </div>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1rem", margin: "1rem 0", width: "100%" }}>
                    <button
                        className="btn btn-primary  blue-rounded-button"
                        style={{ width: "100%" }}
                        onClick={handleToLoginModal}
                    >
                        Login
                    </button>
                    <button
                        className="btn btn-primary  white-rounded-button"
                        onClick={handleContinueAsAnonymous}
                        style={{ width: "100%" }}
                    >
                        Continue anonymously
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default BookedReservationModal
