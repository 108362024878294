import {
  SET_RESPONSE_MESSAGE,
  SET_MODAL,
  SET_PAGE_ROUTE_PATH,
  SET_REGISTER_REDIRECT_RESPONSE,
  SET_TOAST_MESSAGE,
  SET_NUM_OF_LOADING_SPINNER_CALLS,
  SET_IS_TABLE_VALID, SET_CURRENT_ACCORDION, SET_CURRENT_ACCORDION_ORDER,
  SET_EXPIRATION_TIMER_MODAL,
  SET_TIMER_EXPIRED_MESSAGE_MODAL,
  SET_MENU_ITEM_QUANTITY_CHANGED_MODAL,
  SET_IS_FOR_WHOM_TO_PAY_CONFIRMED,
  SET_ANCHOR_MENU,
  SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT,
  SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM
} from '../../config/actionTypes'
import {
  check_table_validity_api,
  send_support_email_api
} from '../../config/api_endpoints'
import axios from 'axios'
import { setErrors } from './errorsActions'

export const setLoader = payload => dispatch => {
  dispatch({ type: SET_NUM_OF_LOADING_SPINNER_CALLS, payload })
}

export const setResponseMessage = payload => dispatch => {
  dispatch({ type: SET_RESPONSE_MESSAGE, payload })
}

export const setActiveModal = (modal, metadata, reservationId) => dispatch => {
  dispatch({ type: SET_MODAL, payload: modal, metadata, reservationId })
}

export const setAnchorMenu = payload => dispatch => {
  dispatch({type: SET_ANCHOR_MENU, payload})
}

export const setPageRoutePath = route_path => dispatch => {
  dispatch({ type: SET_PAGE_ROUTE_PATH, payload: route_path })
}

export const setRegisterRedirectResponse = payload => dispatch => {
  dispatch({ type: SET_REGISTER_REDIRECT_RESPONSE, payload })
}

export const setToastMessage = payload => dispatch => {
  dispatch({ type: SET_TOAST_MESSAGE, payload })
}

export const sendContactEmail = data => async dispatch => {
  try {
    dispatch(setLoader(true))
    await axios.post(send_support_email_api, data)
    dispatch(setLoader(false))
    dispatch(setToastMessage({ message: 'Email sent!', severity:"info" }))
  } catch (err) {
    dispatch(setLoader(false))
  }
}

export const checkIsTableValid = data => async dispatch => {
  try {
    dispatch(setLoader(true))
    await axios.get(check_table_validity_api, { params: data })
    dispatch(setIsTableValid(true))
    dispatch(setLoader(false))
  } catch (err) {
    dispatch(setLoader(false))
    dispatch(setIsTableValid(false))
    dispatch(setErrors(err, () => checkIsTableValid(data)))
  }
}

export const setIsTableValid = payload => dispatch => {
  dispatch({ type: SET_IS_TABLE_VALID, payload })
}

export const setCurrentAccordion = payload => dispatch => {
  dispatch({type: SET_CURRENT_ACCORDION, payload})
}

export const setCurrentAccordionOrder = payload => dispatch => {
  dispatch({type: SET_CURRENT_ACCORDION_ORDER, payload})
}

export const setExpirationTimerModal = payload => dispatch => {
  dispatch({ type: SET_EXPIRATION_TIMER_MODAL, payload })
}

export const setTimerExpiredMessageModal = payload => dispatch => {
  dispatch({ type: SET_TIMER_EXPIRED_MESSAGE_MODAL, payload })
}

export const setIsForWhomToPayConfirmed = payload => dispatch => {
  dispatch({ type: SET_IS_FOR_WHOM_TO_PAY_CONFIRMED, payload })
}

export const setConnectSocketAfterIdleTimeout = payload => dispatch => {
  dispatch({ type: SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT, payload })
}

export const setMenuItemQuantityChangedModal = payload => dispatch => {
  dispatch({type: SET_MENU_ITEM_QUANTITY_CHANGED_MODAL, payload})
}

export const setRefreshDataForOpenedMenuItem = payload => dispatch => {
  dispatch({type: SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM, payload})
}