import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import isEmpty from "lodash.isempty";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { login_modal } from '../../config/modal_paths'
import { order_first_time_path, order_page_path } from "../../config/pages_paths";
import { setActiveModal } from '../../store/actions/feedbackActions'
import { registerAnonymously } from "../auth/services/actions"
import { getHasBookedReservation } from '../book/services/actions';
import { setRestaurantById } from '../restaurant/services/actions';
import { getCurrentReservation } from "../../pages/reservations/services/actions";
import { setNewOrderDetails } from "../../pages/order/services/actions";
import { setPageRoutePath } from "../../store/actions/feedbackActions";
import EmptyReservationImage from '../../assets/empty-plate.png'
import VoilaLogo from '../../assets/logo-voila.svg';
import CheckMarkPoint from '../../components/common/CheckMarkPoint'

const QrOrderLanding = () => {
    const {
        auth: { isAuthenticated },
        versionConfig: { is_anonymous_user_identification_enabled },
        restaurants: { restaurants },
        restaurant: { restaurant },
    } = useSelector(state => state)

    const { restaurantId, tableNumber } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()

    const [showErrorOccurred, setShowErrorOccurred] = useState(false)
    const [showBookedOrderOptions, setShowBookedOrderOptions] = useState(false)

    const { isLoading: isAutoIdentifyLoading, data: visitorData, error: visitorError } = useVisitorData()

    const redirectToMenuParams = { dispatch, history, tableNumber, restaurantId }

    useEffect(() => {
        if (isAuthenticated) {
            redirectToMenu()
        } else {
            dispatch(getHasBookedReservation(restaurantId, tableNumber, (hasBookedReservation) => {
                if (hasBookedReservation) {
                    setShowErrorOccurred(false)
                    setShowBookedOrderOptions(true)

                    if (!isEmpty(restaurants) && !isEmpty(restaurantId)) {
                        dispatch(setRestaurantById({ restaurants, restaurantId }))
                    }
                } else {
                    tryToIdentifyUser()
                }
            }))
        }
    }, [dispatch, is_anonymous_user_identification_enabled, isAutoIdentifyLoading])

    const redirectToMenu = () => {
        dispatch(getCurrentReservation((currentReservation) => {
            if (!isEmpty(currentReservation)) {
                dispatch(setPageRoutePath(order_page_path.replace(':reservationId', currentReservation.reservation_id)))
            } else {
                dispatch(setNewOrderDetails({ tableNumber, restaurantId }))
                // !!! history replace !!! to stop going back on browsers back arrow click
                history.replace(order_first_time_path.replace(':restaurantId', restaurantId).replace(':tableNumber', tableNumber))
            }
        }))
    }

    const tryToIdentifyUser = () => {
        if (is_anonymous_user_identification_enabled !== null && is_anonymous_user_identification_enabled !== undefined) {
            if (is_anonymous_user_identification_enabled) {
                if (isAutoIdentifyLoading !== null && isAutoIdentifyLoading !== undefined) {
                    if (isAutoIdentifyLoading) {
                        // still loading, maybe show wait msg?
                        // console.log("AutoIdentify still loading")
                    } else {
                        if (!isEmpty(visitorData) && isEmpty(visitorError)) {
                            // success
                            setShowErrorOccurred(false)
                            dispatch(registerAnonymously(visitorData, restaurantId, tableNumber, redirectToMenu, redirectToMenuParams))
                            // console.log("AutoIdentify success")
                        } else if (isEmpty(visitorData) && !isEmpty(visitorError)) {
                            // fail
                            setShowErrorOccurred(true)
                            // console.log("AutoIdentify fail")
                        } else {
                            // impossible case bug?
                            // console.log("AutoIdentify impossible case")
                        }
                    }
                }
            } else {
                setShowErrorOccurred(false)
                dispatch(registerAnonymously(null, restaurantId, tableNumber, redirectToMenu, redirectToMenuParams))
            }
        }
    }

    const handleToLoginModal = () => {
        dispatch(setActiveModal(login_modal, { redirectToMenu, redirectToMenuParams }))
    }

    const handleContinueAsAnonymous = () => {
        setShowErrorOccurred(false)
        dispatch(registerAnonymously(null, restaurantId, tableNumber, redirectToMenu, redirectToMenuParams))
    }

    const handleContinueWithoutAccount = () => {
        tryToIdentifyUser()
    }

    return (
        <div className="col-md-4 offset-md-4 col-xs-12 col-sm-12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {showErrorOccurred && (
                <>
                    <img src={EmptyReservationImage} alt="empty-reservation" />

                    <h4 className='message-margin-bottom message-margin-top'>An error occured while trying to automatically identify you.</h4>
                    <h4 className='message-margin-bottom'>Refresh the page to try again or continue as new anonymous user.</h4>
                    <h4 className='message-margin-bottom'>If you continue anonymously we can not link your prevous orders, but you will still be able to pay for them.</h4>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "4rem", width: "100%" }}>
                        <button
                            className="btn btn-primary blue-rounded-button"
                            style={{ width: "100%" }}
                            onClick={handleToLoginModal}
                        >
                            Log in / Sign up
                        </button>
                        <button
                            className="btn btn-primary white-rounded-button"
                            onClick={handleContinueAsAnonymous}
                            style={{ width: "100%" }}
                        >
                            Continue anonymously
                        </button>
                    </div>
                </>
            )}
            { !showErrorOccurred && showBookedOrderOptions && (
                <>
                    {restaurant && <img src={restaurant?.logo} style={{ margin: "0 0 1rem", borderRadius:"0.5rem" }} alt="restaurant-logo" />}

                    <h3 style={{textAlign:"center"}}>Login or create an account for full dining experience</h3>

                    <CheckMarkPoint />

                    <div style={{ marginBottom: "0.5rem" }}>
                        <p>Powered by:</p>
                        <img alt='logo_voila' className='voila-logo' src={VoilaLogo} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1rem", margin: "1rem 0", width: "100%" }}>
                        <button
                            className="btn btn-primary  blue-rounded-button"
                            style={{ width: "100%" }}
                            onClick={handleToLoginModal}
                        >
                            Log in / Sign up
                        </button>
                        <button
                            className="btn btn-primary  white-rounded-button"
                            onClick={handleContinueWithoutAccount}
                            style={{ width: "100%" }}
                        >
                            Continue without an account
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

export default QrOrderLanding
