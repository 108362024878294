import React from 'react'
import { useSelector } from 'react-redux'
import RestaurantsGrid from '../../components/restaurants/RestaurantsGrid'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import './HomePage.css'


function HomePage() {
  const { 
    restaurants: {restaurants},
    restaurant: { currentPosition },
    navigation: { redirectToHome },
    booking: { reservations },
   } = useSelector(state => state)
   
    return (
    <Row className="home-page-row mx-auto">
      <Column md={12} lg={12} xl={9}>
        <RestaurantsGrid restaurants={restaurants} currentPosition={currentPosition} ongoing={reservations?.ongoing}/>
      </Column>
    </Row>
  )
}

export default HomePage
