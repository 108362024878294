import React from 'react'
import CustomButton from '../../common/CustomButton'

function CancelReservationButton({ onClick }) {
  return (
    <CustomButton
      onClick={onClick}
      text={'Yes, cancel'}
      wrapperClassName="cancel-reservation-btn"
      textClassName="cancel-reservation-btn-text"
    />
  )
}

export default CancelReservationButton
