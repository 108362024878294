import React from 'react'
import Modal from '../modal/Modal'
import PaymentDoneImage from '../../assets/illustration-payment-done.png'
import VoilaLogo from '../../assets/logo-voila.svg'
import RestaurantLogo from '../../assets/logo-boucherie.png'
import './PaymentDoneModal.css'
import CustomButton from '../common/CustomButton'
import {useDispatch, useSelector} from "react-redux";
import RatingStars from '../common/rating/RatingStars'
import { editRating } from '../../pages/order/services/actions'

function PaymentDoneModal({ show, close, metadata }) {
    const { 
        restaurant: { restaurant },
        orders: { userRating }
    } = useSelector(state => state)

    const dispatch = useDispatch()

    const { ratingContent, ratingStars, ratingDescription } = 
    RatingStars({
        existingRatingStars:userRating.ratingStars, 
        existingRatingDescription:userRating.ratingDescription
    })

    const ratingFeedback = ratingStars || ratingDescription

    const handleCloseModal = () => {
        ratingFeedback && dispatch(editRating(userRating.reservationId, ratingStars, ratingDescription))
        close()
    }

    return (
        <Modal className="payment-done-anon-modal" show={show} close={handleCloseModal}>
            <div>
                <img alt="chef" className='payment-done-img' src={PaymentDoneImage} />
                <h3>Payment done</h3>
                <img className='restaurant-logo' alt='restaurant_logo' src={ restaurant.logo || RestaurantLogo } />
                <p id='restaurant-title'>{ restaurant.name }</p>

                <p>Thank you for dining with us. We hope you enjoyed your experience.</p>

                {ratingContent}

                <p id='powered-by'>Powered by:</p>
                <img alt='logo_voila' className='voila-logo' src={VoilaLogo} />
                <CustomButton
                    onClick={handleCloseModal}
                    text={'Ok, great'}
                    wrapperClassName="payment-done-button"
                    textClassName="cancel-reservation-btn-text"
                />
            </div>
        </Modal>
    )
}

export default PaymentDoneModal
