import React from 'react'
import CustomButton from '../../common/CustomButton'

function AddItemButton({ onClick, disabled }) {
  return (
    <div style={{ position: 'sticky', bottom: '10px' }} className='add-to-order-btn-wrapper'>
    <CustomButton
      disabled={disabled}
      onClick={onClick}
      text="Add Item"
      wrapperClassName="booking-btn add-menu-item-submit-btn add-to-order-btn mx-auto"
      textClassName="booking-btn-text add-menu-item-submit-btn-text add-to-order-btn-text active"
    />
    </div>
  )
}

export default AddItemButton
