import React from 'react'

function MenuItemBadge({ number }) {
  return (
    <div className="menu-item-badge-container">
      <p className="menu-item-badge">{number}</p>
    </div>
  )
}

export default MenuItemBadge
