import jwt_decode from 'jwt-decode';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { logoutUser } from "../../pages/auth/services/actions";

const AuthVerify = () => {
    const location = useLocation()
    const dispatch = useDispatch()

  useEffect(() => {
    const user = JSON.parse(JSON.stringify(localStorage.getItem("jwtToken")));

    if (user) {
      const decoded = jwt_decode(localStorage.jwtToken.split(' ')[1])

      if (decoded.exp * 1000 < Date.now()) {
        dispatch(logoutUser())
      }
    }
  }, [dispatch,location]);

  return null;
};

export default AuthVerify;