import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import AddMenuItemModal from '../../orders/AddMenuItemModal'
import {
  getLastRoundOrders,
  addItemToOrder,
  removeOrderItem,
  getItemInfo,
} from '../../../pages/order/services/actions'
import {
  getModifiersForMenuItem,
  getSelectedModifiersForOrderItem,
  setModifiersForMenuItem,
  setSelectedModifiersForOrderItem
} from '../../../pages/restaurant/services/actions'
import { setIsPay } from '../../../store/actions/usersActions'
import substringHelper from '../../../utils/substringHelper'
import isEmpty from 'lodash.isempty'
import CustomButton from '../../common/CustomButton'
import YouSureItemModal from '../../orders/YouSureItemModal'
import MenuItemDetailsModal from '../../restaurant/MenuItemDetailsModal'
import { setRefreshDataForOpenedMenuItem } from '../../../store/actions/feedbackActions'
import { AddIcon, DeleteIcon, DiscoverTuneIcon, DoNotDesturbIcon, EditIcon } from '../../../assets/svgs-google/GoogleIcons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import MenuItemPlaceholder from '../../../assets/menu_item_placeholder.png'
import Image from '../../common/Image'


function UserOrderItem({
  orderItem,
  isOwnItem,
  round,
  canOrder = true,
  orders,
  lastItem,
}) {
  const [showItemModal, setShowItemModal] = useState(false)
  const [existingOrderItem, setExistingOrderItem] = useState(null)
  const [onAddPopUp, setOnAddPopUp] = useState(false)
  const [onEditPopUp, setOnEditPopUp] = useState(false)
  const [onDeletePopUp, setOnDeletePopUp] = useState(false)
  const [onMenuItemDetails, setOnMenuItemDetails] = useState(false)
  const [modifierList, setModifierList] = useState([])
  const [specialRequirments, setSpecialRequirments] = useState('')
  const {
    booking: { currentReservation },
    orders: { lastRoundOrders },
    feedback: { refreshDataForOpenedMenuItem }
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const { reservationId } = useParams()
  const history = useHistory()

  // const modifierList = orderItem.modifiers.map((modifier, index) =>
  // (index !== 0 ? ", " : "" ) + modifier.name + (parseInt(modifier.price) !== 0 ? " $" + (modifier.price) : ""))

  useEffect(() => {
    !isEmpty(orderItem.modifiers) && setModifierList(orderItem.modifiers.map((modifier, index) =>
      (index !== 0 ? ", " : "") + modifier.name + (parseInt(modifier.price) !== 0 ? " $" + (modifier.price) : "")))

    !isEmpty(orderItem.description) && setSpecialRequirments(orderItem.description)
  }, [orderItem])

  // useEffect(() => {
  //   if (reservationId && !lastRoundOrders) dispatch(getLastRoundOrders(reservationId))
  // }, [dispatch, reservationId])

  useEffect(() => {
    if (refreshDataForOpenedMenuItem.refresh) {
      dispatch(getItemInfo(refreshDataForOpenedMenuItem.menuItemId, reservationId || currentReservation.reservation_id)).then((data) => {
        let tmpOrderItem;
        let existingItemInCurrentRoundFound = false;

        for (let i = 0; i < lastRoundOrders.length; i++) {
          if (lastRoundOrders[i].menu_item_id === orderItem.menu_item_id) {
            existingItemInCurrentRoundFound = true
            tmpOrderItem = lastRoundOrders[i]
            break
          }
        }

        if (!existingItemInCurrentRoundFound) {
          tmpOrderItem = { ...orderItem }
          delete tmpOrderItem.order_item_id
          delete tmpOrderItem.quantity
          delete tmpOrderItem.description
        }

        tmpOrderItem = {
          ...tmpOrderItem,
          inventory_status: data.inventory_status,
          remaining_quantity: data.remaining_quantity,
          reservation_selected_quantity: data?.reservation_selected_quantity || null,
          users: data?.users || []
        }

        setExistingOrderItem(tmpOrderItem)
      })

      dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: false }))
    }
  }, [refreshDataForOpenedMenuItem.refresh])

  const onAddOrderItemModalHandler = async () => {
    let tmpOrderItem
    let existingItemInCurrentRoundFound = false

    // if the user in the current round
    // have already ordered the same menu item
    // display it, so he can edit it
    for (let i = 0; i < lastRoundOrders.length; i++) {
      if (lastRoundOrders[i].menu_item_id === orderItem.menu_item_id) {
        existingItemInCurrentRoundFound = true
        tmpOrderItem = lastRoundOrders[i]
        break
      }
    }

    // else show him a new menu item with empty values
    // so he can create a new order for that particular menu item
    if (!existingItemInCurrentRoundFound) {
      tmpOrderItem = { ...orderItem }
      delete tmpOrderItem.order_item_id
      delete tmpOrderItem.quantity
      delete tmpOrderItem.description
    }

    dispatch(getItemInfo(tmpOrderItem.menu_item_id, reservationId || currentReservation.reservation_id)).then((data) => {
      tmpOrderItem = {
        ...tmpOrderItem,
        inventory_status: data.inventory_status,
        remaining_quantity: data.remaining_quantity,
        reservation_selected_quantity: data?.reservation_selected_quantity || null,
        users: data?.users || []
      }

      setExistingOrderItem(tmpOrderItem)
    })

    dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: tmpOrderItem.menu_item_id, refresh: false }))

    if (tmpOrderItem && tmpOrderItem.order_item_id)
      await dispatch(getSelectedModifiersForOrderItem(tmpOrderItem.order_item_id, currentReservation.restaurant_id)).then(() => { setShowItemModal(true) })
    else {
      await dispatch(getModifiersForMenuItem(tmpOrderItem.menu_item_id, tmpOrderItem.menu_section_id, currentReservation.restaurant_id)).then(() => { setShowItemModal(true) })
    }
    handleAddPopUp()
  }

  const onAddClickHandler = data => {
    dispatch(addItemToOrder(reservationId || currentReservation.reservation_id, data, history))
    onCloseOrderItemModalHandler()
    dispatch(setIsPay(false))
  }

  const onEditOrderItemModalHandler = async () => {
    dispatch(getItemInfo(orderItem.menu_item_id, reservationId || currentReservation.reservation_id)).then((data) => {
      orderItem = {
        ...orderItem,
        inventory_status: data.inventory_status,
        remaining_quantity: data.remaining_quantity,
        reservation_selected_quantity: data?.reservation_selected_quantity || null,
        users: data?.users || []
      }

      setExistingOrderItem(orderItem)
    })

    dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: orderItem.menu_item_id, refresh: false }))

    await dispatch(getSelectedModifiersForOrderItem(orderItem.order_item_id, currentReservation.restaurant_id)).then(() => { setShowItemModal(true) })
    handleEditPopUp()
  }

  const onDeleteOrderItemClick = () => {
    dispatch(removeOrderItem(orderItem.order_item_id, orderItem.reservation_id))
    handleDeletePopUp()
  }

  const onCloseOrderItemModalHandler = () => {
    dispatch(setSelectedModifiersForOrderItem([]))
    dispatch(setModifiersForMenuItem([]))
    setShowItemModal(false)
    dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: null, refresh: false }))
  }

  const handleAddPopUp = () => {
    setOnAddPopUp(prevState => !prevState)
  }

  const handleEditPopUp = () => {
    setOnEditPopUp(prevState => !prevState)
  }

  const handleDeletePopUp = () => {
    setOnDeletePopUp(prevState => !prevState)
  }

  const handleMenuItemDetails = () => {
    setOnMenuItemDetails(prevState => !prevState)
  }

  return (
    <>
      {showItemModal && (
        <AddMenuItemModal
          onAddClickHandler={onAddClickHandler}
          show={showItemModal}
          close={onCloseOrderItemModalHandler}
          item={existingOrderItem}
        />
      )}
      {
        onMenuItemDetails &&
        <MenuItemDetailsModal
          show={onMenuItemDetails}
          close={handleMenuItemDetails}
          item={orderItem}
          modifierList={modifierList}
          specialRequirments={specialRequirments}
        />
      }
      <div>
        <div className='grid-container-1-3-1'>
          <div className='grid-container-1-2' onClick={handleMenuItemDetails}>
            <div className='user-order-item-image-container_'>
              <div className='user-order-item-image-wrapper_'>
                {/* <img loading='eager' className='user-order-item-image_' src={orderItem.image} alt="order-item" /> */}
                {/* <LazyLoadImage
                  alt="menu-item"
                  effect="blur"
                  src={orderItem.image || MenuItemPlaceholder}
                  placeholderSrc={MenuItemPlaceholder}
                  className='user-order-item-image_'
                /> */}
                <Image
                  menu
                  alt="menu-item"
                  src={orderItem.image}
                  className='user-order-item-image_'
                />
                {orderItem.quantity ?
                  <div className='order-item-badge-wrapper_'>
                    <span className='order-item-badge_'>{orderItem.quantity}</span>
                  </div> :
                  null
                }
              </div>
            </div>
            <div className='user-order-item-details_'>
              {/* <h6>{orderItem.name}</h6> */}
              <h6>{orderItem.name}</h6>
              {/* <h6>{substringHelper(orderItem.name)}</h6> */}
              {/* <h6>{substringHelper(orderItem?.name)}</h6> */}
              <p>{modifierList}</p>
              <span style={{ fontFamily: "Lato-Bold", fontSize: "12px", display: "flex", gap:"2px", alignItems: "center", margin: "0.25rem 0 0", letterSpacing: "0.04rem" }}>{orderItem.item_status === "Item not sent" &&
                <>
                  {
                    DoNotDesturbIcon(16, '#767676', false)
                  }
                  {
                    orderItem.item_status
                  }
                </>
              }</span>
            </div>
          </div>

          <div className='user-order-item-actions-wrapper_'>
            <div>
            <h6>${orderItem.price}</h6>
            </div>
            {canOrder &&
              <div className={`${isOwnItem && orderItem.round === null ? 'user-order-item-actions_' : 'user-order-item-actions_2'} `}>
                {isOwnItem && orderItem.round === null ?
                  <>
                    {/* <i className="fa fas far fab fa-marker fa-icon" style={{marginRight:"0.75rem"}} onClick={onEditOrderItemModalHandler} />
                      <i className="fas fa-thin fa-trash fa-icon" style={{marginRight:"0.25rem"}} onClick={handleDeletePopUp} /> */}
                    <div onClick={onEditOrderItemModalHandler}>
                      {/* {EditIcon(32)} */}
                      {DiscoverTuneIcon(undefined,'#767676')}
                    </div>
                    <div onClick={handleDeletePopUp}>
                      {DeleteIcon(undefined,'#767676')}
                    </div>
                  </>
                  :
                  <>
                    {/* <i className='fa-icon' style={{ marginRight: "0.75rem" }} />
                    <i className="fas fa-solid fa-plus fa-icon" style={{ marginRight: "0.25rem" }} onClick={onAddOrderItemModalHandler} /> */}
                    <div onClick={onAddOrderItemModalHandler}>
                      {AddIcon()}
                    </div>
                  </>
                }



                {/* {onAddPopUp &&
                  <YouSureItemModal
                  show={onAddPopUp}
                  content='add'
                  action={onAddOrderItemModalHandler}
                  close={handleAddPopUp}
                  />}
                  {onEditPopUp &&
                  <YouSureItemModal
                  show={onEditPopUp}
                  content='edit'
                  action={onEditOrderItemModalHandler}
                  close={handleEditPopUp}
                  />} */}
                {onDeletePopUp &&
                  <YouSureItemModal
                    show={onDeletePopUp}
                    content='delete'
                    action={onDeleteOrderItemClick}
                    close={handleDeletePopUp}
                  />}

              </div>

            }
          </div>
        </div>
        <div className={!lastItem ? 'user-order-item-line_' : null}></div>
      </div>
    </>
  )
}

const PopUpContent = ({ content, onAction, onClose }) => {
  return (
    <div className='order-item-actions-popup_'>
      <h6>Are you sure you want to {content} this item?</h6>
      <CustomButton text='Yes' onClick={onAction} />
      <CustomButton text='NO' onClick={onClose} />
    </div>
  )
}



export default UserOrderItem
