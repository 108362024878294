import HomeActiveIcon from '../../assets/home-active.svg'
import HomeInactiveIcon from '../../assets/home-inactive.svg'
import ProfileActive from '../../assets/profile-active.svg'
import ProfileInactive from '../../assets/profile-inactive.svg'
import ReservationsActive from '../../assets/reservations-active.svg'
import ReservationsInactive from '../../assets/reservations-inactive.svg'
import { register_modal } from '../../config/modal_paths'
import {
  home_path,
  profile_page_path,
  reservations_path
} from '../../config/pages_paths'
import OutlineButton from '../common/OutlineButton'
import NavItemWithIcon from './NavItemWithIcon'

export const navItems = {
  landing: ({ openModalHandler, onNavItemClick }) => [
    {
      component: OutlineButton,
      props: {
        text: 'See restaurants',
        className: 'nav-item m-2 round-btn',
        path: home_path,
        onClick: () => onNavItemClick(home_path)
      }
    },
    {
      component: OutlineButton,
      props: {
        text: 'Sign up',
        className: 'nav-item m-2 round-btn',
        path: home_path,
        onClick: () => openModalHandler(register_modal)
      }
    },
    //  Change temporarily for production, when apps are done change back
    // {
    //   component: GetTheAppButton,
    //   props: {
    //     text: <span>Get the app</span>,
    //     className: 'nav-item m-2 round-btn'
    //   }
    // }
  ],
  user: ({ onNavItemClick, onAuthProtectedClick, numOfReservations }) => [
    {
      component: NavItemWithIcon,
      props: {
        text: 'Home',
        className: 'mx-2',
        activeIcon: HomeActiveIcon,
        inactiveIcon: HomeInactiveIcon,
        path: home_path,
        onClick: () => onNavItemClick(home_path)
      }
    },
    {
      component: NavItemWithIcon,
      props: {
        text: 'Orders',
        className: 'mx-2',
        activeIcon: ReservationsActive,
        inactiveIcon: ReservationsInactive,
        badgeNumber: numOfReservations || 0,
        path: reservations_path,
        onClick: () => onAuthProtectedClick(reservations_path)
      }
    },
    {
      component: NavItemWithIcon,
      props: {
        text: 'Profile',
        className: 'mx-2',
        activeIcon: ProfileActive,
        inactiveIcon: ProfileInactive,
        path: profile_page_path,
        onClick: () => onAuthProtectedClick(profile_page_path)
      }
    }
  ]
}
