const moment = require('moment')

function timeToAMPM(date) {
  return moment(date, [
    'YYYY-MM-DDTHH:mm A',
    'YYYY-MM-DD HH:mm A',
    'YYYY-MM-DDTHH:mm'
  ]).format('hh:mm A')
}
export default timeToAMPM
