import Validator from 'validator';
import isEmpty from 'lodash.isempty';

function validateUserPassword({ password, new_password, confirm_password, has_pw }) {
  const errors = {};

  if (has_pw && isEmpty(password))
    errors.password = 'Please enter your old password';

  if (isEmpty(new_password))
    errors.new_password = 'Please enter a new password';
  else if (!Validator.isLength(new_password, { min: 8 }))
    errors.new_password = 'New password must contain at least 8 characters';
  else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])/.test(new_password))
    errors.new_password = 'New password must contain at least one lowercase and one uppercase letter, one number, and one special character (!@#$%^&*()_+)';

  if (isEmpty(confirm_password))
    errors.confirm_password = 'Please confirm your password';
  else if (new_password !== confirm_password)
    errors.confirm_password = 'Passwords do not match';

  return { errors, isValid: isEmpty(errors) };
}

export default validateUserPassword;
