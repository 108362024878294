import React from 'react'
import Row from '../../containers/layout/Row'
import Column from '../../containers/layout/Column'
import EmptyReservationImage from '../../assets/empty-plate.png'
import Button from '../common/Button'
import { home_path, order_page_path, } from '../../config/pages_paths'
import { useHistory } from 'react-router'

// This component is used for displaying information and navigation about 
// a non existing reservation or a current empty reservation 

function EmptyReservation({ text, paragraph, reservationId }) {
  const history = useHistory()

  const handleHomeNavigation = () => history.push(home_path)

  const handleMenuNavigation = () => history.push(order_page_path.replace(":reservationId", reservationId))

  return (
    <Row className="m-4">
      <Column className="text-center empty-reservation">
        <img src={EmptyReservationImage} alt="empty-reservation" />
        <h2>{text || "Nothing ordered yet."}</h2>
        <h4 className='message-margin-bottom'>{paragraph || "Ready when you are."}</h4>
        
        {!reservationId ?
          <Button
            text="Pick a restaurant"
            className="pick-restaurant-btn"
            onClick={handleHomeNavigation}
          />
          :
          <Button
            text="Add items from Menu"
            className="pick-restaurant-btn"
            onClick={handleMenuNavigation}
          />
        }
      </Column>
    </Row>
  )
}

export default EmptyReservation


