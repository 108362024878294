import React from "react";
import {buttonClassesEnum, paymentComponentsEnum} from "../enums";

const ChoosePaymentMethod = ({activePaymentMethodButton, onChoosePaymentMethod}) => {
    const showButton = (buttonName, idx) => (
        <div key={idx} className="spreedly-choose-payment-method">
            <button onClick={() => onChoosePaymentMethod(idx)}
                    className={`spreedly-choose-payment-method-button spreedly-choose-payment-method-button-${buttonClassesEnum[idx === activePaymentMethodButton]} btn btn-primary`}>
                <span>{buttonName}</span>
            </button>
        </div>
    )

    return (
        <div className='spreedly-choose-payment-method-container'>
            <span className='spreedly-choose-payment-method-title'>Payment method</span>
            <div className='spreedly-choose-payment-method-buttons-container'>
                {Object.values(paymentComponentsEnum).filter(paymentMethod => paymentMethod.isEnabled).map((button, idx) => {
                    return showButton(button.buttonName, idx)
                })}
            </div>
        </div>
    )
}

export default ChoosePaymentMethod