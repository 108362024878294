import React, {Fragment, useEffect, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import isEmpty from 'lodash.isempty'
import DropIn from 'braintree-web-drop-in-react'

function CloseOrCompleteRound({
  onCompleteOrderClick,
  isSendToKitchenMode,
  onSendToKitchen
}) {
  const bottomOfPage = useRef(null)
  const [clientToken, setClientToken] = useState(null)
  const [instance, setInstance] = useState({})
  const {
    orders: {
      checkoutInfo: { total, subTotalInt },
      orderProcessing
    }
  } = useSelector(state => state)

  useEffect(() => {
    const signal = axios.CancelToken.source()
    const getToken = async () => {
      const result = await axios.get('/api/payments/get-token', {
        cancelToken: signal.token
      })
      setClientToken(result.data.token)
    }
    getToken().catch(err => console.log('err getting client token', err))

    return () => {
      signal.cancel('Cancel get Braintree token api')
    }
  }, [])

  const scrollToBottom = () => {
    setTimeout(() => (
      bottomOfPage?.current?.scrollIntoView({ behavior: "smooth" })
    ), 100)
  }

  const confirmButton = () => {
    return (
      <button
        disabled={
          isSendToKitchenMode
            ? false
            : isEmpty(instance) ||
              orderProcessing ||
              Number(total.replace('$', '')) <= 0
        }
        onClick={
          isSendToKitchenMode
            ? onSendToKitchen
            : () => onCompleteOrderClick({ instance })
        }
        className="btn btn-primary"
      >
        {isSendToKitchenMode ? 'Send your Order' : 'Complete order'}
      </button>
    )
  }

  return (
    <div className="credit-card-form-wrapper mb-4">
      {clientToken && !isSendToKitchenMode && subTotalInt > 0 ? (
        <Fragment>
          <DropIn
            options={{ authorization: clientToken, vaultManager: true }}
            onInstance={instance => {
              scrollToBottom()
              setInstance(instance)
            }}
          />
        </Fragment>
      ) : null}
      <div className="pay-button">
        {isSendToKitchenMode
          ? confirmButton()
          : subTotalInt > 0
          ? confirmButton()
          : null}
      </div>
      <div ref={bottomOfPage} />
    </div>
  )
}

export default CloseOrCompleteRound
