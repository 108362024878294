import React, { Fragment } from 'react'
import DescriptiveRadioButton from '../../common/radioButtons/DescriptiveRadioButton'
import PreparationTime from './PreparationTime'
import { DeliveryOptions } from '../OrdersRounds'

function OrderDeliveryOptions({
  checkoutInfo = { sections: [] },
  setPreparationTime,
  setOption
}) {
  const [checked, setChecked] = React.useState(true)

  const onChange = e => {
    e.persist()
    setChecked(checked => !checked)
    setOption(e)
  }

  return (
    <Fragment>
      <div className={'preparation-options-wrapper'}>
        <DescriptiveRadioButton
          label={'Bring items course by course'}
          name={'radio'}
          id={DeliveryOptions.separate}
          defaultChecked={true}
          onChange={onChange}
          show={checked}
        >
          {' '}
          <div className={'checkout-sections-wrapper'}>
            {checkoutInfo.sections.map(
              ({ section, initialDelay, maxOffset }) => {
                return (
                  <div
                    key={section}
                    className="text-center preparation-time-section-wrapper"
                  >
                    <h3>{section}</h3>
                    <span id="preparation-time-lead">On table in:</span>
                    <PreparationTime
                      cb={prepTime =>
                        setPreparationTime({
                          section,
                          preparationTime: prepTime
                        })
                      }
                      initialDelay={initialDelay}
                      maxOffset={maxOffset}
                    />
                  </div>
                )
              }
            )}
            <p>Other courses will arrive one by one as they made.</p>
          </div>
        </DescriptiveRadioButton>
      </div>
      <div>
        <DescriptiveRadioButton
          label={'Bring all items together'}
          name={'radio'}
          id={DeliveryOptions.together}
          onChange={onChange}
          show
        >
          <div className={'bring-all-together'}>
            <p>
              All of your courses will arrive at the same time, based on the
              item with the longest preparation time{' '}
            </p>
          </div>
        </DescriptiveRadioButton>
      </div>
    </Fragment>
  )
}

export default OrderDeliveryOptions
