import { SwipeableDrawer } from '@material-ui/core'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import React from 'react'
import CustomButtonMenu from '../../components/common/CustomButtonMenu'
import MenuSectionListApproved from './MenuSectionListApproved'
import Image from '../../components/common/Image'
import { ExpandLessIcon200, ExpandMoreIcon200 } from '../../assets/svgs-google/GoogleIcons'

const MenuListApproved = ({
    restaurantMenuApproved,
    restaurantLogo,
    isMobile,
    isMenuOpened,
    isMenuDrawerOpened,
    drawerRef,
    activeMenuId,
    activeMenuName,
    activeMenuSectionId,
    activeMenuSectionName,
    onReservation,
    handleIsMenuDrawerOpened,
    handleChangeMenu,
    handleChangeMenuSection,
}) => {
    const restaurantMenuContainer = (
        <>
            {isMobile ? (
                <div className="restaurant-logo-wrapper" style={{margin:"0.5rem 0"}}>
                    <Image width={"240px"} height={"160px"} restaurant src={restaurantLogo} alt="restaurant-logo" />
                </div>
            ) : null}

            {restaurantMenuApproved?.map((menu, idx) => {
                return (
                    <div key={menu.menu_id ?? idx} style={{ margin: "0 0.5rem" }}>
                        <div
                            onClick={() => handleChangeMenu(menu.menu_id, menu.menu_name)}
                            className="restaurant-menu-container"
                        >
                            <div className="menu-heading-container">
                                <h2
                                    className={classNames('restaurant-menu-heading', {
                                        active: isMenuOpened && activeMenuId === menu.menu_id
                                    })}
                                >
                                    {menu.menu_name}
                                </h2>
                                <div className="side-menu-menu-icon">
                                    {activeMenuId === menu.menu_id && isMenuOpened ?
                                        ExpandLessIcon200(32, '#020083 ') :
                                        ExpandMoreIcon200(32, '#D5D5D5')}
                                </div>
                            </div>
                        </div>
                        <div className="side-menu-item-separator" />
                        {!isEmpty(menu.menu_sections) ? (
                            <MenuSectionListApproved
                                isMenuOpened={isMenuOpened}
                                activeMenuId={activeMenuId}
                                activeMenuSectionId={activeMenuSectionId}
                                menuSections={menu.menu_sections}
                                menuId={menu.menu_id}
                                onReservation={onReservation}
                                handleChangeMenuSection={handleChangeMenuSection}
                            />
                        ) : null}
                    </div>
                );
            })}
        </>
    );

    const restaurantMenuContainerMobile = (
        <div className='full-menu-container'>
            {
                activeMenuName && activeMenuName ?
                    <div className='custom-btn-wrapper'>
                        <CustomButtonMenu onClick={handleIsMenuDrawerOpened}
                            icon
                            text={activeMenuName + " / " + activeMenuSectionName}
                            textClassName="voila-primary-menu-btn-text"
                            textStyle={{ paddingLeft: "0.5rem" }}
                        />
                    </div> :
                    null
            }
            <SwipeableDrawer
                ref={drawerRef}
                anchor='left'
                disableDiscovery
                disableBackdropTransition={false}
                open={isMenuDrawerOpened}
                onOpen={handleIsMenuDrawerOpened}
                onClose={handleIsMenuDrawerOpened}
            >
                {restaurantMenuContainer}
            </SwipeableDrawer>
        </div>
    )

    return (
        <div className='full-menu-container'>
            {
                !isMobile ?
                    restaurantMenuContainer :
                    restaurantMenuContainerMobile
            }
        </div>
    )
}

export default MenuListApproved
