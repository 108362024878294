import React from 'react'

function HomeHeader(props) {
  return (
    <>
      <div className="landing-header-left-column-mobile" />
      <section className="wrapper">
        <section className="hero">
          <div className="hero-contain">
            <h1>New recipe for dining</h1>
            <p>
              Picture a dining experience that it’s all about safety,
              convenience, good food, good friends and real joy.{' '}
            </p>
            <div className="down" />
          </div>
        </section>
        <section className="how-it-works">
          <div className="step first">
            <h2>Contactless, effortless &amp; worryless dining</h2>
            <p className="explanation">
              We offer a selection of{' '}
              <strong> handpicked, top restaurants in NYC </strong> that can
              provide a dining experience{' '}
              <strong> following all safety guidelines.</strong>
              We make no compromises on safety and quality in oder to give you
              peace of mind and pleasure while you wine &amp; dine.
            </p>
          </div>

          <div className="step second">
            <h2>Book, order and pay in the app, enjoy in the world</h2>
            <p className="explanation">
              With voilà you can still enjoy dining even in these special
              circumstances. We designed{' '}
              <strong>
                {' '}
                every part of the dining experience to be without contact
              </strong>{' '}
              - from the booking of your table all the way down to the ordering
              of your food and paying of the check.
            </p>
          </div>

          <div className="step">
            <h2>… and let our chef’s do their magic.</h2>
            <p className="explanation">
              The voilà dining experience ends with{' '}
              <strong>
                some magic on your plate and a smile on your face.
              </strong>{' '}
              All along, you get a feeling of{' '}
              <strong>something new, something better.</strong>
            </p>
          </div>

          <div className="clearer" />
        </section>
      </section>
    </>
  )
}

export default HomeHeader
