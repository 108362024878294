const errorMessages = {
    reservationNotFound: "We apologize for the inconvenience, but we couldn't find the reservation you're looking for.",
    reservationCancelled: "We apologize for the inconvenience, your reservation is cancelled. If you believe this is a mistake please contact the restaurant staff.",
    reservationManuallyClosed: "We apologize for the inconvenience, your order is manually closed. You can pay your check on a POS terminal, please contact the restaurant staff. If your check is already settled, disregard this message.",
    reservationExpired: "Your order session expired. Please order again and complete your order.",
    reservationNoShow: "You did not show on time. Please book again. If you believe this is a mistake please contact the restaurant staff.",
    reservationNotEligibleToStartBookedOrder: "You can submit your orders only within 1h of the booked time.",
    moreGuestsThanBookedSizeParty: "There are more guests invited than the reserved size of party. Please modify the reservation on OpenTable.",
}

export default errorMessages