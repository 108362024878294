export const landing_path = '/'
export const home_path = '/home'
export const aboutus_page_path = '/about-us'
export const help_page_path = '/help'
export const terms_page_path = '/terms'
export const privacy_page_path = '/privacy'
export const contact_page_path = '/contact'

export const login_path = '/login'
export const register_path = '/register'
export const register_confirmation_path = '/register/:confirmationToken'
export const password_reset_path = '/password-reset/:token'
export const profile_page_path = '/profile'
export const credit_card_information_path = '/credit-card-information'

export const restaurant_path = '/restaurant/:restaurantId'
export const full_menu_path = `${restaurant_path}/full-menu`
export const table_number_page_path = `${restaurant_path}/choose-table`

export const qr_order_landing_path = '/qr-order-landing/:restaurantId/:tableNumber'
export const order_first_time_path = '/add-order/:restaurantId/:tableNumber'

export const reservations_path = '/dining'
export const order_page_path = '/dining/order/:reservationId'

export const booking_page_path = `${restaurant_path}/book`
export const booking_success_path = `${restaurant_path}/booking-success/:reservationId`     
export const booking_reservation_path = `${restaurant_path}/booking-manage/:reservationId` 
export const accept_invitation_path = '/accept-invitation/:token'

export const anonymous_restricted_routes = {
  profile_page_path,
  booking_page_path,
  table_number_page_path
}