import React from 'react'
import NavigationSideMenu from '../../components/navigation/NavigationSideMenu'
import SpreedlyCreditCardVault from '../../components/profile/spreedly/SpreedlyCreditCardVault'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'

function CreditCardInformationPage() {
    return (
        <Row className="m-4 reservations-row">
        <Column md={10} lg={8}>
          <Row>
            <Column md="6" lg="6">
              <NavigationSideMenu />
            </Column>
    
            <Column className="scrollable-list" md="6" lg="6">
                <h3 className='account-information'>Credit card information</h3>
                <SpreedlyCreditCardVault />
            </Column>
          </Row>
        </Column>
      </Row>
  )
}

export default CreditCardInformationPage
