import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { editFeedback } from "../../pages/order/services/actions";
import CustomButton from '../common/CustomButton'
import classnames from 'classnames'
import Modal from '../modal/Modal'
import ModalHeading from '../common/ModalHeading'
import './EditTipModal.css'
import InputField from '../common/InputField'

function EditTipModal({
                        total,
                        onCloseTipModal,
                        showTipModal,
                        payForUsers,
                        type
                      }) {
  const {
    booking: { currentReservation: { reservation_id, tip: savedTip, feedback: savedFeedback } },
  } = useSelector(state => state)

  const dispatch = useDispatch();

  
  const defaultTipOption = 17
  const [firstOption, secondOption, thirdOption] = [10, defaultTipOption, 20]
  const isTipSaved = (
    savedTip !== null && 
    savedTip >= 0 && 
    savedTip <= 100 
    )
  const [nextTip, setNextTip] = useState(isTipSaved ? +savedTip : defaultTipOption)
  const [nextTipInput, setNextTipInput] = useState(isTipSaved ? +savedTip : defaultTipOption)
  const [showCustomTipModal, setShowCustomTipModal] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [feedback, setFeedback] = useState(savedFeedback || "")
  const isOtherTip = ![firstOption, secondOption, thirdOption].includes(nextTip)

  useEffect(() => {
    setShowFeedback(nextTip < 10)

  }, [nextTip, isOtherTip])

  const onEditTipHandler = () => setShowCustomTipModal(true)
  const onCloseOtherTipModal = () => {
    // workaround so that the input field can be empty
    setNextTip(!isNaN(nextTipInput) ? nextTipInput : 0)
    setShowCustomTipModal(false)
  }

  const onConfirmNextTip = () => {
    dispatch(editFeedback(nextTip, feedback, reservation_id, payForUsers))
    onCloseTipModal()
  }

  const onTipChangeHandler = e => {
    if (
        typeof +e.target.value === 'number' &&
        +e.target.value >= 0 &&
        +e.target.value <= 100 &&
        !e.target.value.includes('.')
    ) {
      setNextTipInput(parseInt(e.target.value))
    }
  }

  return (
    <>
      { !showCustomTipModal && type !== "info" && (
        <Modal
          className={`cancel-reservation-modal ${showCustomTipModal ? 'edit-tip-modal' : 'edit-tip-modal-no-tip'}`}
          show={showTipModal}
          close={onCloseTipModal}
        >
          <ModalHeading
            className={showFeedback ? 'password-reset-header' : 'push-heading'}
            title="Custom tip"
            onClick={onCloseTipModal}
          />
          <div className="edit-tip-container">
            <CustomButton
              onClick={() => setNextTip(firstOption)}
              wrapperClassName={classnames('booking-btn', { active: nextTip === firstOption })}
              textClassName={classnames('booking-btn-text', { active: nextTip === firstOption })}
              text={`${firstOption}%`}
            />
            <CustomButton
              onClick={() => setNextTip(secondOption)}
              wrapperClassName={classnames('booking-btn', { active: nextTip === secondOption })}
              textClassName={classnames('booking-btn-text', { active: nextTip === secondOption })}
              text={`${secondOption}%`}
            />
            <CustomButton
              onClick={() => setNextTip(thirdOption)}
              wrapperClassName={classnames('booking-btn', { active: nextTip === thirdOption })}
              textClassName={classnames('booking-btn-text', { active: nextTip === thirdOption })}
              text={`${thirdOption}%`}
            />
            <CustomButton
              onClick={onEditTipHandler}
              wrapperClassName={classnames('booking-btn', { active: isOtherTip })}
              textClassName={classnames('booking-btn-text', { active: isOtherTip })}
              text={isOtherTip ? `${nextTip}%` : 'Custom'}
            />
          </div>

          {showFeedback && (
            <div className="mt-4">
              <h3> Please share your feedback </h3>
              <InputField
                type="textarea"
                maxLength={1024}
                placeholder="Your remarks are valuable to us"
                value={feedback}
                onChange={e => setFeedback(e.target.value)}
              />
            </div>
          )}

          <div className="mt-4">
            <h3>Total amount:</h3>
            <span>${((total * nextTip) / 100).toFixed(2)}</span>
          </div>

          <CustomButton
              wrapperClassName="booking-submit-btn active mx-auto mt-3"
              textClassName="booking-submit-btn-text active"
              text="Apply"
              onClick={onConfirmNextTip}
          />
        </Modal>
      )}

      {showCustomTipModal && type !== "info" && (
        <Modal
          className="cancel-reservation-modal edit-tip-modal-no-tip"
          show={showTipModal}
          close={onCloseTipModal}
        >
          <ModalHeading
            className="password-reset-header push-heading"
            title="Custom Tip"
            onClick={onCloseTipModal}
          />

          <InputField
            type="number"
            containerClassName="mt-4"
            value={nextTipInput}
            onChange={onTipChangeHandler}
          />

          <CustomButton
            wrapperClassName="booking-submit-btn active mx-auto mt-3"
            textClassName="booking-submit-btn-text active"
            text="Apply"
            onClick={onCloseOtherTipModal}
          />
        </Modal>
      )}

      {type === "info" && (
        <Modal
        className={`cancel-reservation-modal ${showCustomTipModal ? 'edit-tip-modal' : 'edit-tip-modal-no-tip'}`}
        show={showTipModal}
        close={onCloseTipModal}
      >
        <ModalHeading
          className={showFeedback ? 'password-reset-header' : 'push-heading'}
          title="Platform Charge"
          onClick={onCloseTipModal}
        />

          <p>The Platform charge helps Voila to operate the platform. This fee is charged as a percent of an order’s subtotal and will be calculated before any promotions or discounts are applied.</p>
          
        <CustomButton
            wrapperClassName="booking-submit-btn active mx-auto mt-3"
            textClassName="booking-submit-btn-text active"
            text="Okay"
            onClick={onCloseTipModal}
        />
      </Modal>
      )}
    </>
  )
}

export default EditTipModal
