import axios from "axios"
import {
    SET_RESTAURANT_MENU_APPROVED,
    SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED,
    SET_ACTIVE_MENU_SECTION_ITEMS_WITH_ROUND_FOR_USER_APPROVED,
    SET_MENU_ITEM_RECOMMENDATIONS,
    SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL
} from "../../../config/actionTypes"
import { get_restaurant_menu_approved_api } from "../../../config/api_endpoints"
import { setLoader, setToastMessage } from "../../../store/actions/feedbackActions"
import isEmpty from "lodash.isempty"


export const getRestaurantMenuApproved = (restaurantId, reservationId, isNow) => async dispatch => {
    try {
        setLoader(true)
        const { data } = await axios.post(get_restaurant_menu_approved_api.replace(":restaurantId", restaurantId), { reservationId, isNow })
        dispatch(setRestaurantMenuApproved(data))
    } catch (err) {
        if (err.response.data.message) {
            dispatch(setToastMessage({
                message: err.response.data.message,
                severity: "error",
            }))
        }
    }
    finally {
        dispatch(setLoader(false))
    }
}

export const setRestaurantMenuApproved = payload => dispatch => {
    dispatch({ type: SET_RESTAURANT_MENU_APPROVED, payload })
}

export const setActiveMenuAndSectionInfoApproved = payload => dispatch => {
    dispatch({ type: SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED, payload })
}
export const setActiveMenuSectionItemsWithRoundForUserApproved = payload => dispatch => {
    dispatch({ type: SET_ACTIVE_MENU_SECTION_ITEMS_WITH_ROUND_FOR_USER_APPROVED, payload })
}

export const setMenuItemRecommendations = payload => dispatch => {
    dispatch({ type: SET_MENU_ITEM_RECOMMENDATIONS, payload })
}

export const setOnMenuItemRecommendationsModal = payload => dispatch => {
    dispatch({ type: SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL, payload })
}
