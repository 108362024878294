import {
  SET_SHOW_NAVIGATION,
  SET_MENU_ACTIVE_NAME,
  SET_MENU_ACTIVE_SUB_NAME,
  SET_REDIRECT_TO_HOME
} from '../../../config/actionTypes'

const initialState = {
  showNavigation: true,
  menuActiveName: '',
  menuSectionActiveName: '',
  redirectToHome: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_NAVIGATION:
      return { ...state, showNavigation: action.payload }
    case SET_MENU_ACTIVE_NAME:
      return { ...state, menuActiveName: action.payload }
    case SET_MENU_ACTIVE_SUB_NAME:
      return { ...state, menuSectionActiveName: action.payload }
    case SET_REDIRECT_TO_HOME:
      return { ...state, redirectToHome: action.payload }
    default:
      return state
  }
}
