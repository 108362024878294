import React, { Fragment, useCallback, useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import './App.css'
import Navigation from './components/navigation/Navigation'
import LandingPage from './pages/landing/LandingPage'
// import { getRestaurants } from './store/actions/restaurantsActions'
import jwt_decode from 'jwt-decode'
import isEmpty from 'lodash.isempty'
import { useDispatch, useSelector } from 'react-redux'
import AcceptInvitation from './components/acceptInvitation/AcceptInvitation'
import AuthVerify from './components/auth/AuthVerify'
import RegisterConfirmation from './components/auth/RegisterConfirmation'
import LoadingSpinner from './components/common/loader/LoadingSpinner'
import ToastMessage from './components/common/toast/ToastMessage'
import ExpirationTimerModal from './components/orders/ExpirationTimerModal'
import MenuItemQuantityChangedModal from './components/orders/MenuItemQuantityChangedModal'
import TimerExpiredMessageModal from './components/orders/TimerExpiredMessageModal'
import AnonymousRedirectRoute from "./components/routes/AnonymousRedirectRoute"
import AuthRoute from './components/routes/AuthRoute'
import GuestRoute from './components/routes/GuestRoute'
import { is_idle_modal, orders_paid_modal, update_user_name_modal } from './config/modal_paths'
import {
  aboutus_page_path, accept_invitation_path,
  booking_page_path,
  booking_reservation_path,
  booking_success_path, contact_page_path, credit_card_information_path, full_menu_path, help_page_path, home_path,
  landing_path, login_path, order_first_time_path, order_page_path,
  password_reset_path, privacy_page_path, profile_page_path, qr_order_landing_path, register_confirmation_path,
  reservations_path,
  restaurant_path,
  table_number_page_path,
  terms_page_path
} from './config/pages_paths'
import useExpirationTimer from './hooks/useExpirationTimer'
import useIsIdle from './hooks/useIsIdle'
import useIsCurrentTabActive from './hooks/useIsTabVisible'
import useModal from './hooks/useModal'
import useSocket from './hooks/useSocket'
import PasswordReset from './pages/auth/resetPassword/PasswordReset'
import { getAccessToken, setCurrentUser } from './pages/auth/services/actions'
import BookingPage from './pages/book/BookingPage'
import BookingSuccessPage from './pages/book/BookingSuccessPage'
import { setPayingForOthers } from './pages/book/services/actions'
import ContactPage from './pages/contact/ContactPage'
import HomePage from './pages/home/HomePage'
import AboutUsPage from './pages/informations/AboutUsPage'
import HelpPage from './pages/informations/HelpPage'
import ChooseTable from './pages/order/ChooseTable'
import OrderPage from './pages/order/OrderPage'
import QrOrderLanding from "./pages/order/QrOrderLanding"
import PrivacyPage from './pages/privacy/PrivacyPage'
import CreditCardInformationPage from './pages/profile/CreditCardInformationPage'
import ProfilePage from './pages/profile/ProfilePage'
import ReservationPage from './pages/reservation/ReservationPage'
import ReservationPageWrapper from './pages/reservation/ReservationPageWrapper'
import ReservationsPage from './pages/reservations/ReservationsPage'
import {
  closeCurrentReservation,
  getCurrentReservation,
  getReservations, setOtherTryingToPay, setReservations, setUserIdsSomeonePaysFor
} from './pages/reservations/services/actions'
import FullMenuPage from './pages/restaurant/FullMenuPage'
import RestaurantPage from './pages/restaurant/RestaurantPage'
import RestaurantWrapper from './pages/restaurant/RestaurantWrapper'
import { setCurrentPosition } from './pages/restaurant/services/actions'
import TermsPage from './pages/terms/TermsPage'
import { setActiveModal, setConnectSocketAfterIdleTimeout, setMenuItemQuantityChangedModal } from './store/actions/feedbackActions'
import { clearRestaurantReservation, getRestaurants } from './store/actions/restaurantsActions'
import { getUser, setIsPay, setIsProceededToCheckoutSelection } from './store/actions/usersActions'
import { getVersionConfig } from './store/actions/versionConfigActions'
import store from './store/store'
import setAuthToken from './utils/setAuthToken'
import localStorageHelper from './utils/localStorageHelper'
import RestaurantMenuApproved from './pages/menu/RestaurantMenuApproved'

if (localStorage.jwtToken) {
  const decoded = jwt_decode(localStorage.jwtToken.split(' ')[1])
  setAuthToken(localStorage.jwtToken)
  if (decoded.exp && decoded.exp < Date.now() / 1000) {
    store.dispatch(getAccessToken())
  } else {
    store.dispatch(setCurrentUser(decoded))
  }
}


function App() {
  const dispatch = useDispatch()
  const location = useLocation()

  const {
    auth: { isAuthenticated, user },
    user: { user: userDetails, isUpdated },
    restaurants: { restaurants },
    feedback: { toast, numOfLoadingSpinnerCalls, expirationTimerModal, timerExpiredMessageModal, showMenuItemQuantityChangedModal },
    navigation: { showNavigation },
    booking: { currentReservation, reservations: { ongoing } },
  } = useSelector(state => state)

  const containerRef = useRef()

  const activeModal = useModal()

  // const expirationTime = currentReservation?.expiration_time || ""
  // const [expirationTime, setExpirationTime] = useState("")

  const { timeLeft, refreshExpirationToken, expirationTime, orderExpired } = useExpirationTimer()
  const { isIdle } = useIsIdle()
  const { isCurrentTabActive } = useIsCurrentTabActive()

  useEffect(() => {
    if (isEmpty(userDetails) && localStorageHelper.exists('jwtToken')) {
      dispatch(getUser())
    }
  }, [dispatch, userDetails])

  useEffect(() => {
    if (isIdle && currentReservation?.reservation_id && !expirationTimerModal.show && !timerExpiredMessageModal.show) {
      dispatch(setActiveModal(is_idle_modal))
    }
  }, [isIdle])

  useEffect(() => {
    // conect socket after switching browser tab
    if (isCurrentTabActive) {
      dispatch(setConnectSocketAfterIdleTimeout(true))

      setTimeout(() => {
        dispatch(setConnectSocketAfterIdleTimeout(false))
      }, 1000)
    }
  }, [isCurrentTabActive])

  useEffect(() => {
    if (!isEmpty(userDetails) && isEmpty(userDetails?.first_name) && ongoing?.isComplete) {
      dispatch(setActiveModal(update_user_name_modal))
    }
  }, [dispatch, userDetails, ongoing?.isComplete])

  useEffect(() => {
    // if(isEmpty(restaurants)) {
    dispatch(getRestaurants())
    // }
  }, [dispatch])

  useEffect(() => {
    // reset payment if user changes route
    if (location.pathname !== reservations_path) {
      dispatch(setIsPay(false))
      dispatch(setIsProceededToCheckoutSelection(false))
    }
  }, [location.pathname])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getReservations())
      dispatch(getCurrentReservation())

      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          dispatch(
            setCurrentPosition({
              lon: position.coords.longitude,
              lat: position.coords.latitude
            })
          )
        })
      }
      return () => dispatch(setReservations([]))
    }

  }, [dispatch, isAuthenticated])

  useEffect(() => {
    dispatch(getVersionConfig())
  }, [dispatch])

  const refreshReservations = useCallback(() => {
    dispatch(getReservations())
    dispatch(getCurrentReservation())
  }, [dispatch, expirationTime])

  function isCurrentUsersAction(userIds, payerId, userId) {
    return +payerId !== userId && userIds.some(id => +id === userId)
  }

  const onOtherPaid = useCallback(
    ({ userIds, payerId, modalMeta, reservationId, isClosed }) => {
      if (isCurrentUsersAction(userIds, payerId, +user.id)) {
        dispatch(setActiveModal(orders_paid_modal, modalMeta, reservationId))
        dispatch(setOtherTryingToPay(false))
        dispatch(setUserIdsSomeonePaysFor([]))
        dispatch(setPayingForOthers([]))
        if (isClosed) {
          dispatch(closeCurrentReservation())
          dispatch(clearRestaurantReservation())
        }
      }
    },
    [dispatch, user.id]
  )

  const onMenuItemQuantityChanged = useCallback(
    ({ modalForUsersDict }) => {
      if (modalForUsersDict[user.id]) {
        const modalMessage = {
          heading: "We sincerely apologise",
          message: <>
            <span>
              The available quantity of selected {modalForUsersDict[user.id].names.length > 1 ? "items" : "item"}: <strong>{modalForUsersDict[user.id].names.map((item, i, { length }) => length - 1 === i ? " " + item.itemName + ` (${item.quantity})` : " " + item.itemName + ` (${item.quantity})` + ",")}</strong> has changed.
            </span>

            <span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>Please try another tasty option instead.</span>
          </>
        }

        dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }))
      }
    },
    [dispatch, user.id]
  )

  const onGuestsHaveSelectedMoreQuantityThanItemHas = useCallback(
    ({ guestsSelectedMoreQuantityThanItemHas, userId }) => {
      if (user.id === userId) {
        const modalMessage = {
          heading: "We sincerely apologise",
          message: <>
            <span>Currently we don't have that much available quantity for the {guestsSelectedMoreQuantityThanItemHas.length > 1 ? "items" : "item"}: <strong>{guestsSelectedMoreQuantityThanItemHas.map((item, i, { length }) => length - 1 === i ? " " + item.name : " " + item.name + ",")}</strong>.</span>

            <span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>Please try another tasty option instead.</span>
          </>
        }

        dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }))
      }
    },
    [dispatch, user.id]
  )

  const onMenuItemIsOutOfStock = useCallback(
    ({ itemsNames }) => {
      if (itemsNames[user.id] && itemsNames[user.id].show) {
        const modalMessage = {
          heading: "Order something else?",
          message: <>
            <span>
              Oops, we are currently out of stock of the following menu {itemsNames[user.id].names.length > 1 ? "items" : "item"}: <strong>{itemsNames[user.id].names.map((name, i, { length }) => length - 1 === i ? " " + name : " " + name + ",")}</strong>.
            </span>

            <span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>Please try another tasty option instead.</span>
          </>
        }

        dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }))
      }
    },
    [dispatch, user.id]
  )

  useSocket({
    reservationId: (currentReservation || {}).reservation_id,
    shouldConnect: true,
    refreshExpirationToken,
    refreshReservations,
    orderExpired,
    onOtherPaid,
    onMenuItemQuantityChanged,
    onGuestsHaveSelectedMoreQuantityThanItemHas,
    onMenuItemIsOutOfStock
  })

  return (
    <>
      {showNavigation ? <Navigation /> : null}
      <div ref={containerRef} className="container-fluid bistro-app mx-auto">
        {toast.show ? <ToastMessage {...toast} /> : null}
        {expirationTimerModal.show ? <ExpirationTimerModal timeLeft={timeLeft} /> : null}
        {timerExpiredMessageModal.show ? <TimerExpiredMessageModal /> : null}
        {showMenuItemQuantityChangedModal.show && <MenuItemQuantityChangedModal show={true} message={showMenuItemQuantityChangedModal.message} extraButton={showMenuItemQuantityChangedModal.extraButton} />}
        {numOfLoadingSpinnerCalls > 0 ? <LoadingSpinner /> : null}
        {activeModal}

        <Switch>
          <Route path={terms_page_path} component={TermsPage} />
          <Route path={contact_page_path} component={ContactPage} />
          <Route path={privacy_page_path} component={PrivacyPage} />
          <Route path={aboutus_page_path} component={AboutUsPage} />
          <Route path={help_page_path} component={HelpPage} />
          <GuestRoute exact path={landing_path} component={LandingPage} />
          <Route
            exact
            path={restaurant_path}
            render={() => <RestaurantWrapper component={RestaurantPage} />}
          />
          <Route exact path={home_path} component={HomePage} />
          <Route exact path={password_reset_path} component={PasswordReset} />
          <Route
            exact
            path={accept_invitation_path}
            component={AcceptInvitation}
          />
          <Route
            exact
            path={register_confirmation_path}
            component={RegisterConfirmation}
          />
          <Route
            exact
            path={full_menu_path}
            render={() => <RestaurantWrapper component={RestaurantMenuApproved} />}
          />
          <AuthRoute
            // redirectTo={home_path}
            // exact
            path={booking_reservation_path}
            // component={ReservationPage}
            render={() => <ReservationPageWrapper component={ReservationPage} />}
          />
          <AuthRoute
            redirectTo={home_path}
            exact
            path={table_number_page_path}
            component={ChooseTable}
          />
          <AuthRoute
            redirectTo={home_path}
            exact
            path={qr_order_landing_path}
            component={QrOrderLanding}
          />
          <Route
            redirectTo={home_path}
            exact
            path={booking_page_path}
            component={BookingPage}
          />
          <AnonymousRedirectRoute
            redirectTo={login_path}
            exact
            path={profile_page_path}
            component={ProfilePage}
          />
          <AnonymousRedirectRoute
            redirectTo={login_path}
            exact
            path={credit_card_information_path}
            component={CreditCardInformationPage}
          />
          <AuthRoute
            redirectTo={login_path}
            exact
            path={booking_success_path}
            component={BookingSuccessPage}
          />
          {/* <AuthRoute
            redirectTo={login_path}
            exact
            path={booking_reservation_path}
            component={BookingReservationPage}
          /> */}
          <AuthRoute
            redirectTo={login_path}
            exact
            path={reservations_path}
            component={ReservationsPage}
          // render={() => <ReservationPageWrapper component={ReservationsPage} /> }
          />
          <AuthRoute
            redirectTo={login_path}
            exact
            path={order_page_path}
            component={OrderPage}
          />
          <AuthRoute
            redirectTo={login_path}
            exact
            path={[order_first_time_path]}
            component={OrderPage}
          />
          <Redirect to={{ pathname: landing_path }} />
        </Switch>

        <AuthVerify />
      </div>
    </>
  )
}

export default App
