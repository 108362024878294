import { SET_RESTAURANTS, CLEAR_RESTAURANT_RESERVATION, SET_RESTAURANT } from '../../config/actionTypes'

const initialState = {
  restaurants: [],
  restaurant: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RESTAURANTS:
      return {
        ...state,
        restaurants: action.payload
      }
    case SET_RESTAURANT: 
      return { ...state, restaurant: action.payload }

    case CLEAR_RESTAURANT_RESERVATION:
      return {
        ...state,
        restaurants: state.restaurants.map(restaurant => {
          if (Object.values(restaurant.reservation).length > 0) {
            return {...restaurant, reservation: {}}
          } else {
            return {...restaurant}
          }
        })
      }
    default:
      return state
  }
}
