// React
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react'
import { Provider } from 'react-redux'
import store from './store/store'
import App from './App'

// Services
import * as serviceWorker from './serviceWorker'

// Fonts && css
import './fonts/Avenir-Book.otf'
import './fonts/Avenir-Medium.otf'
import './fonts/Avenir-Roman.otf'
import './fonts/Cormorant-Bold.ttf'
import './fonts/Cormorant-Regular.ttf'
import './fonts/Lato-Bold.ttf'
import './fonts/Lato-Regular.ttf'

import './index.css'


ReactDOM.render(
  <FpjsProvider
    cacheLocation="localstorage"
    loadOptions={{
      apiKey: "Qc8S5ZUGFW9wTZ5gPTz3",
      endpoint: "https://metrics.staging.voila.nyc",
      region: "na"
    }}
  >
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </FpjsProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
