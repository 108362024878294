import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
// import { cancelReservation } from '../../pages/book/services/actions'
import { reservations_path } from '../../config/pages_paths'
import { setActiveModal } from '../../store/actions/feedbackActions'
import Modal from '../modal/Modal'
import ModalHeading from '../common/ModalHeading'
import CancelReservationButton from './buttons/CancelReservationButton'
import DismissButton from './buttons/DismissButton'
import Row from '../../containers/layout/Row'
import Column from '../../containers/layout/Column'
import { cancelReservation } from '../../pages/book/services/actions'

function CancelReservationModal({ show, close }) {
  const dispatch = useDispatch()
  const {
    booking: { reservationId },
    feedback: { responseMessage }
  } = useSelector(state => state)
  const history = useHistory()

  const onCancelReservation = () => {
    dispatch(cancelReservation(reservationId))
  }

  const onDismissHandler = () => {
    dispatch(setActiveModal(''))
  }

  useEffect(() => {
    if (responseMessage) {
      dispatch(setActiveModal(''))
      history.push(reservations_path)
    }
  }, [responseMessage, dispatch, history])

  return (
    <Modal className="cancel-reservation-modal" show={show} close={close}>
      <ModalHeading
        className="password-reset-header"
        onClick={close}
        title="Are you sure?"
      />
      <Row>
        <Column className="text-center align-items-center m-4" md={12} lg={12}>
          <div className="cancel-reservation-heading">
            <span>
              You reservation will be canceled, along with the orders that you
              and your guests did.
            </span>
          </div>
          <div className="cancel-reservation-btns">
            <CancelReservationButton onClick={onCancelReservation} />
            <DismissButton onClick={onDismissHandler} />
          </div>
        </Column>
      </Row>
    </Modal>
  )
}

export default CancelReservationModal
