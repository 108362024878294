import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaymentDoneImage from '../../assets/illustration-payment-done.png'
import RestaurantLogo from '../../assets/logo-boucherie.png'
import { register_modal } from '../../config/modal_paths'
import { editRating } from '../../pages/order/services/actions'
import { setActiveModal } from '../../store/actions/feedbackActions'
import CheckMarkPoint from '../common/CheckMarkPoint'
import CustomButton from '../common/CustomButton'
import OutlineButton from '../common/OutlineButton'
import RatingStars from '../common/rating/RatingStars'
import Modal from '../modal/Modal'
import './PaymentDoneAnonUserModal.css'


function PaymentDoneAnonUserModal({ show, close }) {
    const {
        restaurant: { restaurant },
        orders: { userRating },
    } = useSelector(state => state)

    const dispatch = useDispatch()

    const { ratingContent, ratingStars, ratingDescription } = 
    RatingStars({
        existingRatingStars:userRating.ratingStars, 
        existingRatingDescription:userRating.ratingDescription
    })

    const ratingFeedback = ratingStars || ratingDescription

    const handleContinueAction = () => {
        ratingFeedback && dispatch(editRating(userRating.reservationId, ratingStars, ratingDescription))
        close()
        dispatch(setActiveModal(register_modal))
    }

    const handleCloseModal = () => {
        ratingFeedback && dispatch(editRating(userRating.reservationId, ratingStars, ratingDescription))
        close()
    }


    return (
        <Modal className="payment-done-anon-modal" show={show} close={handleCloseModal}>
            <div className='modal-wrapper'>
                <img alt="chef" className='payment-done-img' src={PaymentDoneImage} />
                <h3>Payment done</h3>
                <img className='restaurant-logo' alt='restaurant_logo' src={restaurant.logo || RestaurantLogo} />
                <p id='restaurant-title'>{restaurant.name}</p>
                <p>Thank you for dining with us. We hope you enjoyed your experience.</p>
                
                {ratingContent}

                <p>Create an account to get even more on your next dining:</p>
                <CheckMarkPoint />
                <div className='btn-wrapper create-account-btn-wrapper white-bg'>
                    <CustomButton
                        onClick={handleContinueAction}
                        text={'Create account'}
                        wrapperClassName="btn btn-primary big-round-btn create-account-modal-btn"
                        textClassName="cancel-reservation-btn-text create-account-modal-btn-text create-account-text"
                    />
                    <OutlineButton
                        onClick={handleCloseModal}
                        text={'Skip'}
                        wrapperClassName="cancel-reservation-btn payment-done-button"
                        textClassName="cancel-reservation-btn-text"
                    />

                </div>
            </div>
        </Modal>
    )
}

export default PaymentDoneAnonUserModal
