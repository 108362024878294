import React from 'react';
import CustomButton from '../common/CustomButton';
import Modal from '../modal/Modal';

const YouSureItemModal = ({ show, close, action, content, message }) => {
    return (
        <div className="you-sure-item-modal">
            {/* <CloseButton onClick={close}/> */}
            <Modal className='' show={show} close={close} ignoreClassName={false}>
                <div>
                    {message ? 
                    <h4 style={{textAlign:'center'}}>{message}</h4> :
                    <h4 style={{textAlign:'center'}}>Are you sure you want to {content} this item?</h4>  
                }
                    <div className='you-sure-action-wrapper'>
                        <CustomButton text='Yes' onClick={action} />
                        <CustomButton text='No' onClick={close} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default YouSureItemModal;