import React from 'react'
import classnames from 'classnames'

function SeparationLine({
  width,
  style = {},
  className,
  mx = true,
}) {
  return (
    <div
      className={classnames(mx && 'mx-auto', className)}
      style={{
        width: width || '354px',
        height: '1px',
        borderTop: 'solid 1px #ebebeb',
        marginTop: '1rem',
        ...style
      }}
    />
  )
}

export default SeparationLine
