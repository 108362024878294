import React from 'react'
import CustomButton from '../../common/CustomButton'

function DismissButton({ onClick }) {
  return (
    <CustomButton
      onClick={onClick}
      text={'No, keep it'}
      wrapperClassName="cancel-reservation-btn dismiss"
      textClassName="cancel-reservation-btn-text dismiss"
    />
  )
}

export default DismissButton
