import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash.isempty'
import LoadingSpinner from '../../components/common/loader/LoadingSpinner'
import {
  getReservationDetails,
  setReservationDetails
} from '../book/services/actions'
import { useHistory, useParams } from 'react-router'
import { initialReservationDetails } from '../book/services/reducers'

function ReservationPageWrapper({ component: Component }) {
  const {
    booking: {
      reservationDetails: { reservation }
    }
  } = useSelector(state => state)
  const [loading, setLoading] = useState(true)
  const { reservationId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    reservationId && dispatch(getReservationDetails(reservationId, history))
  }, [reservationId, dispatch])

  useEffect(() => {
    if (!isEmpty(reservation)) {
      setLoading(false)
    }
  }, [reservation])

  useEffect(() => {
    return () => {
      dispatch(setReservationDetails(initialReservationDetails))
    }
  }, [dispatch])
  
  return loading ? (
    <LoadingSpinner />
  ) : Component ? (
    <Component />
  ) : null
}

export default ReservationPageWrapper
