import React from 'react'
import formatPrice from '../helpers/formatPrice'
import { useSelector } from 'react-redux'
import SeparationLine from '../../common/SeparationLine'

function CheckoutInfo({ onEditTipHandler, showInfoModalHandler }) {
  const {
    orders: { checkoutInfo },
    user: { isPay, isProceededToCheckoutSelection },
    booking: {currentReservation: { reservation_is_tip_enabled }}
  } = useSelector(state => state)


  return (
    <div className="checkout-info-wrapper">

      {checkoutInfo.checkoutInfoPerUsers.length > 1 && checkoutInfo.subTotalInt !== parseInt(checkoutInfo.checkoutInfoOnTable.subTotal) && (isProceededToCheckoutSelection && isPay || !isPay) && (
        <>
          {!isPay ? (
              <h5>Table balance: ${checkoutInfo.checkoutInfoOnTable.subTotal}</h5>
            ) : (
              <h5>Total table balance: ${ checkoutInfo.checkoutInfoOnTable.total}</h5>
          )} 
          <SeparationLine style={{marginBottom: "15px", width: "100%"}} />
        </>
      )}

      {checkoutInfo.subTotalInt > 0 && (
      <>
      <h2>Your {!isPay ? "Order" : "check"}</h2>

      {checkoutInfo.orders.map((order, idx) => {
        let words = order.name.split(" ")
        let names = []
        words.forEach(word => names.push(word.substring(0, 15)))
        names = names.join(" ")
        return (
          <div style={{ marginBottom: '15px' }} key={idx}>
            <div className="checkout-info-items-wrapper">
              <span>{names + ' x ' + order.quantity + ':'}</span>
              <span>{formatPrice(order.price.toString())}</span>
            </div>
            {order.itemPercentageDiscount && order.itemPercentageDiscount.isApplied &&
              <div className="checkout-info-items-wrapper">
                <span>{order.itemPercentageDiscount.percentage}% item discount:</span>
                <span>{order.itemPercentageDiscount.amount}</span>
              </div>
            }
            {order.itemFixedDiscount && order.itemFixedDiscount.isApplied &&
              <div className="checkout-info-items-wrapper">
                <span>Fixed item discount:</span>
                <span>{order.itemFixedDiscount.amount}</span>
              </div>
            }
            <div className="checkout-info-modifiers-wrapper">
              {order.modifiers.map((modifier, idx) => {
                return (
                  <p className="checkout-info-modifiers" key={idx}> {modifier.displayName} x {modifier.quantity} </p>
                )
              })}
            </div>
          </div>
        )
      })}

      {!isPay && (
        <div style={{ marginBottom: '15px', display: 'flex' }}>
          <span>Subtotal:</span>
          <span>{checkoutInfo.subTotal}</span>
        </div>
      )}

      {isPay && (
        <>
          {checkoutInfo.areAnyDiscountsApplied && (
            <>
              <hr />

              {checkoutInfo.checkPercentageDiscount && checkoutInfo.checkPercentageDiscount.isApplied && (
                <div style={{ marginBottom: '15px', display: 'flex' }}>
                  <span>{checkoutInfo.checkPercentageDiscount.percentage}% check discount:</span>
                  <span>{checkoutInfo.checkPercentageDiscount.amount}</span>
                </div>
              )}

              {checkoutInfo.checkFixedDiscount && checkoutInfo.checkFixedDiscount.isApplied && (
                <div style={{ marginBottom: '15px', display: 'flex' }}>
                  <span>Fixed check discount is applied</span>
                  <span />
                  {/*not covered case*/}
                  {/*<span>{checkoutInfo.checkFixedDiscount.amount}</span>*/}
                </div>
              )}

              <div style={{ marginBottom: '15px', display: 'flex' }}>
                <span>Pre-discount subtotal:</span>
                <span>{checkoutInfo.subTotal}</span>
              </div>

              <div style={{ marginBottom: '15px', display: 'flex' }}>
                <span>Discount total:</span>
                <span>{checkoutInfo.discount}</span>
              </div>
            </>
          )}

          <div style={{ marginBottom: '15px', display: 'flex' }}>
            <span>Subtotal:</span>
            <span>{checkoutInfo.discountedSubTotal}</span>
          </div>

          <hr />

          <div style={{ marginBottom: '15px', display: 'flex', alignItems: "center" }}>
            <span>Platform charge {checkoutInfo.voilaPlatformFeePercentage}: {' '}
              <button className='platfromCharge-button' onClick={showInfoModalHandler}>
                <OutlineInfoIcon />
              </button>
            </span>
            <span>{checkoutInfo.voilaPlatformFee}</span>
          </div>

          {
           reservation_is_tip_enabled &&
            <div style={{ marginBottom: '15px', display: 'flex' }}>
              <span>
                Tip {checkoutInfo.tip}%:{' '}
                <button className={"editTip-button"} onClick={onEditTipHandler}>
                  Custom Tip
                </button>
              </span>
              <span className={"totalTip"}>{checkoutInfo.totalTip}</span>
            </div>
          }

          <div style={{ marginBottom: '15px', display: 'flex' }}>
            <span>Tax{checkoutInfo.taxRate}:</span>
            <span>{checkoutInfo.tax}</span>
          </div>

          <hr />

          <div className={"total-amount"} style={{ display: 'flex' }}>
            <span>Total:</span>
            <span>{formatPrice(checkoutInfo.total.toString())}</span>
          </div>

          <hr />

          {checkoutInfo.areAnyDiscountsApplied && <p>The tip is based on the check price before <br /> discounts (Pre-discount subtotal)</p>}
        </>
      )}
      </>
      )} 
    </div>
  )
}

export default CheckoutInfo

function OutlineInfoIcon() {
  return (
    <svg x="0px" y="0px"
      width="25" height="25"
      viewBox="0 0 50 50"
      fill='#040082'>
      <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z">
      </path>
    </svg>
  )
}