import { order_page_path } from '../config/pages_paths'
import { getCheckoutInfo, getLastRoundOrders, getOrders, getOrdersRounds } from '../pages/order/services/actions'
import { getCurrentReservation } from '../pages/reservations/services/actions'
import { setConnectSocketAfterIdleTimeout } from '../store/actions/feedbackActions'
import { setIsPay, setIsProceededToCheckoutSelection } from '../store/actions/usersActions'


const reconnectSocket = (currentReservation, id, history, dispatch) => {
    if (currentReservation?.reservation_id) {
        dispatch(setConnectSocketAfterIdleTimeout(true))
        dispatch(getCurrentReservation((currentReservation) => {
            if (Object.values(currentReservation).length === 0) {
                history.push(order_page_path.replace(':reservationId', currentReservation?.reservation_id))
            }
        }))
        dispatch(getOrders(currentReservation?.reservation_id))
        dispatch(getOrdersRounds(currentReservation?.reservation_id))
        dispatch(setIsPay(false))
        dispatch(getLastRoundOrders(currentReservation?.reservation_id))
        dispatch(setIsProceededToCheckoutSelection(false))
        dispatch(getCheckoutInfo(currentReservation?.reservation_id, [id]))
    }
}

export default reconnectSocket